import 'react-calendar/dist/Calendar.css';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import GlobalState from '../contexts/GlobalState';
import Api from './Api';
import DocumentsSmallTab from './DocumentsSmallTab';
import Datepicker from 'react-datepicker';
import { addYears, getIndex, formatDate } from '../Helpers';

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import RelationsSelect from './RelationsSelect';
import { AttachIcon, CloseIcon, ThreeDotsMenu } from './Icons';
registerLocale('ar', ar)

export default function BoardMembers(props) {

    const { profileClientGlobal, generalDataGlobal } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [generalData,] = generalDataGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [boardPopup, setBoardPopup] = useState(false);
    const [currentMember, setCurrentMember] = useState(null);
    const [comment, setComment] = useState('');
    const [editRelationMenu, setEditRelationMenu] = useState(null);
    const [deletePopup, setDeletePopup] = useState(null);

    const [fileName, setFileName] = useState();

    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();

    const [clientRole, setClientRole] = useState('');
    const [permanentType, setPermanentType] = useState('');
    const [mandateTerm, setMandateTerm] = useState('');
    const [memberStatus, setMemberStatus] = useState('');

    const [fileDate, setFileDate] = useState(new Date());

    //all client statuses from backend
    const clientStatuses = () => {
        const clientStat = generalData?.client_statuses?.map(
            stat => ({ slug: stat?.slug, value: stat.id, label: stat.title }))
        return clientStat;
    }

    // get all permanent types
    const permanentTypes = () => {
        const permanentType = generalData?.permanent_types?.map(
            singlePerm => ({ slug: singlePerm?.slug, value: singlePerm.id, label: singlePerm.title }))
        return permanentType;
    }

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            minWidth: '40px !important'
        })
    };

    //reset states when closing the popup
    const closeAddBoard = (e) => {
        setBoardPopup(false);
        setErrorsReturned({});
        setCurrentMember(null);
        setComment('');
        setSelectedRelation('');
        setMandateTerm('');
        setClientRole('');
        setPermanentType('');
        setMandateTerm('');
        setEditRelationMenu(null);
        setDeletePopup(null);
        setFileName('');
        setDocument('');
    }

    // reset states once delete is canceled
    const cancelDeletePopup = (e) => {
        setDeletePopup(null);
        setEditRelationMenu(null);
        closeAddBoard(e);
    }

    // when editing to set the states with current edit data
    const editRelationClick = (e, data) => {
        setComment(data?.comment ? data?.comment : '');
        setMandateTerm(data?.mandate_term ? data?.mandate_term : '');
        setPermanentType(permanentTypes()[getIndex(data?.permanent_represent_types_id, permanentTypes(), 'value')]);
        setClientRole(clientRoles()[getIndex(data?.client_roles_id, clientRoles(), 'value')]);
        setMemberStatus(clientStatuses()[getIndex(data?.client_stat_id, clientStatuses(), 'value')]);
        setDocumentLink(data?.file);
        setFileName(data?.uploaded_files?.file_name);

        /* handle the custom client type dropdown */
        if (data?.board?.client_type?.slug) {
            setCustomRelationFilter(data?.board?.client_type?.slug);
        }
        if (data?.board_id) {
            if (data?.board?.client_type_id === 1) {
                let text = 'client-' + data?.board_id;
                setSelectedRelation(companyRelations()[getIndex(text, companyRelations(), 'value')]);
            }
            if (data?.board?.client_type_id === 2) {
                let text = 'client-' + data?.board_id;
                setTimeout(() => {
                    setSelectedRelation(individualRelations()[getIndex(text, individualRelations(), 'value')]);
                }, 300);
            }
            if (data?.board?.client_type_id === 3) {
                let text = 'client-' + data?.board_id;
                setTimeout(() => {
                    setSelectedRelation(ngoRelations()[getIndex(text, ngoRelations(), 'value')]);
                }, 300);
            }
        }
        /* handle the custom client type dropdown */

        setCurrentMember(data);
        setBoardPopup(true);
    }

    //delete element backend post
    const deleteElement = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('user_id', props.user_id);
        fd.append('client_id', props.user_id);

        if (deletePopup && deletePopup?.id) {
            fd.append('member_id', deletePopup?.id);
            fd.append('file_id', deletePopup?.uploaded_files?.id ? deletePopup?.uploaded_files?.id : null)
        }

        setGeneralDataApiOptions({
            url: 'delete-board-member',
            method: 'post',
            data: fd,
            callback: res => {
                closeAddBoard(e);
                setProfileClient(res.data);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    //submit memeber backend post
    const submitMember = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();

        fd.append('board_id', selectedRelation ? selectedRelation?.value : '');
        fd.append('user_id', props.user_id);
        fd.append('client_id', props.user_id);
        fd.append('comment', comment);
        fd.append('client_roles_id', clientRole ? clientRole?.value : '');
        fd.append('permanent_represent_types_id', permanentType ? permanentType?.value : '');
        fd.append('mandate_term', mandateTerm ? mandateTerm : '');
        fd.append('description', comment);
        fd.append('return_row', 1);
        fd.append('relation_name', selectedRelation ? selectedRelation?.value : '');
        fd.append('client_stat_id', memberStatus ? memberStatus?.value : '');
        fd.append('file_date', formatDate(fileDate));


        // handle file upload in request 
        fd.append('force', 1);
        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 2);
        fd.append('folder_name', 'board memebers');
        fd.append('from_home', 1);
        fd.append('file_name', fileName ? fileName : '');

        if (currentMember && currentMember?.id) {
            fd.append('member_id', currentMember?.id);
            if (currentMember?.client_relations_id) {
                fd.append('relation_id', currentMember?.client_relations_id);
            }
        }


        if (document) {
            fd.append('file', document[0]);
        }
        setGeneralDataApiOptions({
            url: 'add-edit-board-member',
            method: 'post',
            data: fd,
            callback: res => {
                closeAddBoard(e);
                setProfileClient(res.data);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }


    //get all individual relations
    const individualRelations = () => {
        const relOptions = profileClient?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    // get all company relations
    const companyRelations = () => {
        const relOptions = profileClient?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get all ngo relations
    const ngoRelations = () => {
        const relOptions = profileClient?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }


    // get all client roles
    const clientRoles = () => {
        const clientRole = generalData?.client_roles?.map(
            singleRole => ({ slug: singleRole?.slug, value: singleRole.id, label: singleRole.title }))
        return clientRole;
    }

    //handle document upload
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0]);
            setDocumentLink(file);
        }
    }

    const [customRelationFilter, setCustomRelationFilter] = useState('individual');
    const [selectedRelation, setSelectedRelation] = useState('');

    useEffect(() => {
        setSelectedRelation('');
    }, [customRelationFilter]);
    /* */

    return (
        <>
            {
                profileClient && profileClient.id === Number(props.user_id) ?
                    <>
                        <div className="container-fluid documents-div pt-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col d-flex flex-row">
                                            <div className="col-auto my-auto d-flex">
                                                {/* <h1 className="m-0 d-flex">{generalData?.website_titles['board-members']}
                                                    <img style={{ height: "35px" }} className="cursor-pointer" src={'/assets/images/plus.svg'} onClick={(e) => setBoardPopup(true)} alt="add" />
                                                </h1> */}
                                                <div className="col-auto my-auto d-flex align-items-center">
                                                    <h1 className="m-0 active">{generalData?.website_titles['board-members']}</h1>
                                                    <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={(e) => setBoardPopup(true)} alt="add" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-2">
                                {
                                    profileClient?.board_members?.length > 0 ?
                                        profileClient?.board_members.map((singleMember, memberIndex) => (
                                            <div key={memberIndex} className="col-12 col-md-6 py-2 h-auto">
                                                <div className="cms-table-content position-relative h-100">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="gray-background">
                                                                <div className="row py-2 px-3">
                                                                    <div className="col-auto">
                                                                        <img className="image-user-chat" src={singleMember?.board?.picture ? singleMember?.board?.full_path_picture : (singleMember?.board?.client_type_id === 1 ? generalData?.fixed_settings?.full_path_no_company_picture : (singleMember?.board?.client_type_id === 2 ? generalData?.fixed_settings?.full_path_no_individual_picture : generalData?.fixed_settings?.full_path_no_ngo_picture))} alt="chat user" />
                                                                    </div>
                                                                    <div className="col ps-0">
                                                                        <div className="row justify-content-between">
                                                                            <div className="col-auto">
                                                                                <p className="m-0 font-semi-bold">{singleMember?.board?.name}</p>
                                                                                <h5 className="locked-inputs w-100 fs-14">{singleMember?.board?.client_type?.title}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* edit menu on click */}
                                                                    <div className="col-auto pe-0 d-flex align-items-center cursor-pointer">
                                                                        <div className="text-end p-0 position-relative" >
                                                                            <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editRelationMenu?.id === singleMember?.id ? setEditRelationMenu(null) : setEditRelationMenu(singleMember) }} />
                                                                            <div style={{ top: '0px' }} className={"settings-clickmenu text-start " + (singleMember?.id === editRelationMenu?.id ? ' d-block' : '')}>
                                                                                <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editRelationClick(e, singleMember) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleMember) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row px-3 py-2">
                                                        {
                                                            singleMember?.mandate_term ?
                                                                <div className="col-12 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['mandate-term']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.mandate_term}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            singleMember?.client_roles_id ?
                                                                <div className="col-12 col-md-6 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['role']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.client_roles?.title}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            singleMember?.client_stat_id ?
                                                                <div className="col-12 col-md-6 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['status']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.client_stat?.title}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            singleMember?.permanent_represent_types_id ?
                                                                <div className="col-12 col-md-6 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['permanent-represent']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.permanent_represent_types?.title}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            singleMember?.uploaded_files &&
                                                            <div className="col-12 py-1">
                                                                <p className="profile-general-input-title">{generalData?.website_titles['file']}</p>
                                                                <a className href={singleMember?.uploaded_files.full_path_file} target="_blank" rel="noreferrer" download={singleMember?.uploaded_files.file_name}>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.uploaded_files?.file_name}
                                                                    </h5>
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            singleMember?.comment ?
                                                                <div className="col-12 py-1">
                                                                    <p className="profile-general-input-title">{generalData?.website_titles['comment']}</p>
                                                                    <h5 className="locked-inputs w-100 fs-14">
                                                                        {singleMember?.comment}
                                                                    </h5>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className="col-12">
                                            <h5 className="locked-inputs w-100 m-0 p-0">{generalData?.website_titles['no-board-members']}</h5>
                                        </div>
                                }
                            </div>
                            {/* show the document tab component */}
                            <DocumentsSmallTab userId={props.user_id} files={profileClient?.home_files} />
                        </div>
                        {
                            //popup to add/edit borad member
                            boardPopup ?
                                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <CloseIcon className="cursor-pointer" onClick={(e) => closeAddBoard(e)} />
                                        <div className="text-center ">
                                            <div className="containter-fluid">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currentMember?.id ? generalData?.website_titles['edit-board-member'] : generalData?.website_titles['add-board-member']}</h1>
                                                    </div>
                                                    <form onSubmit={submitMember}>
                                                        <div className="col-12 pt-2 pb-4">
                                                            <div className="row">
                                                                <div className="col-12 col-md-6 pt-2">
                                                                    <RelationsSelect
                                                                        selectedRelation={selectedRelation}
                                                                        value={selectedRelation}
                                                                        setSelectedRelation={setSelectedRelation}
                                                                        profile={profileClient}
                                                                        defaultSelected={'individual'}
                                                                        excludeMatters
                                                                        excludeTasks
                                                                        className="relations-select"
                                                                    />
                                                                    {
                                                                        errorsReturned?.board_id ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_id}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="col-12 col-md-6 pt-2">
                                                                    <div className="position-relative text-start justify-content-start w-100">
                                                                        <Select
                                                                            classNamePrefix="popup-select"
                                                                            className="popup-select w-100"
                                                                            styles={customStylesPopup}
                                                                            options={clientRoles()}
                                                                            components={{
                                                                                IndicatorSeparator: () => null
                                                                            }}
                                                                            placeholder={generalData?.website_titles['role']}
                                                                            isSearchable={false}
                                                                            value={clientRole ? clientRole : null}
                                                                            onChange={setClientRole}
                                                                        />
                                                                        <input
                                                                            type="text"
                                                                            className='position-x-center'
                                                                            style={{
                                                                                opacity: 0,
                                                                                width: 0,
                                                                                height: 0,
                                                                            }}
                                                                            value={clientRole || ''}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    {
                                                                        errorsReturned?.client_roles_id ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.client_roles_id}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="col-12 col-md-6 pt-2">
                                                                    <div className="postion-relative text-start justify-content-start w-100">
                                                                        <Select
                                                                            classNamePrefix="popup-select"
                                                                            className="popup-select w-100"
                                                                            styles={customStylesPopup}
                                                                            options={clientStatuses()}
                                                                            components={{
                                                                                IndicatorSeparator: () => null
                                                                            }}
                                                                            placeholder={generalData?.website_titles['status']}
                                                                            isSearchable={false}
                                                                            value={memberStatus ? memberStatus : null}
                                                                            onChange={setMemberStatus} />
                                                                    </div>
                                                                    {
                                                                        errorsReturned?.client_stat_id ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.client_stat_id}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="col-12 col-md-6 pt-2">
                                                                    <div className="position-relative text-start justify-content-start w-100">
                                                                        <Select
                                                                            classNamePrefix="popup-select"
                                                                            className="popup-select w-100"
                                                                            styles={customStylesPopup}
                                                                            options={permanentTypes()}
                                                                            components={{
                                                                                IndicatorSeparator: () => null
                                                                            }}
                                                                            placeholder={generalData?.website_titles['permanent-represent']}
                                                                            isSearchable={false}
                                                                            value={permanentType ? permanentType : null}
                                                                            onChange={setPermanentType} />
                                                                        <input
                                                                            type="text"
                                                                            className='position-x-center'
                                                                            style={{
                                                                                opacity: 0,
                                                                                width: 0,
                                                                                height: 0,
                                                                            }}
                                                                            value={permanentType || ''}
                                                                            required
                                                                        />
                                                                    </div>
                                                                    {
                                                                        errorsReturned?.permanent_represent_types_id ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.permanent_represent_types_id}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>

                                                                <>
                                                                    <div className="col-12 col-md-6 pt-2 position-relative">
                                                                        <input
                                                                            hidden
                                                                            id='upload-document-file'
                                                                            type="file"
                                                                            onChange={(e) => { documentHandler(e.target.files) }}
                                                                        />
                                                                        <label
                                                                            className="pop-upload-file position-relative"
                                                                            htmlFor="upload-document-file"
                                                                        >
                                                                            {documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}
                                                                        </label>

                                                                        <AttachIcon className="download-icon right-0 me-2" />
                                                                        {
                                                                            errorsReturned?.file ?
                                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                    <div className="col-12 col-md-6 pt-2 position-relative" >
                                                                        <input
                                                                            className='add-user-inputs'
                                                                            id='file_name'
                                                                            type="text"
                                                                            placeholder={generalData?.website_titles['file-name']}
                                                                            onChange={(e) => { setFileName(e.target.value) }}
                                                                            value={fileName}
                                                                        />
                                                                        {
                                                                            errorsReturned?.file_name ?
                                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_name}</h5>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </>

                                                                <div className="col-12 col-md-6 pt-2" >
                                                                    <div className="postion-relative text-start justify-content-start w-100">
                                                                        <Datepicker className="popup-date add-user-inputs"
                                                                            placeholderText={generalData?.website_titles['date']}
                                                                            value={fileDate ? fileDate : ''}
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            dropdownMode="select"
                                                                            selected={fileDate}
                                                                            dateFormat="d MMMM yyyy"
                                                                            onChange={(date) => { setFileDate(date); }}
                                                                            minDate={addYears(new Date(), -50)}
                                                                            maxDate={addYears(new Date(), +100)}
                                                                            locale={localStorage.getItem("LmsLanguage")}
                                                                        />
                                                                    </div>
                                                                    {
                                                                        errorsReturned?.transaction_date ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.transaction_date}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>



                                                                <div className="col-12 pt-2">
                                                                    <input name="mandate_term" value={mandateTerm} onChange={(e) => setMandateTerm(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['mandate-term']} />
                                                                    {
                                                                        errorsReturned?.mandate_term ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.mandate_term}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>


                                                                <div className="col-12 pt-2">
                                                                    <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.website_titles['comment']} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                                                    {
                                                                        errorsReturned?.comment ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        errorsReturned?.board_exists ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <button type="submit" className="popup-button my-auto">{currentMember?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }

                        {
                            //delete popup
                            deletePopup ?
                                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <CloseIcon className="cursor-pointer" onClick={(e) => cancelDeletePopup(e)} />
                                        <div className="text-center ">
                                            <div className="containter-fluid">
                                                <div className="row d-flex justify-content-center align-items-center">
                                                    <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                        <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                        <button onClick={(e) => deleteElement(e)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                        <button onClick={(e) => cancelDeletePopup(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        <Api options={generalDataApiOptions} />
                    </>
                    :
                    null
            }
        </>
    )
}