import { useContext, useEffect, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Select from 'react-select';
import Popup from "./Popup";
import { AddIcon, CalendarIcon, LocationIcon, LocationIconHover, NotificationsIcon } from "./Icons";
import NotificationsMenuComponent from "./NotificationsMenuComponent";
import useOutsideClick from "../useOutsideClick";
import { useRef } from "react";
import HearingPopupComponent from "./HearingPopupComponent";
import TaskPopupComponent from "./TaskPopupComponent";
import LitigationPopupComponent from "./LitigationPopupComponent";
import ClientPopup from "./ClientPopup";
import { getIndex } from "../Helpers";
import { LmsLogoSmall, LmsLogoText1, LmsLogoText2 } from './Icons';

//custom select styles
const customStyles = {
    dropdownIndicator: base => ({
        ...base,
        marginTop: "-1px !important",
        color: "white !important",
        margin: "0px",
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: '16px',
            color: 'white',
        }
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            color: isSelected ? '#00366A' : '#B1B1B1',
            fontFamily: 'Poppins',
            fontWeight: '400',
            fontSize: "16px",
            backgroundColor: 'white',
            "&:hover": {
                backgroundColor: "transparent",
                color: "#00366A",
            },
            margin: "auto",
            paddingTop: "5px",
            paddingBottom: "0px",
        };
    },
    singleValue: base => ({
        ...base,
        color: "white",
    }),
    control: provided => ({
        ...provided,
        border: "none",
        backgroundColor: 'transparent',
        outline: "none",
        '&:hover': { borderColor: '#393939' },
        textTransform: 'uppercase',
        boxShadow: 0,
        borderRadius: "50px",
        fontFamily: 'Poppins',
        fontWeight: '400',
    }),
    menu: (provided, state) => ({
        ...provided,
        borderRadius: "0px 0px 7px 7px",
        marginTop: "13px",
        width: "56px",
        textTransform: 'uppercase',
        border: "1px solid transparent",
        boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
    }),
    input: (base, state) => ({
        ...base,
        color: "#00366A",
        fontSize: "16px",
        height: "100%",
        width: "10px",
        fontFamily: 'Poppins',
        textTransform: 'uppercase',
        fontWeight: '400',
        marginTop: "16px",
        '& input': {
            font: 'inherit',
        },
    })
};

export default function Layout(props) {

    const [language, setLanguage] = useState(localStorage.getItem("LmsLanguage") ? localStorage.getItem("LmsLanguage") : 'en');
    const {
        generalDataGlobal,
        authUserGlobal,
        chechkAccessGlobal,
        logoutGlobal,
        currentClientGlobal: [, setCurrentClient],
        profileClientGlobal,
        unreadRemindersGlobal,
        unreadNotificationsGlobal,
        setUpdateDashboardDataGlobal
    } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [unreadReminder,] = unreadRemindersGlobal;
    const [unreadNotifications, setUnreadNotifications] = unreadNotificationsGlobal;
    const [authUser, setAuthUser] = authUserGlobal;
    const [usersMenuOpen, setUsersMenuOpen] = useState(false);
    const [logoutPopup, setLogoutPopup] = logoutGlobal;
    const [menuClicked] = useState(false);
    const [hoverOn, setHoverOn] = useState('');
    const [notificationsMenu, setNotificationsMenu] = useState(false);
    const [quickActionsMenu, setQuickActionsMenu] = useState(false);
    const [addPopup, setAddPopup] = useState({});
    const [popupTitle, setPopupTitle] = useState();
    const history = useNavigate();
    const [customSnoozePopup, setCustomSnoozePopup] = useState(false);

    const { activePage } = props;
    //get all languages
    const allLanguages = () => {
        const languageOptions = generalData?.languages?.map(
            singleOption => ({ value: singleOption.slug, label: singleOption.slug, Key: singleOption.id }))
        return languageOptions;
    }

    const notificationsMenuRef = useRef();

    const quickActionsRef = useRef();

    const removeScrollLock = () => {
        document.getElementsByTagName("html")[0].classList.remove('lock-scroll')
    }
    //handle mobile close user menu
    useEffect(() => {
        if (window.innerWidth < 991 && usersMenuOpen) {
            removeScrollLock();
            setUsersMenuOpen(!usersMenuOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuClicked]);

    //hanlde language change
    useEffect(() => {
        if (language) {
            generalData?.languages?.forEach(single => {
                if (single?.slug === language?.value) {
                    localStorage.setItem("LmsLanguage", single?.slug);
                    document.getElementsByTagName("html")[0].setAttribute("lang", single?.slug);
                    window.location.reload();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    useOutsideClick(notificationsMenuRef, () => {
        if (!customSnoozePopup) {
            setNotificationsMenu(false);
        }
    })

    useOutsideClick(quickActionsRef, () => {
        setQuickActionsMenu(false);
    })

    const handleQuickActionClick = (type) => {
        setAddPopup({
            open: true,
            type: type
        })
        setPopupTitle(generalData?.website_titles[type] || generalData?.mattersCommonTitles[type])
    }

    const submitSearch = (e, data) => {
        history('/search?search_value=' + data);
    }

    return (
        <>
            {
                generalData ?
                    <>
                        <div className="container-fluid">
                            <div className="row no-gutters">
                                <div className="col-12 p-0">
                                    <div className="header-background">
                                        <div className="row h-100">
                                            <div className="col-8 col-lg-6 my-auto ps-4 ps-lg-0">
                                                <div className="padding-search-header w-100">
                                                    {/* submitSearch */}
                                                    <input onKeyDown={(e) => e?.key === 'Enter' && submitSearch(e, e.target.value)} name="search" className="header-search" placeholder={generalData?.fixed_titles['search']} />
                                                </div>
                                            </div>
                                            <div className="col-4 col-lg-6 d-flex justify-content-end my-auto pe-4">
                                                <div ref={quickActionsRef} onClick={() => { setQuickActionsMenu(!quickActionsMenu) }} className={`small-picture-header me-2 d-none d-lg-flex position-relative cursor-pointer onhover-active-svg ${quickActionsMenu ? 'active' : ''}`} >
                                                    <AddIcon className={'active-onhover'} fill={'#144676'} />
                                                    <ul className={`dropdown-menu bg-white top-100 end-0 rounded border-0 shadow-primary ${quickActionsMenu ? 'show' : ''}`} aria-labelledby="dropdownMenuButton1" style={{ transform: 'translateY(15px)' }}>
                                                        <li>
                                                            <button onClick={() => { handleQuickActionClick('company'); setCurrentClient({ client_type_id: 1 }) }} className="dropdown-item">{generalData?.website_titles['company']}</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => { handleQuickActionClick('individual'); setCurrentClient({ client_type_id: 2 }) }} className="dropdown-item">{generalData?.website_titles['individual']}</button>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => { handleQuickActionClick('ngo'); setCurrentClient({ client_type_id: 3 }) }} className="dropdown-item">{generalData?.website_titles['ngo']}</button>
                                                        </li>
                                                        {
                                                            (activePage !== 'events' && activePage !== 'tasks' && activePage !== 'dashboard') &&
                                                            <li>
                                                                <button onClick={() => { handleQuickActionClick('task') }} className="dropdown-item">{generalData?.website_titles['task']}</button>
                                                            </li>
                                                        }
                                                        {
                                                            (activePage !== 'dashboard') &&
                                                            <li>
                                                                <button onClick={() => { handleQuickActionClick('litigation') }} className="dropdown-item">{generalData?.mattersCommonTitles['litigation']}</button>
                                                            </li>
                                                        }
                                                        {
                                                            (activePage !== 'events' && activePage !== 'dashboard') &&
                                                            <li>
                                                                <button onClick={() => { handleQuickActionClick('hearing') }} className="dropdown-item">{generalData?.mattersCommonTitles['hearing']}</button>
                                                            </li>
                                                        }
                                                    </ul>

                                                </div>
                                                {
                                                    authUser?.user_role_id === 1 &&
                                                    <Link to={'/locations'} className={`small-picture-header me-2 d-none d-lg-flex cursor-pointer onhover-active-svg onhover-show-on-hover position-relative ${activePage === 'locations' ? 'active' : ''}`} >
                                                        <LocationIcon />
                                                        <LocationIconHover />
                                                    </Link>
                                                }

                                                <div ref={notificationsMenuRef} style={{ pointerEvents: 'initial' }} className="small-picture-header me-2 notifications-button-home position-relative" >
                                                    {
                                                        (unreadReminder > 0 || unreadNotifications > 0) &&
                                                        <div className="red-dot-div" ></div>
                                                    }
                                                    <div style={{ padding: '10px' }} onClick={(e) => { setNotificationsMenu(!notificationsMenu); setTimeout(() => { setUnreadNotifications(0) }, 300) }} className="clickable-icon">
                                                        <NotificationsIcon />
                                                    </div>
                                                    {
                                                        notificationsMenu &&
                                                        <NotificationsMenuComponent
                                                            customSnoozePopup={customSnoozePopup}
                                                            setCustomSnoozePopup={setCustomSnoozePopup}
                                                            setNotificationsMenu={setNotificationsMenu}
                                                            notificationsMenu={notificationsMenu}
                                                        />

                                                    }
                                                </div>
                                                <Link to={`/events`} className={`${activePage === 'events' ? 'active' : ''} small-picture-header me-2 d-none d-lg-flex notifications-button-home`}>
                                                    <CalendarIcon />
                                                </Link>
                                                <Link to={'/profile/' + authUser?.id + '/general-info'} >
                                                    <img style={{ border: "1px solid white" }} className="profile-picture-header me-2 d-none d-lg-flex" src={authUser?.picture ? authUser?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="profile" />
                                                </Link>
                                                <div className="position-relative d-none d-lg-block">
                                                    <Select
                                                        classNamePrefix="languages-select"
                                                        className="languages-select"
                                                        styles={customStyles}
                                                        options={allLanguages()}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        isSearchable={false}
                                                        value={language ? allLanguages()[getIndex(language, allLanguages(), 'value')] : null}
                                                        onChange={setLanguage} />
                                                </div>
                                                <div style={{ zIndex: "99999999" }} className="ps-2 d-block d-lg-none my-auto">
                                                    <div id="burger-button" className={"nav-icon2 burger-button" + (usersMenuOpen ? " open" : "")} onClick={() => { setUsersMenuOpen(!usersMenuOpen); !usersMenuOpen ? document.getElementsByTagName("html")[0].classList.add('lock-scroll') : document.getElementsByTagName("html")[0].classList.remove('lock-scroll') }}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={"users-menu-mobile d-block d-lg-none " + (usersMenuOpen ? ' active' : '')}>
                                    <div className="user-menu-wrapper-mobile">
                                        <div className="users-menu-logo p-3 w-100 pb-4">
                                            <div className="d-flex justify-content-center">
                                                <img src={'/assets/images/logo.svg'} alt="logo" />
                                            </div>
                                        </div>
                                        <div onClick={(e) => removeScrollLock()} className="d-flex justify-content-between pb-4">
                                            <Link to={'/profile/' + authUser?.id + '/general-info'} className="d-flex mobile-title-padd my-auto">
                                                <div className="d-flex justify-content-center">
                                                    <img style={{ border: "1px solid white" }} className="profile-picture-mobile logo-size" src={authUser?.picture ? authUser?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="profile" />
                                                    <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['profile']}</span>
                                                </div>
                                            </Link>
                                            <div className="d-flex justify-content-center">
                                                <Select
                                                    classNamePrefix="languages-select"
                                                    className="languages-select"
                                                    styles={customStyles}
                                                    options={allLanguages()}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    isSearchable={false}
                                                    value={language ? allLanguages()[getIndex(language, allLanguages(), 'value')] : null}
                                                    onChange={setLanguage} />
                                            </div>
                                        </div>
                                        <div className="users-menu-pages pt-4 pb-4">
                                            <Link onClick={(e) => removeScrollLock()} to={'/'} className={"d-flex mobile-title-padd pb-3"}>
                                                <div className="d-flex justify-content-center">
                                                    <img src={'/assets/images/dashboard.svg'} alt='dashboard' />
                                                    <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['dashboard']}</span>
                                                </div>
                                            </Link>
                                            <Link onClick={(e) => removeScrollLock()} to={'/contacts'} className={"d-flex mobile-title-padd pb-3"}>
                                                <div className="d-flex justify-content-center">
                                                    <img src={'/assets/images/contacts.svg'} alt="contacts" />
                                                    <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['contacts']}</span>
                                                </div>
                                            </Link>
                                            <Link onClick={(e) => removeScrollLock()} to={'/tasks'} className={"d-flex mobile-title-padd pb-3"}>
                                                <div className="d-flex justify-content-center">
                                                    <img src={'/assets/images/tasks.svg'} alt="tasks" />
                                                    <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['tasks']}</span>
                                                </div>
                                            </Link>
                                            <Link onClick={(e) => removeScrollLock()} to={'/finances/transactions'} className={"d-flex mobile-title-padd pb-3"}>
                                                <div className="d-flex justify-content-center">
                                                    <img className="logo-size" src={'/assets/images/finances.svg'} alt="finances" />
                                                    <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['finances']}</span>
                                                </div>
                                            </Link>
                                            <Link onClick={(e) => removeScrollLock()} to={'/matters'} className={"d-flex mobile-title-padd pb-3"}>
                                                <div className="d-flex justify-content-center">
                                                    <img className="logo-size" src={'/assets/images/matters.svg'} alt="matters" />
                                                    <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['matters']}</span>
                                                </div>
                                            </Link>
                                            <Link onClick={(e) => removeScrollLock()} className={"d-flex mobile-title-padd pb-3"}>
                                                <div className="d-flex justify-content-center">
                                                    <img className="logo-size" src={'/assets/images/locations.svg'} alt="locations" />
                                                    <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['locations']}</span>
                                                </div>
                                            </Link>
                                            {
                                                chechkAccessGlobal(authUser, 'settings', 'browse') === 1 ?
                                                    <Link onClick={(e) => removeScrollLock()} to={'/settings'} className={"d-flex mobile-title-padd pb-3"}>
                                                        <div className="d-flex justify-content-center">
                                                            <img className="logo-size" src={'/assets/images/settings.svg'} alt="settings" />
                                                            <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['settings']}</span>
                                                        </div>
                                                    </Link>
                                                    :
                                                    null
                                            }
                                            <div className={"d-flex mobile-title-padd pb-3 cursor-pointer"} onClick={() => setLogoutPopup(true)}>
                                                <div className="d-flex justify-content-center">
                                                    <img className="logo-size" src={'/assets/images/logout.svg'} alt="logout" />
                                                    <span className="ps-2 mobile-menu-titles my-auto">{generalData?.fixed_titles['logout']}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                                <div style={{ zIndex: "999999999" }}>
                                    <div className="users-menu ">
                                        <div className="user-menu-wrapper">
                                            <div className="users-menu-logo p-3 w-100 pb-5">
                                                {/* <div className="user-menu-logo-div">
                                                    <LmsLogoSmall />
                                                </div>
                                                <div className="logo-text ps-2">
                                                    <LmsLogoText1 />
                                                    <LmsLogoText2 />
                                                </div> */}
                                            </div>
                                            <div className="users-menu-pages">
                                                <Link onMouseLeave={() => props.activePage === 'dashboard' ? null : setHoverOn('')} onMouseEnter={() => setHoverOn('dashboard')} to={'/'} className={"links-div-users d-flex padd-icons-user-menu my-auto " + (props.activePage === 'dashboard' ? " active" : "")}>
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            hoverOn === 'dashboard' || props.activePage === 'dashboard' ?
                                                                <img src={'/assets/images/dashboard-hover.svg'} alt='dashboard' />
                                                                :
                                                                <img src={'/assets/images/dashboard.svg'} alt='dashboard' />

                                                        }
                                                        <span className="ps-3">{generalData?.fixed_titles['dashboard']}</span>
                                                    </div>
                                                </Link>
                                                <Link to={'/contacts'} onMouseLeave={() => props.activePage === 'contacts' ? null : setHoverOn('')} onMouseEnter={() => setHoverOn('contacts')} className={"links-div-users d-flex padd-icons-user-menu my-1 " + (props.activePage === 'contacts' ? " active" : "")}>
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            hoverOn === 'contacts' || props.activePage === 'contacts' ?
                                                                <img src={'/assets/images/contacts-hover.svg'} alt="contacts hover" />
                                                                :
                                                                <img src={'/assets/images/contacts.svg'} alt="contacts" />
                                                        }
                                                        <span className="ps-3">{generalData?.fixed_titles['contacts']}</span>
                                                    </div>
                                                </Link>
                                                <Link to={'/tasks'} onMouseLeave={() => props.activePage === 'tasks' ? null : setHoverOn('')} onMouseEnter={() => setHoverOn('tasks')} className={"links-div-users d-flex padd-icons-user-menu my-1 " + (props.activePage === 'tasks' ? " active" : "")}>
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            hoverOn === 'tasks' || props.activePage === 'tasks' ?
                                                                <img src={'/assets/images/tasks-hover.svg'} alt="tasks hover" />
                                                                :
                                                                <img src={'/assets/images/tasks.svg'} alt="tasks" />
                                                        }
                                                        <span className="ps-3">{generalData?.fixed_titles['tasks']}</span>
                                                    </div>
                                                </Link>
                                                <Link to={'/finances/transactions'} onMouseLeave={() => props.activePage === 'finances' ? null : setHoverOn('')} onMouseEnter={() => setHoverOn('finances')} className={"links-div-users d-flex padd-icons-user-menu my-1 " + (props.activePage === 'finances' ? " active" : "")}>
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            hoverOn === 'finances' || props.activePage === 'finances' ?
                                                                <img src={'/assets/images/finances-hover.svg'} alt="finances hover" />
                                                                :
                                                                <img src={'/assets/images/finances.svg'} alt="finances" />
                                                        }
                                                        <span className="ps-3">{generalData?.fixed_titles['finances']}</span>
                                                    </div>
                                                </Link>
                                                <Link to={'/matters'} onMouseLeave={() => props.activePage === 'matters' ? null : setHoverOn('')} onMouseEnter={() => setHoverOn('matters')} className={"links-div-users d-flex padd-icons-user-menu my-1 " + (props.activePage === 'matters' ? " active" : "")}>
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            hoverOn === 'matters' || props.activePage === 'matters' ?
                                                                <img className="logo-size" src={'/assets/images/matters-hover.svg'} alt="matters hover" />
                                                                :
                                                                <img className="logo-size" src={'/assets/images/matters.svg'} alt="matters" />
                                                        }
                                                        <span className="ps-3">{generalData?.fixed_titles['matters']}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="users-settings-div ">
                                                {
                                                    chechkAccessGlobal(authUser, 'settings', 'browse') === 1 ?
                                                        <Link to={'/settings'} onMouseLeave={() => props.activePage === 'settings' ? null : setHoverOn('')} onMouseEnter={() => setHoverOn('settings')} className={"links-div-users d-flex padd-icons-user-menu my-1 " + (props.activePage === 'settings' ? " active" : "")}>
                                                            <div className="d-flex align-items-center">
                                                                {
                                                                    hoverOn === 'settings' || props.activePage === 'settings' ?
                                                                        <img src={'/assets/images/settings-hover.svg'} alt="settings hover" />
                                                                        :
                                                                        <img src={'/assets/images/settings.svg'} alt="settings" />
                                                                }
                                                                <span className="ps-3">{generalData?.fixed_titles['settings']}</span>
                                                            </div>
                                                        </Link>
                                                        :
                                                        null
                                                }
                                                <div onMouseLeave={() => props.activePage === 'logout' ? null : setHoverOn('')} onMouseEnter={() => setHoverOn('logout')} className="cursor-pointer links-div-users d-flex padd-icons-user-menu my-auto " onClick={() => setLogoutPopup(true)}>
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            hoverOn === 'logout' ?
                                                                <img src={'/assets/images/logout-hover.svg'} alt="logout" />
                                                                :
                                                                <img src={'/assets/images/logout.svg'} alt="logout" />
                                                        }
                                                        <span className="ps-3">{generalData?.fixed_titles['logout']}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div>
                            </div>
                        </div>
                        <div className="website-dashboard-content pb-5">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 p-0">
                                        <div className="row">
                                            <div className="col-12 pb-5 pb-lg-0">
                                                {props.children}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            //logout popup
                            logoutPopup ?
                                <Popup logout={1} logoutTitle={generalData?.fixed_titles['logout']} cancelTitle={generalData?.website_titles['cancel']} title={generalData?.fixed_titles['logout-message']} />
                                :
                                null
                        }
                    </>
                    :
                    null
            }

            {
                //litigation popup
                addPopup.open && addPopup?.type === 'litigation' &&
                <LitigationPopupComponent
                    popupTitle={popupTitle}
                    setAddMatterPopup={setAddPopup}
                    addMatterPopup={addPopup}
                    currentPage={1}
                // returnRecord={1}
                // onSuccess={(res) => { navigate(`/matters/litigations/${res?.data?.id}/general-info`) }}
                />
            }

            {
                // hearing popup 
                addPopup?.open && addPopup?.type === 'hearing' &&
                <HearingPopupComponent
                    popupTitle={popupTitle}
                    setAddMatterPopup={setAddPopup}
                    addMatterPopup={addPopup}
                    // returnTable={'events'}
                    // onSuccess={(res) => { navigate(`/matters/litigations/${res?.data?.id}/general-info`) }}
                    returnRecord={1}
                />
            }

            {
                addPopup?.open && addPopup?.type === 'task' &&
                <TaskPopupComponent
                    setAddPopup={setAddPopup}
                    popupTitle={popupTitle}
                    // onSuccess={(res) => { navigate(`/tasks/${res?.data?.id}/general-info`) }}
                    returnRecord={1}
                />
            }

            {
                // company popup 
                addPopup?.open && (addPopup?.type === 'company' || addPopup?.type === 'individual' || addPopup?.type === 'ngo') &&
                <ClientPopup
                    popupTitle={popupTitle}
                    clientType={
                        addPopup?.type ?
                            addPopup?.type === 'company' ?
                                1
                                :
                                (
                                    addPopup?.type === 'individual' ?
                                        2
                                        :
                                        addPopup?.type === 'ngo' ?
                                            3
                                            :
                                            1
                                )
                            :
                            1
                    }
                    // returnRecord={1}
                    setAddPopup={setAddPopup}
                    currentClient={profileClient ? profileClient?.id : null}
                    onSuccess={(res) => { res?.data?.dashboard ? setUpdateDashboardDataGlobal(res?.data?.dashboard) : (res?.data?.auth_user ? setAuthUser(res?.data?.auth_user) : setProfileClient(res?.data) )}}
                />
            }
        </>
    );
}