import React, { useContext, useEffect, useRef, useState } from 'react';
import Layout from "../components/Layout";
import { ClientsIcon, CloseIcon, GreenArrowDown, MattersIcon, Pencil, RedArrowDown, ThreeDotsMenu, WhiteCalculators } from '../components/Icons';
import GlobalState from '../contexts/GlobalState';
import { Link, useNavigate } from 'react-router-dom';
import UsersSelect from '../components/UsersSelect';
import StatusComponent from '../components/StatusComponent';
import Api from '../components/Api';
import HearingPopupComponent from '../components/HearingPopupComponent';
import HearingCommonPopup from './HearingCommonPopup';
import LitigationPopupComponent from '../components/LitigationPopupComponent';
import TaskPopupComponent from '../components/TaskPopupComponent';
import ClientPopup from '../components/ClientPopup';
import { addYears, formatDate, getIndex } from '../Helpers';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import DashboardCalendarComponent from '../components/DashboardCalendarComponent';
import DashboardBigCalendarComponent from '../components/DashboardBigCalendarComponent';
import MeetingPopupComponent from '../components/MeetingPopupComponent';
import ReminderPopup from '../components/ReminderPopup';


export default function Dashboard() {
    const {
        generalDataGlobal: [generalData],
        generalDataGlobal: [{ matterSectionsSettings }],
        authUserGlobal: [authUser],
        currentClientGlobal: [, setCurrentClient],
        hearings, setHearings,
        litigations, setLitigations,
        setAllLitigations,
        currentReminderEditGlobal: [, setCurrentReminderEditGl],
        updateDashboardDataGlobal
    } = useContext(GlobalState);

    const navigate = useNavigate();
    const { statuses, tasksStatuses } = generalData;

    //get all transaction types
    const allTransactionTypes = () => {
        const transactionType = generalData?.transaction_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return transactionType;
    }

    //get all users
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: <div><img className="select-image me-1" src={singleOption?.picture ? singleOption?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt={singleOption?.name} />{singleOption?.name}</div> }))
        return curren;
    }

    //get all currencies
    const allCurrencies = () => {
        const curren = generalData?.currencies?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title, slug: singleOption.slug }))
        return curren;
    }


    // general states 
    const [apiOptions, setApiOptions] = useState({});
    const [hoverOn,] = useState('');
    const editMenuRef = useRef();
    const [openEditMenu, setOpenEditMenu] = useState();
    const [deletePopup, setDeletePopup] = useState(false);
    const [, setTarget] = useState();
    const [deleteEvent, setDeleteEvent] = useState();
    const [eventsByDate, setEventsByDate] = useState({});

    // contacts states 
    const [totalContacts, setTotalContacts] = useState(0);
    const [companies, setCompanies] = useState();
    const [clientType,] = useState(1);
    const [clientCategory,] = useState();

    // matters states 
    const [activeMatterType,] = useState('hearing');
    const [addPopup, setAddPopup] = useState({});
    const [popupTitle, setPopupTitle] = useState();
    const [activeEditUsers, setActiveEditUsers] = useState();
    const [currentPage,] = useState();

    // hearing states 
    const [editHearing, setEditHearing] = useState();
    const [hearingCommonPopup, setHearingCommonPopup] = useState();

    // litigations states 
    const [editLitigation, setEditLitigation] = useState();
    const [activeSelect, setActiveSelect] = useState();
    const [totalLitigations, setTotalLitigations] = useState(0);
    const [popupContent, setPopupContent] = useState(null);

    // tasks states
    const [tasks, setTasks] = useState();
    const [totalTasks, setTotalTasks] = useState(0);
    const [editTask, setEditTask] = useState();
    const [activeTasksType, setActiveTasksType] = useState('my-tasks');

    // transactions states 
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [transactions, setTransactions] = useState();
    const [transactionCurrency, setTransactionCurrency] = useState('');
    const [transactionTo, setTransactionTo] = useState(allUsers()[getIndex(Number(authUser?.id), allUsers(), 'value')]);
    const [transactionAmount, setTransactionAmount] = useState('');
    const [addTransactionDate, setAddTransactionDate] = useState(new Date());
    const [addTransactionNote, setAddTransactionNote] = useState('');
    const [currenEditTransaction, setCurrenEditTransaction] = useState('');
    const [errorsReturned, setErrorsReturned] = useState({});
    const [, setOpenEditTransaction] = useState(null);
    const [transactionType, setTransactionType] = useState(allTransactionTypes()[getIndex(5, allTransactionTypes(), 'value')]);

    // calendar states 
    const [activeView, setActiveView] = useState('month');
    const [activeDay, setActiveDay] = useState(new Date());
    const [calendarEvents, setCalendarEvents] = useState();

    // meetings states 
    const [editMeeting, setEditMeeting] = useState();

    // reminders states 
    const [currentReminderEdit, setCurrentReminderEdit] = useState()


    // close transaction popup and reset states
    function closeAddTransaction(e) {
        e.preventDefault();
        setAddPopup(false);
        setTransactionAmount('');
        setTransactionCurrency('');
        setAddTransactionDate(new Date());
        setCurrenEditTransaction(null);
        setAddTransactionNote('');

        setErrorsReturned({});
    }

    useEffect(() => {
        if(updateDashboardDataGlobal){
            updateStates(updateDashboardDataGlobal);
        }
    },[updateDashboardDataGlobal]);// eslint-disable-line react-hooks/exhaustive-deps

    // fetch dashboard data 
    useEffect(() => {
        updateDashboardData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleTableEditSingleFieldClick = (record, fieldName, fieldTitle) => {
        setHearingCommonPopup(true);
        setPopupTitle(generalData?.hearingFixedTitles[fieldTitle]);
        setPopupContent({
            record: record,
            fieldName: fieldName,
            returnIndex: 1
        })
    }

    // const handleAssigneesChange = (data) => {
    //     updateDashboardData(activeTasksType);
    // }

    const updateDashboardData = (type = 'my-tasks') => {
        setOpenEditMenu(null)
        setApiOptions({
            url: 'dashboard',
            method: 'post',
            data: {
                tasksType: type
            },
            callback: ({ data }) => {
                updateStates(data);
                setOpenEditMenu(null)
                // updateEventsData(activeDay)
            },
            catch: err => {

            }
        })
    }

    const updateEventsData = (date, hidden) => {
        setApiOptions({
            url: 'events',
            method: 'post',
            hideLoaderInPage: hidden ?? 0,
            data: {
                date: formatDate(date),
                meetings: 1,
                hearings: 1,
                tasks: 1,
                reminders: 1,
            },
            callback: ({ data: { upcomingEvents, calendarEvents } }) => {
                setCalendarEvents(calendarEvents)
            },
            catch: (err => {
            })
        })
    }

    const submitDeleteEvent = (data) => {
        let event_id = data?.id;
        setApiOptions({
            url: '/delete-dashboard-data',
            method: 'post',
            data: {
                model: data?.event_type,
                target_id: event_id,
                tasksType: activeTasksType,
            },
            callback: ({ data }) => {
                setCalendarEvents(prevEvents => prevEvents.filter(event => event.id !== event_id));
                updateStates(data);
                setDeletePopup(false);
                setOpenEditMenu(null);
            },
            catch: err => {
                if (err) {
                    // triggerEditMenu(null);
                }
            }
        })
    }

    //custom select styles
    const customStylesPopupCurr = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //custom select styles
    const customStylesUsersPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "2px",
                paddingTop: "2px",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    const updateStates = (data) => {
        updateEventsData(activeDay, 1);
        setTotalContacts(data?.companies?.total);
        setTotalLitigations(data?.litigations?.total);
        setTotalTasks(data?.tasks?.total);
        setHearings(data?.hearings);
        setLitigations(data?.litigations);
        setAllLitigations(data?.allLitigations);
        setTasks(data?.tasks);
        setCompanies(data?.companies?.data);
        setTotalTransactions(data?.transactions?.total);
        setTransactions(data?.transactions?.data);
        setEventsByDate({
            meetings: data?.meetingsByDate,
            hearings: data?.hearingsByDate,
            tasks: data?.tasksByDate,
            reminders: data?.remindersByDate
        })
    }

    //submit transactions
    const submitTransactions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        fd.append('transaction_type_id', transactionType ? transactionType?.value : '');
        fd.append('currency_id', transactionCurrency ? transactionCurrency?.value : '');
        fd.append('receiver_id', transactionTo ? transactionTo?.value : '');
        fd.append('currency', 'usd');
        fd.append('user_id', authUser?.id);
        fd.append('amount', transactionAmount ? transactionAmount : '');
        fd.append('date', addTransactionDate ? formatDate(addTransactionDate) : '');
        fd.append('note', addTransactionNote ? addTransactionNote : '');
        fd.append('return_dashboard', 1);


        setApiOptions({
            url: 'add-user-transaction',
            method: 'post',
            data: fd,
            callback: res => {
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
                updateStates(res?.data);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };


    const handleEditClick = (e, data) => {
        setApiOptions({});
        e.stopPropagation();
        setAddPopup({
            open: true,
            type: data?.event_type,
            target: data
        });
        setPopupTitle(generalData?.website_titles[data?.event_type]);
        if (data?.event_type === 'hearing') {
            setEditHearing(data);
        }
        if (data?.event_type === 'reminder') {
            setCurrentReminderEditGl(data);
        }
        if (data?.event_type === 'meeting') {
            setEditMeeting(data);
        }
        if (data?.event_type === 'task') {
            setEditTask(data);
        }
    }

    const handleDeleteClick = (e, event) => {
        e.stopPropagation();
        setDeleteEvent(event);
        setDeletePopup(true);
        setApiOptions({});

    }

    return (
        <>
            <Api options={apiOptions} />

            <Layout activePage="dashboard">
                {/* header starts */}

                <div className='row'>
                    <div className='col-12'>
                        <h4 className='fw-600'>{generalData?.users_cost_settings['quick-overview']}</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-xl-3 col-lg-4 col-md-6 mb-3 ">
                        <Link to="/contacts" >
                            <div className='finances-box bg-white'>
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <div className="background-svg-finances">
                                            <ClientsIcon />
                                        </div>
                                    </div>
                                    <div className="col ps-0 pe-0 d-flex flex-column my-auto">
                                        <h1 className="big-numbers-finances m-0">{totalContacts}</h1>
                                        <h4 className="big-titles-finances m-0">{generalData?.contacts_settings['total-contacts']}</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 col-xl-3 col-lg-4 col-md-6 mb-3 ">
                        <Link to="/matters">
                            <div className='finances-box bg-white'>
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <div className="background-svg-finances">
                                            <MattersIcon />
                                        </div>
                                    </div>
                                    <div className="col ps-0 pe-0 d-flex flex-column my-auto">
                                        <h1 className="big-numbers-finances m-0">{totalLitigations}</h1>
                                        <h4 className="big-titles-finances m-0">{generalData?.litigationFixedTitles['total-litigations']}</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 col-xl-3 col-lg-4 col-md-6 mb-3 ">
                        <Link to="/tasks">
                            <div className='finances-box bg-white'>
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <div className="background-svg-finances">
                                            {
                                                hoverOn === 'tasks' ?
                                                    <img src={'/assets/images/tasks-hover.svg'} alt="tasks hover" />
                                                    :
                                                    <img src={'/assets/images/tasks.svg'} alt="tasks" />
                                            }
                                        </div>
                                    </div>
                                    <div className="col ps-0 pe-0 d-flex flex-column my-auto">
                                        <h1 className="big-numbers-finances m-0">{totalTasks}</h1>
                                        <h4 className="big-titles-finances m-0">{generalData?.tasksFixedTitles['total-tasks']}</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 col-xl-3 col-lg-4 col-md-6 mb-3 ">
                        <Link to="/finances/transactions">
                            <div className='finances-box bg-white'>
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <div className="background-svg-finances">
                                            <div className="d-flex align-items-center">
                                                <WhiteCalculators />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col ps-0 pe-0 d-flex flex-column my-auto">
                                        <h1 className="big-numbers-finances m-0">{totalTransactions}</h1>
                                        <h4 className="big-titles-finances m-0">{generalData?.website_titles['total-transactions']}</h4>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* header ends  */}


                {/* hearings  */}
                <div className='row mt-4'>
                    <div className='col-12'>
                        <h4 className='fw-600'>{generalData?.hearingFixedTitles['my-hearings']}</h4>
                    </div>
                    <div className='col-12'>
                        <>
                            <div className="row g-0">
                                <div className="col-12 p-0 height-100 pb-0 w-100 costs-box-styling bg-white px-4">
                                    <div className='w-100 d-flex py-3 '>
                                        <button onClick={() => { setAddPopup({ open: true, type: 'hearing' }); setPopupTitle(generalData?.mattersCommonTitles['hearing']) }} className='btn ms-auto text-primary onhover-link'>{generalData?.mattersCommonTitles['new-hearing']}</button>
                                    </div>
                                    <div className="table-wrapper-settings" style={{ height: 'auto' }}>
                                        <>
                                            <table className="users-table" width="100%" >
                                                <thead>
                                                    <tr>
                                                        <td width="40px" style={{ minWidth: "20px", width: "20px", maxWidth: "20px" }}></td>
                                                        <td className="table-sorting" style={{ minWidth: "250px", width: "250px", maxWidth: "250px" }}>
                                                            {generalData?.hearingFixedTitles['hearing-number']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.matterSectionsSettings['litigation-title']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.hearingFixedTitles['hearing-date-time']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.hearingFixedTitles['litigation-number-date']}
                                                            {/* <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'stage_title' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" /> */}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.matterSectionsSettings['stage']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.matterSectionsSettings['assignees']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.hearingFixedTitles['hearing-type']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.hearingFixedTitles['court-name']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.hearingFixedTitles['court-level']}
                                                            {/* <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'court_level' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" /> */}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.hearingFixedTitles['hearing-summary']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.hearingFixedTitles['client-summary']}
                                                        </td>

                                                        <td className="table-sorting" style={{ minWidth: "300px", width: "300px", maxWidth: "300px" }}>
                                                            {generalData?.hearingFixedTitles['previous-hearing-date']}
                                                            {/* <img className={"ms-2" + (sortedDirection === 'DESC' && order === 'appeal_date' ? ' active' : '')} src={'/assets/images/arrow-icon.svg'} alt="arrow" /> */}
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        hearings?.data?.length > 0 ?
                                                            hearings?.data?.map((hearing, index) => (
                                                                <tr key={index} onClick={() => { navigate(`/matters/litigations/${hearing?.litigation?.id}/general-info`) }}>
                                                                    <td width="20px" className="text-end p-0 pe-2 position-relative" >
                                                                        <ThreeDotsMenu ref={editMenuRef} onClick={(e) => { e.stopPropagation(); openEditMenu?.id === hearing?.id ? setOpenEditMenu(null) : setOpenEditMenu({ ...hearing, menuType: 'hearing' }) }} />
                                                                        <div style={{ width: 200 }} className={"settings-clickmenu first-td-buttons text-start " + ((hearing?.id === openEditMenu?.id && openEditMenu?.menuType === 'hearing') ? ' d-block' : '')}>
                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEditHearing(hearing); setAddPopup({ open: true, type: 'hearing', target: hearing, returnIndex: 1 }); setPopupTitle(generalData?.mattersCommonTitles['hearing']) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                            <p style={{ zIndex: "99" }} onClick={(e) => {
                                                                                e.stopPropagation(); e.preventDefault(); e.stopPropagation();
                                                                                setDeleteEvent(hearing);
                                                                                setDeletePopup(true);
                                                                                setApiOptions({});
                                                                            }}
                                                                                className="p-0 py-1 m-0 ms-2"
                                                                            >
                                                                                {generalData?.settings_section_settings['delete-title']}
                                                                            </p>
                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEditHearing(hearing); setAddPopup({ open: true, type: 'hearing', action: 'copy', returnIndex: 1 }); setPopupTitle(generalData?.matterTypes[1]?.title) }} className="p-0 py-1 m-0 ms-2">{generalData?.hearingFixedTitles['copy']}</p>
                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); handleTableEditSingleFieldClick(hearing, 'report', 'create-a-report') }} className="p-0 py-1 m-0 ms-2">{hearing?.report ? generalData?.hearingFixedTitles['view-report'] : generalData?.hearingFixedTitles['create-a-report']}</p>
                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEditHearing(hearing); setAddPopup({ open: true, type: 'hearing', showMore: true, returnIndex: 1 }); setPopupTitle(generalData?.matterTypes[1]?.title) }} className="p-0 py-1 m-0 ms-2">{hearing?.result_description ? generalData?.hearingFixedTitles['view-judgment'] : generalData?.hearingFixedTitles['determine-judgment']}</p>
                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); navigate(`/matters/litigations/${hearing?.litigation?.id}/costs`) }} className="p-0 py-1 m-0 ms-2">{generalData?.website_titles['costs']}</p>
                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); navigate(`/matters/litigations/${hearing?.litigation?.id}/reminder`) }} className="p-0 py-1 m-0 ms-2">{generalData?.website_titles['reminder']}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>{hearing.number}</td>
                                                                    <td>{hearing?.litigation?.title}</td>
                                                                    <td>{hearing?.hearing_date}</td>
                                                                    <td>{hearing?.litigation?.establish_date} / {hearing?.litigation?.number}</td>
                                                                    <td>{hearing?.stage?.stage?.title}</td>
                                                                    <td onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
                                                                        <div className="w-100">
                                                                            <UsersSelect
                                                                                url={'/add-edit-assignees'}
                                                                                activeEditUsers={activeEditUsers}
                                                                                setActiveEditUsers={setActiveEditUsers}
                                                                                data={hearing}
                                                                                users={hearing?.assignees}
                                                                                model={'Hearing'}
                                                                                clientId={''}
                                                                                tasksType={activeTasksType}
                                                                                className="litigation-user-select m-0"
                                                                                page={currentPage}
                                                                                type={'hearing'}
                                                                                returnDashboard={1}
                                                                                onChange={(res) => {updateStates(res)}}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {hearing?.hearing_type?.title}
                                                                    </td>

                                                                    <td className="" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                                        {hearing?.stage?.court_name}
                                                                    </td>
                                                                    <td>
                                                                        {hearing?.stage?.court_degree}
                                                                    </td>
                                                                    <td>
                                                                        <div className="w-100 h-100 position-relative pe-2 cursor-pointer">
                                                                            <div className="position-absolute top-0 end-0" style={{ zIndex: 2 }}>
                                                                                <Pencil onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleTableEditSingleFieldClick(hearing, 'hearing_summary', 'hearing-summary') }} />
                                                                            </div>
                                                                            {
                                                                                hearing?.hearing_summary ?
                                                                                    hearing?.hearing_summary?.length > 27 ?
                                                                                        <>
                                                                                            <div className="position-relative user-image-in-table">
                                                                                                <span >
                                                                                                    <>{`${hearing?.hearing_summary.substring(0, 27)}...`}</>
                                                                                                </span>
                                                                                                {

                                                                                                    <>
                                                                                                        <div className="triangle-down"></div>
                                                                                                        <p className="hover-username" style={{ maxWidth: '100%', fontSize: '22px !important' }}>{hearing?.hearing_summary}</p>
                                                                                                    </>

                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        hearing?.hearing_summary
                                                                                    :
                                                                                    '-'
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="w-100 h-100 position-relative pe-2 cursor-pointer">
                                                                            <div className="position-absolute top-0 end-0" style={{ zIndex: 2 }}>
                                                                                <Pencil onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleTableEditSingleFieldClick(hearing, 'client_summary', 'client-summary') }} />
                                                                            </div>
                                                                            {
                                                                                hearing?.client_summary ?
                                                                                    hearing?.client_summary?.length > 27 ?
                                                                                        <>
                                                                                            <div className="position-relative user-image-in-table">
                                                                                                <span >
                                                                                                    <>{`${hearing?.client_summary.substring(0, 27)}...`}</>
                                                                                                </span>
                                                                                                {

                                                                                                    <>
                                                                                                        <div className="triangle-down"></div>
                                                                                                        <p className="hover-username" style={{ maxWidth: '100%', fontSize: '22px !important' }}>{hearing?.client_summary}</p>
                                                                                                    </>

                                                                                                }
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        hearing?.client_summary
                                                                                    :
                                                                                    '-'
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>{hearing?.previous_hearing?.hearing_date}</td>
                                                                </tr>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                hearings?.data?.length === 0 &&
                                                <div className="text-center">
                                                    <p>{matterSectionsSettings['empty-records'].replace('%record%', activeMatterType.title)}</p>
                                                </div>
                                            }
                                        </>

                                    </div>
                                    <div className="col-12 justify-content-center align-items-center py-4">
                                        <Link to={'/matters/hearings'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>

                {/* companies transactions & calendar  */}
                <div className='row mt-4'>

                    {/* companies and transactions */}
                    <div className='col-lg-6'>
                        <div className='row mb-3'>
                            <div className='col-12'>
                                <h4 className='fw-600'>{generalData?.website_titles['companies-recap']}</h4>
                                <div className='row'>
                                    <div className="col-12">
                                        <div className='costs-box-styling bg-white'>
                                            <div className='w-100 d-flex py-3 '>
                                                <button
                                                    onClick={() => {
                                                        setAddPopup({ open: true, type: 'company' });
                                                        setPopupTitle('company');
                                                        setCurrentClient({ client_type_id: 1 })
                                                    }}
                                                    className='btn ms-auto text-primary onhover-link'
                                                >
                                                    {generalData?.website_titles['new-company']}
                                                </button>
                                            </div>
                                            <div className='row pb-3'>
                                                {
                                                    companies?.map((company, indexCompany) => (
                                                        <Link key={indexCompany} to={`/client/${company?.id}/general-info`}>
                                                            <div className='col-12 mb-3'>
                                                                <div className='shadow-primary mx-3 p-3 rounded'>
                                                                    <div className='row gx-0 align-items-center'>
                                                                        <div className='col-1'>
                                                                            <div className='ratio ratio-1x1'>

                                                                                <img
                                                                                    className="circle border-primary border"
                                                                                    src={company?.picture ? company?.full_path_picture : generalData?.fixed_settings?.full_path_no_company_picture}
                                                                                    alt="chat user"
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                        <div className='col-11 ps-3'>
                                                                            <h6 >{company?.name}</h6>
                                                                            <p>{company?.description}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-12'>
                                <h4 className='fw-600'>{generalData?.website_titles['my-transactions']}</h4>
                                <div className='row'>
                                    <div className="col-12">
                                        <div className='costs-box-styling bg-white'>
                                            <div className='w-100 d-flex py-3 '>
                                                <button
                                                    onClick={() => {
                                                        setAddPopup({ open: true, type: 'transaction' });
                                                        setPopupTitle(generalData?.mattersCommonTitles['hearing'])
                                                    }}
                                                    className='btn ms-auto text-primary onhover-link'
                                                >
                                                    {generalData?.website_titles['new-transaction']}
                                                </button>
                                            </div>
                                            <div className='row'>
                                                {
                                                    transactions?.map((transaction, indexTransaction) => (
                                                        <div key={indexTransaction} className='col-12 mb-3'>
                                                            <div className='shadow-primary mx-3 px-3 rounded'>
                                                                <div className='row align-items-center'>
                                                                    <div className='col-auto border-end border-primary py-3'>
                                                                        <div className='d-flex flex-column '>
                                                                            <div className="user-image-in-table d-flex align-items-center">
                                                                                <img className="table-user-image" src={transaction?.user?.picture ? transaction?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                <p className="ms-2 fs-14">{Number(transaction?.user?.id) === Number(authUser?.id) ? generalData?.fixed_titles['me'] : transaction?.user?.name}</p>
                                                                            </div>
                                                                            <div className={`table-user-image d-flex align-items-center justify-content-center ${Number(transaction?.user?.id) !== Number(authUser?.id) ? 'rotate-180-center' : ''}`}>
                                                                                {
                                                                                    Number(transaction?.user?.id) === Number(authUser?.id) ?
                                                                                        // red arrow 
                                                                                        <RedArrowDown className='h-100' />
                                                                                        :
                                                                                        // green arrow 
                                                                                        <GreenArrowDown className="justify-content-center align-items-center my-2" />
                                                                                }

                                                                            </div>
                                                                            <div className="user-image-in-table d-flex align-items-center ">
                                                                                <img className="table-user-image" src={transaction?.receiver?.picture ? transaction?.receiver?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                <p className="ms-2 fs-14">{Number(transaction?.receiver_id) === Number(authUser?.id) ? generalData?.fixed_titles['me'] : transaction?.receiver?.name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-auto flex-grow-1 py-3'>
                                                                        <div className='w-100 h-100 d-flex justify-content-between'>
                                                                            <h6>{transaction?.transaction_type?.title}</h6>
                                                                            <p style={{ color: Number(transaction?.receiver_id) === Number(authUser?.id) ? "#30D309" : "#D30915" }}>{transaction?.amount} {transaction?.currency?.title}</p>
                                                                        </div>
                                                                        <div className='w-100 h-100 d-flex justify-content-between'>
                                                                            <h6 className='text-gray' >{transaction?.date}</h6>
                                                                            <span style={transaction?.transaction_stat_id === 1 ? { color: "#30D309" } : (transaction?.transaction_stat_id === 2 ? { color: "#D30915" } : { color: "#FDC32D" })} >{transaction?.transaction_stat?.title}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }


                                            </div>
                                            <div className="col-12 justify-content-center align-items-center py-4">
                                                <Link to={'/finances/transactions'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* calendar  */}
                    <div className='col-lg-6'>
                        <h4 className='fw-600'>{generalData?.eventsFixedTitles['calendar']}</h4>
                        <div className='row'>
                            <div className="col-12">
                                <div className='costs-box-styling bg-white d-flex flex-column' style={{ height: activeView === 'day' ? '1122px' : 'auto' }}>
                                    <div className='w-100 d-flex py-4 custom-toolbar'>
                                        <div>
                                            <button className={`btn onhover-active ${activeView === 'day' ? 'active' : ''}`} onClick={() => { setActiveView('day') }}>{generalData?.website_titles['day']}</button>
                                            <button className={`btn onhover-active ${activeView === 'month' ? 'active' : ''}`} onClick={() => { setActiveView('month') }}>{generalData?.website_titles['month']}</button>
                                        </div>
                                    </div>
                                    <div className='row pb-4'
                                    // style={{ height: "calc(100% - 68px)" }}
                                    >
                                        <div className='col-12'
                                        // style={{ height: "calc(100% - 300px)" }}
                                        >
                                            {
                                                activeView === 'day' ?
                                                    <DashboardBigCalendarComponent
                                                        activeDay={activeDay}
                                                        setActiveDay={setActiveDay}
                                                        data={eventsByDate}
                                                        handleEditClick={handleEditClick}
                                                        handleDeleteClick={handleDeleteClick}
                                                        updateEventsData={updateEventsData}
                                                        calendarEvents={calendarEvents}
                                                        setCalendarEvents={setCalendarEvents}
                                                    />
                                                    :
                                                    <DashboardCalendarComponent
                                                        setActiveView={setActiveView}
                                                        setActiveDay={setActiveDay}
                                                        activeDay={activeDay}
                                                        data={eventsByDate}
                                                    />
                                            }
                                        </div>

                                    </div>

                                    <div className='d-flex justify-content-end mt-auto' >
                                        <div className='ms-3 d-flex align-items-center' >
                                            <div
                                                style={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '100%',
                                                    background: generalData?.eventsColor['task'],
                                                    marginRight: 10

                                                }}
                                            ></div>
                                            <p style={{ color: generalData?.eventsColor['task'] }}>

                                                {eventsByDate['tasks']?.length} {generalData?.website_titles['tasks']}
                                            </p>
                                        </div>
                                        <div className='ms-3 d-flex align-items-center' >
                                            <div
                                                style={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '100%',
                                                    background: generalData?.eventsColor['reminder'],
                                                    marginRight: 10

                                                }}
                                            ></div>
                                            <p style={{ color: generalData?.eventsColor['reminder'] }}>

                                                {eventsByDate['reminders']?.length} {generalData?.website_titles['reminders']}
                                            </p>
                                        </div>
                                        <div className='ms-3 d-flex align-items-center' >
                                            <div
                                                style={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '100%',
                                                    background: generalData?.eventsColor['hearing'],
                                                    marginRight: 10

                                                }}
                                            ></div>
                                            <p style={{ color: generalData?.eventsColor['hearing'] }}>
                                                {eventsByDate['hearings']?.length} {generalData?.website_titles['hearings']}
                                            </p>
                                        </div>

                                        <div className='ms-3 d-flex align-items-center' >
                                            <div
                                                style={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '100%',
                                                    background: generalData?.eventsColor['meeting'],
                                                    marginRight: 10

                                                }}
                                            ></div>
                                            <p style={{ color: generalData?.eventsColor['meeting'] }}>
                                                {eventsByDate['meetings']?.length} {generalData?.website_titles['meetings']}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-12 justify-content-center align-items-center py-4">
                                        <Link to={'/events'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* litigations  */}
                <div className="row mt-4">
                    <div className='col-12'>
                        <h4 className='fw-600'>{generalData?.litigationFixedTitles['litigations-recap']}</h4>
                    </div>
                    <div className='col-12'>
                        <div className="row g-0">

                            <div className="col-12 p-0 height-100 pb-0 w-100 costs-box-styling bg-white px-4 ">
                                <div className='w-100 d-flex py-3 '>
                                    <button
                                        className='btn ms-auto text-primary onhover-link'
                                        onClick={() => {
                                            setAddPopup({ open: true, type: 'litigations' });
                                            setPopupTitle(generalData?.mattersCommonTitles['litigation'])
                                        }}
                                    >
                                        {generalData?.mattersCommonTitles['new-litigation']}
                                    </button>
                                </div>
                                <div className="table-wrapper-settings" style={{ height: 'auto' }}>

                                    <>
                                        <table className="users-table" width="100%" >
                                            <thead>
                                                <tr>
                                                    <td width="40px" style={{ minWidth: "20px", width: "20px", maxWidth: "20px" }}></td>
                                                    <td className="table-sorting" style={{ minWidth: "250px", width: "250px", maxWidth: "250px" }}>
                                                        {generalData?.litigationFixedTitles['litigation-number']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "350px", maxWidth: "350px" }}>
                                                        {generalData?.litigationFixedTitles['litigation-title']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>
                                                        {generalData?.litigationFixedTitles['work-area']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>
                                                        {generalData?.mattersCommonTitles['stage']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>
                                                        {generalData?.mattersCommonTitles['assignees']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "200px", width: "200px" }}>
                                                        {generalData?.litigationFixedTitles['opponents-names']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                        {generalData?.litigationFixedTitles['status']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                        {generalData?.litigationFixedTitles['clients-names']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                        {generalData?.litigationFixedTitles['litigation-subject']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                        {generalData?.litigationFixedTitles['client-description']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                        {generalData?.litigationFixedTitles['establish-date']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                        {generalData?.litigationFixedTitles['appeal-date']}
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    litigations?.data?.length > 0 ?
                                                        litigations?.data?.map((litigation, index) => (
                                                            <tr key={index} onClick={() => navigate(`/matters/litigations/${litigation?.id}/general-info`)}>
                                                                <td width="20px" className="text-end p-0 pe-2 position-relative" >
                                                                    <ThreeDotsMenu ref={editMenuRef} onClick={(e) => { e.stopPropagation(); openEditMenu?.id === litigation?.id ? setOpenEditMenu(null) : setOpenEditMenu({ ...litigation, menuType: 'litigation' }) }} />
                                                                    <div style={{}} className={"settings-clickmenu first-td-buttons text-start " + ((litigation?.id === openEditMenu?.id && openEditMenu?.menuType === 'litigation') ? ' d-block' : '')}>
                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setEditLitigation(litigation); setAddPopup({ open: true, type: 'litigations' }); setPopupTitle(generalData?.mattersCommonTitles['litigation']) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                        <p style={{ zIndex: "200" }} onClick={(e) => {
                                                                            e.stopPropagation(); e.preventDefault(); e.stopPropagation();
                                                                            setDeleteEvent(litigation);
                                                                            setDeletePopup(true);
                                                                            setApiOptions({});
                                                                        }}
                                                                            className="p-0 py-1 m-0 ms-2"
                                                                        >
                                                                            {generalData?.settings_section_settings['delete-title']}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>{litigation.number}</td>
                                                                <td>{litigation.title}</td>

                                                                <td>{litigation?.work_area}</td>
                                                                <td>
                                                                    {
                                                                        litigation?.stages?.length > 0 ?
                                                                            litigation?.stages[0]?.stage?.title
                                                                            :
                                                                            '-'

                                                                    }
                                                                </td>
                                                                <td onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                                                    <div className="w-100" >
                                                                        <UsersSelect
                                                                            url={'/add-edit-assignees'}
                                                                            activeEditUsers={activeEditUsers}
                                                                            setActiveEditUsers={setActiveEditUsers}
                                                                            data={litigation}
                                                                            users={litigation?.assignees}
                                                                            model={'Litigation'}
                                                                            type={'litigations'}
                                                                            clientId={''}
                                                                            className="litigation-user-select m-0"
                                                                            returnDashboard={1}
                                                                            onChange={(res) => {updateStates(res)}}
                                                                            page={currentPage}
                                                                            return={'index'}
                                                                            tasksType={activeTasksType}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {litigation?.opponents[0]?.client?.name || '-'}
                                                                </td>
                                                                <td className="" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                                    <StatusComponent
                                                                        api={'edit-litigation-status'}
                                                                        selectedStatus={litigation?.status}
                                                                        statuses={statuses}
                                                                        index={index}
                                                                        target={litigation.id}
                                                                        targetName={'litigation_id'}
                                                                        type="litigation"
                                                                        activeSelect={activeSelect}
                                                                        setActiveSelect={setActiveSelect}

                                                                    />
                                                                    {/* <div className="text-center currency-text text-white" style={{ backgroundColor: litigation?.status?.background_color }}>
                                                                                            {litigation?.status?.title}
                                                                                        </div> */}
                                                                </td>
                                                                <td>
                                                                    {litigation?.clients[0]?.client?.name || '-'}
                                                                </td>
                                                                <td>
                                                                    {litigation?.subject}
                                                                </td>
                                                                <td>
                                                                    {litigation?.clients[0]?.description || '-'}
                                                                </td>
                                                                <td>
                                                                    {litigation?.establish_date}
                                                                </td>
                                                                <td>
                                                                    {litigation?.appeal_date}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        :
                                                        null
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            litigations?.data?.length === 0 &&
                                            <div className="text-center">
                                                <p>{matterSectionsSettings['empty-records']?.replace('%record%', activeMatterType.title)}</p>
                                            </div>
                                        }
                                    </>


                                </div>
                                <div className="col-12 justify-content-center align-items-center py-4">
                                    <Link to={'/matters'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* tasks  */}
                <div className="row mt-4">
                    <div className='col-12'>
                        <h4 className='fw-600'>{generalData?.fixed_titles['tasks']}</h4>
                    </div>
                    <div className='col-12'>
                        <div className="row g-0">

                            <div className="col-12 p-0 height-100 pb-0 w-100 costs-box-styling bg-white px-4 ">
                                <div className='py-3 d-flex justify-content-between'>
                                    <div className='custom-toolbar'>
                                        <button className={`btn onhover-active me-4 ${activeTasksType === 'my-tasks' ? 'active' : ''}`} onClick={() => { setActiveTasksType('my-tasks'); updateDashboardData('my-tasks'); }}>{generalData?.tasksFixedTitles['my-tasks']}</button>
                                        <button className={`btn onhover-active ${activeTasksType === 'requested-tasks' ? 'active' : ''}`} onClick={() => { setActiveTasksType('requested-tasks'); updateDashboardData('requested-tasks'); }}>{generalData?.tasksFixedTitles['requested-by-me']}</button>
                                    </div>
                                    <div className='d-flex'>
                                        <button onClick={() => { setAddPopup({ open: true, type: 'task' }); setPopupTitle(generalData?.website_titles['task']) }} className='btn ms-auto text-primary onhover-link'>{generalData?.website_titles['new-task']}</button>
                                    </div>
                                </div>
                                <div className="table-wrapper-settings" style={{ height: 'auto' }}>

                                    <>
                                        <table className="users-table" width="100%">
                                            <thead>
                                                <tr>
                                                    <td width="40px" style={{ minWidth: "20px", width: "20px", maxWidth: "20px" }}></td>
                                                    <td className="table-sorting" style={{ minWidth: "250px", width: "250px", maxWidth: "250px" }}>
                                                        {generalData?.website_titles['name']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "350px", maxWidth: "350px" }}>
                                                        {generalData?.website_titles['number']}
                                                    </td>



                                                    <td className="table-sorting" style={{ minWidth: "200px", width: "200px", maxWidth: "200px" }}>
                                                        {generalData?.mattersCommonTitles['assignees']}
                                                    </td>


                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                        {generalData?.litigationFixedTitles['status']}
                                                    </td>

                                                    <td className="table-sorting" style={{ minWidth: "300px", width: "300px" }}>
                                                        {generalData?.tasksFixedTitles['due-date']}
                                                    </td>


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tasks?.data?.length > 0 ?
                                                        tasks?.data?.map((task, index) => (
                                                            <tr key={index} onClick={() => navigate(`/tasks/${task?.id}/general-info`)}>
                                                                <td width="20px" className="text-end p-0 pe-2 position-relative" >
                                                                    <ThreeDotsMenu ref={editMenuRef} onClick={(e) => { e.stopPropagation(); openEditMenu?.id === task?.id ? setOpenEditMenu(null) : setOpenEditMenu({ ...task, menuType: 'task' }) }} />
                                                                    <div style={{}} className={"settings-clickmenu first-td-buttons text-start " + ((task?.id === openEditMenu?.id && openEditMenu?.menuType === 'task') ? ' d-block' : '')}>
                                                                        <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); setEditTask(task); setAddPopup({ open: true, type: 'tasks' }); setPopupTitle(generalData?.website_titles['task']) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                        <p style={{ zIndex: "200" }} onClick={(e) => {
                                                                            e.stopPropagation(); e.preventDefault(); e.stopPropagation();
                                                                            setDeleteEvent(task);
                                                                            setDeletePopup(true);
                                                                            setApiOptions({});
                                                                        }}
                                                                            className="p-0 py-1 m-0 ms-2"
                                                                        >
                                                                            {generalData?.settings_section_settings['delete-title']}
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                                <td>{task?.name}</td>
                                                                <td>{`TA${task?.id}`}</td>
                                                                {/* {
                                                                    clientType === 1 ?
                                                                        <td>{litigation?.legal_type?.title}</td>
                                                                        :
                                                                        null
                                                                } */}

                                                                <td onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                                                    <div className="w-100" >
                                                                        <UsersSelect
                                                                            url={'/add-edit-task-assignees'}
                                                                            activeEditUsers={activeEditUsers}
                                                                            setActiveEditUsers={setActiveEditUsers}
                                                                            data={task}
                                                                            users={task?.assignees}
                                                                            model={'Task'}
                                                                            type={'tasks'}
                                                                            clientId={''}
                                                                            className="litigation-user-select m-0"
                                                                            // onChange={handleAssigneesChange}
                                                                            page={currentPage}
                                                                            returnDashboard={1}
                                                                            onChange={(res) => {updateStates(res)}}
                                                                            return={'index'}
                                                                            tasksType={activeTasksType}
                                                                        />
                                                                    </div>

                                                                </td>

                                                                <td className="" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                                                    <StatusComponent
                                                                        api={'edit-task-status'}
                                                                        selectedStatus={task?.status}
                                                                        statuses={tasksStatuses}
                                                                        index={index}
                                                                        type="tasks"
                                                                        target={task.id}
                                                                        targetName={'task_id'}
                                                                        activeSelect={activeSelect}
                                                                        setActiveSelect={setActiveSelect}
                                                                        tasksType={activeTasksType}

                                                                    />
                                                                    {/* <div className="text-center currency-text text-white" style={{ backgroundColor: litigation?.status?.background_color }}>
                                                                                            {litigation?.status?.title}
                                                                                        </div> */}
                                                                </td>

                                                                <td>
                                                                    {task?.date}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        :
                                                        null
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            tasks?.data?.length === 0 &&
                                            <div className="text-center">
                                                <p>{generalData?.website_titles['empty-record'].replace('%record%', generalData.website_titles['tasks'])}</p>
                                            </div>
                                        }
                                    </>


                                </div>
                                <div className="col-12 justify-content-center align-items-center py-4">
                                    <Link to={'/tasks'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout >

            {
                //litigation popup
                addPopup.open && addPopup?.type === 'litigations' &&
                <LitigationPopupComponent
                    editLitigation={editLitigation}
                    popupTitle={popupTitle}
                    setAddMatterPopup={setAddPopup}
                    addMatterPopup={addPopup}
                    setTotalLitigations={setTotalLitigations}
                    currentPage={1}
                    returnDashboard={1}
                    tasksType={activeTasksType}
                    onSuccess={(res) => {updateStates(res?.data); setOpenEditMenu(null) }}
                />
            }
            {
                // hearing popup 
                (addPopup?.open && addPopup?.type === 'hearing') &&
                <HearingPopupComponent
                    returnDashboard={1}
                    editHearing={editHearing}
                    setEditHearing={setEditHearing}
                    popupTitle={popupTitle}
                    setAddMatterPopup={setAddPopup}
                    addMatterPopup={addPopup}
                    setTotalLitigations={setTotalLitigations}
                    currentPage={1}
                    tasksType={activeTasksType}
                    onSuccess={(res) => { updateStates(res.data); setOpenEditMenu(null) }}

                />
            }

            {
                // hearing common popup 
                hearingCommonPopup &&
                <HearingCommonPopup
                    popupTitle={popupTitle}
                    commonPopup={hearingCommonPopup}
                    setCommonPopup={setHearingCommonPopup}
                    popupContent={popupContent}
                    returnDashboard={1}
                    setPopupContent={setPopupContent}
                    currentPage={1}
                    tasksType={activeTasksType}
                    onSuccess={(res) => { updateStates(res?.data); setOpenEditMenu(null) }}
                />
            }

            {
                // tasks popup
                addPopup?.open && ( addPopup?.type === 'tasks' || addPopup?.type === 'task') &&
                <TaskPopupComponent
                    setAddPopup={setAddPopup}
                    editTask={editTask}
                    returnDashboard={1}
                    setEditTask={setEditTask}
                    tasksType={activeTasksType}
                    onSuccess={(res) => {setOpenEditMenu(null); updateStates(res?.data)}}
                    popupTitle={popupTitle}
                />
            }

            {
                // company popup 
                addPopup?.open && addPopup?.type === 'company' &&
                <ClientPopup
                    popupTitle={popupTitle}
                    returnDashboard={1}
                    tasksType={activeTasksType}
                    clientCategory={clientCategory ? clientCategory?.value : ''}
                    clientType={clientType}
                    setAddPopup={setAddPopup}
                    onSuccess={(res) => { setOpenEditMenu(null); updateStates(res?.data) }}
                />

            }

            {
                addPopup?.open && addPopup?.type === 'meeting' &&
                <MeetingPopupComponent
                    handleClose={() => { setAddPopup(false) }}
                    popupTitle={popupTitle}
                    setEditMeeting={setEditMeeting}
                    editMeeting={editMeeting}
                    returnDashboard={1}
                    tasksType={activeTasksType}
                    onSuccess={(res) => { updateStates(res?.data); setAddPopup(false) }}
                    setAddPopup={setAddPopup}
                />
            }

            {
                //reminder popup
                addPopup?.open && addPopup?.type === 'reminder' &&
                <ReminderPopup
                    selectedDate={activeDay}
                    handleClose={() => { setAddPopup(false) }}
                    onSuccess={(res) => { updateStates(res); setAddPopup(false) }}
                    currentReminderEdit={currentReminderEdit}
                    setCurrentReminderEdit={setCurrentReminderEdit}
                    showClient={true}
                    returnDashboard={1}
                    tasksType={activeTasksType}
                    // marks={profileClient?.marks}
                    clientId={''}
                />
            }

            {
                //transactions popup
                addPopup?.open && addPopup?.type === 'transaction' &&
                <div style={{zIndex: '12222'}} id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="pop-up-api-box">
                        <CloseIcon className="cursor-pointer" onClick={(e) => setAddPopup(false)} />
                        <div className="text-center ">
                            <div className="containter-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-title'] : generalData?.users_cost_settings['add-transaction-title']}</h1>
                                    </div>
                                    <form onSubmit={submitTransactions}>
                                        <div className="col-12 pt-2 pb-4">
                                            <div className="row">
                                                <div className="col-12 col-md-6 pt-2">
                                                    <div className="postion-relative text-start justify-content-start w-100">
                                                        <Select
                                                            classNamePrefix="popup-select"
                                                            className="popup-select w-100"
                                                            styles={customStylesPopup}
                                                            options={allTransactionTypes()}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.users_cost_settings['transaction-type']}
                                                            isSearchable={false}
                                                            // isDisabled={true}
                                                            value={transactionType}
                                                            onChange={setTransactionType}
                                                        />
                                                    </div>
                                                    {
                                                        errorsReturned?.transaction_type_id ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.transaction_type_id}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="col-12 col-md-6 pt-2">
                                                    <div className="position-relative text-start justify-content-start w-100">
                                                        <input name="amount" value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                        <Select
                                                            classNamePrefix="curr-select"
                                                            className="curr-select w-100"
                                                            styles={customStylesPopupCurr}
                                                            options={allCurrencies()}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.users_cost_settings['curr-transaction']}
                                                            isSearchable={false}
                                                            value={transactionCurrency ? transactionCurrency : null}
                                                            onChange={setTransactionCurrency} />
                                                    </div>
                                                    {
                                                        errorsReturned?.currency_id ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                            :
                                                            null
                                                    }
                                                    {
                                                        errorsReturned?.amount ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="col-12 col-md-6 pt-2">
                                                    <div className="postion-relative text-start justify-content-start w-100">
                                                        <DatePicker
                                                            className="popup-date add-user-inputs"
                                                            placeholderText={generalData?.users_cost_settings['date']}
                                                            value={addTransactionDate ? addTransactionDate : ''}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            selected={addTransactionDate}
                                                            dateFormat="d MMMM yyyy"
                                                            onChange={(date) => setAddTransactionDate(date)}
                                                            minDate={addYears(new Date(), -21)}
                                                            maxDate={new Date()}
                                                            locale={localStorage.getItem("LmsLanguage")}
                                                        />
                                                    </div>
                                                    {
                                                        errorsReturned?.date ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="col-12 col-md-6 pt-2">
                                                    <div className="postion-relative text-start justify-content-start w-100">
                                                        <Select
                                                            classNamePrefix="popup-select"
                                                            className="popup-select w-100"
                                                            styles={customStylesUsersPopup}
                                                            options={allUsers()}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.users_cost_settings['transaction-to']}
                                                            isSearchable={false}
                                                            // isDisabled={true}
                                                            value={transactionTo ? transactionTo : null}
                                                            onChange={setTransactionTo} />
                                                    </div>
                                                    {
                                                        errorsReturned?.receiver_id ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.receiver_id}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="col-12 pt-2">
                                                    <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addTransactionNote} onChange={(e) => setAddTransactionNote(e.target.value)}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <button type="submit" className="popup-button my-auto">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-button'] : generalData?.users_cost_settings['add-transaction-button']}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }

            {
                //delete Hearing popup
                deletePopup &&
                <div style={{ zIndex: '122222' }} id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                    <div className="pop-up-api-box">
                        <CloseIcon className="cursor-pointer" onClick={() => { setDeleteEvent(null); setTarget(null); setDeletePopup(false); }} />
                        <div className="text-center ">
                            <div className="containter-fluid">
                                <div className="row d-flex justify-content-center align-items-center">
                                    <div className="col-9 col-sm-12 d-flex justify-content-center">
                                        <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                        <button onClick={() => submitDeleteEvent(deleteEvent || null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                        <button onClick={() => { setDeleteEvent(null); setTarget(null); setDeletePopup(false); }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            }
        </>
    )
}