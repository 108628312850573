import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import GlobalState from '../contexts/GlobalState';
import React from 'react';
import Api from "../components/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { getIndex, formatDate } from "../Helpers";


import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { CloseIcon, CalculatorIcon, TransactionsArrows, EditIcon, IndividualIcon, NgoIcon, CompanyIcon, BlueCalculators, AttachIconInput, TableArrow1, TableArrow2 } from "../components/Icons";
registerLocale('ar', ar)

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Finances(props) {

    const { generalDataGlobal, authUserGlobal, profileUserGlobal } = useContext(GlobalState);
    const { slug } = useParams();
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [expensesCall, setExpensesCall] = useState({});
    const [generalData] = generalDataGlobal;
    const history = useNavigate();
    const [authUser, setAuthUser] = authUserGlobal;
    const [usersApiOptions,] = useState({});
    const [profileUser,] = profileUserGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [costType, setCostType] = useState('');
    const [costCurrency, setCostCurrency] = useState('usd');
    const [showCurr, setShowCurr] = useState('usd');
    const [expenses, setExpenses] = useState({
        'total': 'x',
    });
    const ref = useRef(null);

    const [wallet, setWallet] = useState({
        "total": 'x',
        "salary": 0,
        "other": 0,
        "bonuses": 0,
    });

    //handle slug change
    useEffect(() => {
        if (slug) {
            if (slug === 'transactions') {
                setCostType(2);
            }
            if (slug === 'expenses') {
                setCostType(1);
            }
        } else {
            history('/not-found');
        }
    }, [slug, costType]);// eslint-disable-line react-hooks/exhaustive-deps

    const [chartData, setChartData] = useState({});
    /* expenses start */
    const [addExpenses, setAddExpenses] = useState(false);
    const [editExpense, setEditExpense] = useState(null);
    const [currenEditExpense, setCurrenEditExpense] = useState('');
    const [addExpensesType, setAddExpensesType] = useState('');
    const [addExpensesAmount, setAddExpensesAmount] = useState('');
    const [addExpensesCurrency, setAddExpensesCurrency] = useState('');
    const [addExpensesDate, setAddExpensesDate] = useState(new Date());
    const [addExpensesNote, setAddExpensesNote] = useState('');
    const [addExpensesBillable, setAddExpensesBillable] = useState(0);
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState('');
    const [, setExpensesLinks] = useState([]);
    const [expensesCurrentPage, setExpensesCurrentPage] = useState(1);
    const [ , setExpensesLastPage] = useState(1);
    const [expensesChartData, setExpensesChartData] = useState([0, 0, 0, 0]);
    const [walletChartData, setWalletChartData] = useState([0, 0, 0, 0]);
    const [walletChart, setWalletChart] = useState({});
    const [filterDateFrom, setFilteDateFrom] = useState();
    const [filterDateTo, setFilteDateTo] = useState();

    //get all expenses filter types
    const allExpenseFilters = () => {
        const expFilter = generalData?.expense_filters?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expFilter;
    }

    const [currentFilterExpenses, setCurrentFilterExpenses] = useState(allExpenseFilters()[2]);
    const [changeExpenses, setChangeExpenses] = useState(0);
    const [changeWallet, setChangeWallet] = useState(0);
    const [customDatesPopup, setCustomDatesPopup] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    /* expenses end */

    /* transactions start */
    const [addTransaction, setAddTransaction] = useState(false);
    const [openEditTransaction, setOpenEditTransaction] = useState(null);
    const [transactionType, setTransactionType] = useState('');
    const [transactionCurrency, setTransactionCurrency] = useState('');
    const [transactionTo, setTransactionTo] = useState('');
    const [currenEditTransaction, setCurrenEditTransaction] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');
    const [addTransactionDate, setAddTransactionDate] = useState(new Date());
    const [addTransactionNote, setAddTransactionNote] = useState('');
    const [, setTransactionLinks] = useState([]);
    const [transactionCurrentPage, setTransactionCurrentPage] = useState(1);
    const [ , setTransactionLastPage] = useState(1);

    //get all client types
    const clientTypes = () => {
        const clientTy = generalData?.client_types?.map(
            singleType => ({ slug: singleType?.slug, value: singleType.id, label: singleType.title }))
        return clientTy;
    }

    const [rejectTransaction, setRejectTransaction] = useState(false);
    const [approveTransaction, setApproveTransaction] = useState(false);
    const [currentStatusTransaction, setCurrentStatusTransaction] = useState('');
    const [transactionRejectReason, setTransactionRejectReason] = useState('');
    const [deletePopup, setDeletePopup] = useState(null);
    const [deleteType, setDeleteType] = useState(null);
    const [showCustomSelectMenu, setShowCustomSelectMenu] = useState(false);
    const [customRelationFilter, setCustomRelationFilter] = useState('individual');
    const [relationName, setRelationName] = useState('');
    /* transactions start */

    //get all individual relations
    const individualRelations = () => {
        const relOptions = authUser?.individual_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get all company relations
    const companyRelations = () => {
        const relOptions = authUser?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get all ngo relations
    const ngoRelations = () => {
        const relOptions = authUser?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get all relations from general data
    const allRelations = () => {
        const relOptions = authUser?.relation_names?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    /* handle click outside to close cusotm dropdown start */
    const useOutsideClick = (ref, callback) => {
        const handleClick = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };

        useEffect(() => {
            window?.document?.addEventListener("click", handleClick);

            return () => {
                window?.document?.removeEventListener("click", handleClick);
            };
        });
    };

    useOutsideClick(ref, () => {
        setShowCustomSelectMenu(false);
    });

    /* handle click outside to close cusotm dropdown end */


    //custom select styles
    const customStylesPopupMulti = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            minWidth: '40px !important'
        })
    };

    // reset states
    useEffect(() => {
        setExpenses({
            'total': 'x',
        });
        setWallet({
            'total': 'x',
        });
        setExpensesCurrentPage(1);
        setCurrentFilterExpenses(allExpenseFilters()[2]);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //handle change dates
    const onChangeDate = (dates) => {
        const [start, end] = dates;
        setFilteDateFrom(start);
        setFilteDateTo(end);
    };

    //reset current pagination pages
    useEffect(() => {
        setExpensesCurrentPage(1);
        setTransactionCurrentPage(1);
    }, [costType]);

    //handle expense filter by dates
    useEffect(() => {
        if ((currentFilterExpenses?.value >= 1) && (currentFilterExpenses?.value <= 7)) {
            if (currentFilterExpenses?.value === 1) {
                setFilteDateFrom(new Date());
                setFilteDateTo(new Date());
            }
            setExpensesCurrentPage(1);
            if (costType === 1) {
                setChangeExpenses(changeExpenses + 1);
            } else {
                setChangeWallet(changeWallet + 1);
            }
        }
        if (currentFilterExpenses?.value === 8) {
            setCustomDatesPopup(true);
        }
    }, [currentFilterExpenses]);// eslint-disable-line react-hooks/exhaustive-deps

    //set states when editing
    function editClick(e, single) {
        e.preventDefault();
        e.stopPropagation();
        setAddTransaction(true);
        setCurrenEditTransaction(single);
        setTransactionType(single?.transaction_type_id ? allTransactionTypes()[getIndex(single?.transaction_type_id, allTransactionTypes(), 'value')] : '');
        setTransactionAmount(single?.amount);
        setTransactionCurrency(single?.currency_id ? allCurrencies()[getIndex(single?.currency_id, allCurrencies(), 'value')] : '');
        setAddTransactionDate(single?.date ? new Date(single.date) : new Date());
        setAddTransactionNote(single?.note ? single?.note : '');
        setTransactionTo(single?.receiver_id ? allUsers()[getIndex(single?.receiver_id, allCurrencies(), 'value')] : '');
    }

    //approve transacion actions
    const approveTransactionClick = (e, single) => {
        e.preventDefault();
        setApproveTransaction(true);
        setCurrentStatusTransaction(single);
    }

    //reject transaction actions
    const rejectTransactionClick = (e, single) => {
        e.preventDefault();
        setRejectTransaction(true);
        setCurrentStatusTransaction(single);
        setTransactionRejectReason('');
    }

    //cancel status transaction actions
    const cancelStatusTransaction = (e) => {
        setRejectTransaction(false);
        setApproveTransaction(false);
        setCurrentStatusTransaction('');
        setTransactionRejectReason('');
        setErrorsReturned({});
    }

    //submit approve transaction
    const submitApproveTransaction = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'approve-user-transaction',
            method: 'post',
            data: {
                finance_page: 1,
                transaction_id: currentStatusTransaction?.id,
                currency: transactionCurrency,
                user_id: authUser?.id,
                page: transactionCurrentPage,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                search_value: searchValue,
            },
            callback: res => {
                setWallet(res.data.wallet);
                setAuthUser(res.data.auth_user);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
                cancelStatusTransaction(e);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    //handle transaciton type when user not admin
    useEffect(() => {
        if (addTransaction) {
            if (authUser?.user_role_id !== 1) {
                setTransactionType(allTransactionTypes()[3]);
            }
        }
    }, [addTransaction]);// eslint-disable-line react-hooks/exhaustive-deps

    //submit reject transaction
    const submitRejectTransaction = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'reject-user-transaction',
            method: 'post',
            data: {
                finance_page: 1,
                transaction_id: currentStatusTransaction?.id,
                rejection_reason: transactionRejectReason,
                currency: transactionCurrency,
                user_id: authUser?.id,
                page: transactionCurrentPage,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                search_value: searchValue,
            },
            callback: res => {
                setWallet(res.data.wallet);
                setAuthUser(res.data.auth_user);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
                cancelStatusTransaction(e);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    //handle both delete transactions and expenses
    const deleteAction = (e) => {
        e.preventDefault();
        if (deleteType === 'transaction') {
            deleteTransaction(e, deletePopup);
        }
        if (deleteType === 'expense') {
            deleteExpense(e, deletePopup);
        }
    }

    //delete transactions submit
    const deleteTransaction = (e, single) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'delete-user-transaction',
            method: 'post',
            data: {
                finance_page: 1,
                transaction_id: single?.id,
                currency: transactionCurrency,
                user_id: authUser?.id,
                page: transactionCurrentPage,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                search_value: searchValue,
            },
            callback: res => {
                setWallet(res.data.wallet);
                setAuthUser(res.data.auth_user);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
                setDeletePopup(null);
                setDeleteType(null);
            },
            catch: err => {
            }
        })
    }

    //delete expense call
    const deleteExpense = (e, single) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'delete-user-expense',
            method: 'post',
            data: {
                finance_page: 1,
                page: expensesCurrentPage ? expensesCurrentPage : 1,
                expense_id: single?.id,
                user_id: Number(authUser?.id),
                currency: costCurrency,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                search_value: searchValue,
            },
            callback: res => {
                setExpenses(res.data.expenses);
                setAuthUser(res.data.auth_user);
                setErrorsReturned({});
                closeAddExpense(e);
                setDeletePopup(null);
                setDeleteType(null);
            },
            catch: err => {
            }
        })
    }

    //set states when edit single expense
    const editExpenses = (e, single) => {
        e.preventDefault();
        e.stopPropagation();
        setAddExpenses(true);

        setCurrenEditExpense(single);
        setAddExpensesType(single?.expense_type_id ? allExpensesTypes()[getIndex(single?.expense_type_id, allExpensesTypes(), 'value')] : '');
        setAddExpensesAmount(single?.amount);
        setAddExpensesCurrency(single?.currency_id ? allCurrencies()[getIndex(single?.currency_id, allCurrencies(), 'value')] : '');
        setAddExpensesDate(single?.date ? new Date(single.date) : new Date());
        setAddExpensesNote(single?.note ? single?.note : '');
        setAddExpensesBillable(single?.billable === 1 ? 1 : 0);
        setDocumentLink(single?.file ? single?.full_path_file : '');
        if (single?.client_id) {
            if (single?.client?.client_type_id === 1) {
                let text = 'client-' + single?.client_id;
                setCustomRelationFilter('company');
                setRelationName(companyRelations()[getIndex(text, companyRelations(), 'value')]);
            }
            if (single?.client?.client_type_id === 2) {
                let text = 'client-' + single?.client_id;
                setCustomRelationFilter('individual');
                setRelationName(individualRelations()[getIndex(text, individualRelations(), 'value')]);
            }
            if (single?.client?.client_type_id === 3) {
                let text = 'client-' + single?.client_id;
                setCustomRelationFilter('ngo');
                setTimeout(() => {
                    setRelationName(ngoRelations()[getIndex(text, ngoRelations(), 'value')]);
                }, 300);
            }
        }
    }

    //reset relation name
    useEffect(() => {
        setRelationName('');
    }, [customRelationFilter]);

    //reset states related to expenses when closed or canceled
    function closeAddExpense(e) {
        e.preventDefault();
        setAddExpenses(false);
        setCurrenEditExpense('');
        setAddExpensesType('');
        setAddExpensesAmount('');
        setAddExpensesCurrency('');
        setAddExpensesDate(new Date());
        setEditExpense(null);
        setAddExpensesNote('');
        setAddExpensesBillable('');
        setDocument();
        setDocumentLink('');
        setErrorsReturned({});
        setRelationName('');
        setCustomRelationFilter('individual');
    }

    //reset states related to transactions when closed or canceled
    function closeAddTransaction(e) {
        e.preventDefault();
        setAddTransaction(false);
        setTransactionType('');
        setTransactionAmount('');
        setTransactionCurrency('');
        setAddTransactionDate(new Date());
        setCurrenEditTransaction(null);
        setAddTransactionNote('');
        setOpenEditTransaction(null);
        setErrorsReturned({});
    }

    //get all transaction types
    const allTransactionTypes = () => {
        const transactionType = generalData?.transaction_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return transactionType;
    }

    //get all expeneses types
    const allExpensesTypes = () => {
        const expensesTypes = generalData?.expense_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expensesTypes;
    }

    //get all currencies
    const allCurrencies = () => {
        const curren = generalData?.currencies?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title, slug: singleOption.slug }))
        return curren;
    }

    // get all users
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: <div><img className="select-image me-1" src={singleOption?.picture ? singleOption?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt={singleOption?.name} />{singleOption?.name}</div> }))
        return curren;
    }

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    //get expenses data
    useEffect(() => {
        if (costType && costType === 1) {
            setExpensesCall({
                url: 'get-user-expenses?page=' + expensesCurrentPage,
                method: 'post',
                data: {
                    finance_page: 1,
                    user_id: Number(authUser?.id),
                    currency: costCurrency,
                    date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                    date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                    filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                    search_value: searchValue,
                },
                callback: res => {
                    setExpenses(res.data.expenses);
                    setAuthUser(res.data.auth_user);
                },
            });
        }
    }, [costCurrency, costType, generalData, profileUser, expensesCurrentPage, changeExpenses]);// eslint-disable-line react-hooks/exhaustive-deps

    //expenses pagination
    useEffect(() => {
        if (expenses?.data?.links?.length > 0) {
            let links = [];
            expenses?.data?.links?.forEach((element, index) => {
                links.push(index);
            });
            setExpensesLinks(links);
            setExpensesCurrentPage(expenses?.data?.current_page);
            setExpensesLastPage(expenses?.data?.last_page);
        }
    }, [expenses]);

    //wallet pagination
    useEffect(() => {
        if (wallet?.data?.links?.length > 0) {
            let links = [];
            wallet?.data?.links?.forEach((element, index) => {
                links.push(index);
            });
            setTransactionLinks(links);
            setTransactionCurrentPage(wallet?.data?.current_page);
            setTransactionLastPage(wallet?.data?.last_page);
        }
    }, [wallet]);

    //get wallet data
    useEffect(() => {
        if (costType === 2) {
            setExpensesCall({
                url: 'get-user-wallet?page=' + transactionCurrentPage,
                method: 'post',
                data: {
                    finance_page: 1,
                    currency: costCurrency,
                    user_id: authUser?.id,
                    date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                    date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                    filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                    search_value: searchValue,
                },
                callback: res => {
                    setWallet(res.data.wallet);
                    setAuthUser(res.data.auth_user);
                },
                catch: err => {
                }
            });
        }
    }, [costCurrency, costType, transactionCurrentPage, changeWallet]);// eslint-disable-line react-hooks/exhaustive-deps

    //submit expenses
    const submitExpenses = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (currenEditExpense?.id) {
            fd.append('expense_id', currenEditExpense?.id);
        }

        fd.append('expense_type_id', addExpensesType ? addExpensesType?.value : '');
        fd.append('currency_id', addExpensesCurrency ? addExpensesCurrency?.value : '');
        fd.append('currency', costCurrency ? costCurrency : '');
        fd.append('user_id', authUser?.id);
        fd.append('amount', addExpensesAmount ? addExpensesAmount : '');
        fd.append('date', addExpensesDate ? formatDate(addExpensesDate) : '');
        fd.append('note', addExpensesNote ? addExpensesNote : '');
        fd.append('billable', addExpensesBillable ? addExpensesBillable : 0);
        fd.append('date_from', filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()));
        fd.append('date_to', filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()));
        fd.append('filter_type', currentFilterExpenses ? currentFilterExpenses?.value : '');
        fd.append('page', expensesCurrentPage ? expensesCurrentPage : 1);
        fd.append('finance_page', 1);
        fd.append('search_value', searchValue ? searchValue : '');
        if (relationName) {
            fd.append('relation_name', relationName ? relationName?.value : '');
        }
        if (document) {
            fd.append('file', document[0]);
        }

        setGeneralDataApiOptions({
            url: 'add-user-expense',
            method: 'post',
            data: fd,
            callback: res => {
                setExpenses(res.data.expenses);
                setAuthUser(res.data.auth_user);
                setErrorsReturned({});
                closeAddExpense(e);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //submit transactions
    const submitTransactions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (currenEditTransaction?.id) {
            fd.append('expense_id', currenEditTransaction?.id);
        }

        fd.append('transaction_type_id', transactionType ? transactionType?.value : '');
        fd.append('currency_id', transactionCurrency ? transactionCurrency?.value : '');
        fd.append('receiver_id', transactionTo ? transactionTo?.value : '');
        fd.append('currency', costCurrency ? costCurrency : '');
        fd.append('user_id', authUser?.id);
        fd.append('amount', transactionAmount ? transactionAmount : '');
        fd.append('date', addTransactionDate ? formatDate(addTransactionDate) : '');
        fd.append('note', addTransactionNote ? addTransactionNote : '');
        fd.append('page', transactionCurrentPage);
        fd.append('date_from', filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()));
        fd.append('date_to', filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()));
        fd.append('filter_type', currentFilterExpenses ? currentFilterExpenses?.value : '');
        fd.append('finance_page', 1);
        fd.append('search_value', searchValue ? searchValue : '');


        setGeneralDataApiOptions({
            url: 'add-user-transaction',
            method: 'post',
            data: fd,
            callback: res => {
                setWallet(res.data.wallet);
                setAuthUser(res.data.auth_user);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //docuemnt handler
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0])
            // setTempFile(file);
            setDocumentLink(file);
        }
    }

    //custom select styles
    const customStylesUsersPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "2px",
                paddingTop: "2px",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };


    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // custom select styles
    const customStylesPopupCurr = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // custom select styles
    const customStylesDateFilter = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '500',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "4px",
                maxWidth: "100%",
                paddingLeft: "4px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "1px solid transparent",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '500',
            boxShadow: "none",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid white",
            marginTop: "-10px",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            opacity: "0.4",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '500',
            padding: "0",
            marginTop: "1px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle add transaction click
    const addTransactionsClick = (e) => {
        if (authUser?.id === Number(authUser?.id)) {
            setTransactionTo('');
        }
        setAddTransaction(true);
    }

    //handle expenses chart data
    useEffect(() => {
        if (expenses['expenses']) {
            let all_exp_data = [];
            generalData?.expense_types?.map((single) => (
                expenses['expenses'][single?.slug.replace('-', '')] > 0 &&
                all_exp_data.push(expenses['expenses'][single?.slug.replace('-', '')])
            ));
            setExpensesChartData(all_exp_data);
        }
    }, [slug, expenses, costCurrency]);// eslint-disable-line react-hooks/exhaustive-deps

    //handle wallet chart data
    useEffect(() => {
        if (wallet['wallet']) {
            let all_exp_data = [];
            generalData?.transaction_types?.map((single) => (
                wallet['wallet'][single?.slug.replace('-', '')] > 0 &&
                all_exp_data.push(wallet['wallet'][single?.slug.replace('-', '')])
            ));
            setWalletChartData(all_exp_data);
        }
    }, [slug, wallet, costCurrency]);// eslint-disable-line react-hooks/exhaustive-deps

    //set expenses chart
    useEffect(() => {
        setChartData({
            datasets: [
                {
                    data: expensesChartData,
                    borderRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: [
                        '#ED3E3E',
                        '#3EB0ED',
                        '#1562CB',
                        '#15CB5B',
                        '#077A5A',
                        'orange',
                        'gray',
                        'black',
                        'purple',
                    ],
                },
            ],
        });
    }, [expenses, expensesChartData]);

    //set wallet chart
    useEffect(() => {
        setWalletChart({
            datasets: [
                {
                    data: walletChartData,
                    borderRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: [
                        '#ED3E3E',
                        '#3EB0ED',
                        '#1562CB',
                        '#15CB5B',
                        '#077A5A',
                        'orange',
                        'gray',
                        'black',
                        'purple',
                    ],
                },
            ],
        });
    }, [wallet, walletChartData]);

    let x = 0;

    return (
        <>
            <Layout activePage="finances">
                {
                    authUser?.id ?
                        <>
                            {
                                wallet['total'] !== 'x' || expenses['total'] !== 'x' ?
                                    <>
                                        <div className="row g-0">
                                            <div className="col-12 col-md-12 col-lg-10 col-xl-8">
                                                <div className="row g-0 w-100">
                                                    <div className="col-12 col-md-auto pe-0 pe-md-5 finances-box bg-white seperating-line">
                                                        <div className="row justify-content-center">
                                                            <div className="col-auto">
                                                                <div className="background-svg-finances">
                                                                    <CalculatorIcon />
                                                                </div>
                                                            </div>
                                                            <div className="col ps-0 pe-0 d-flex flex-column my-auto">
                                                                <h1 className="folder-title m-0">{generalData?.fixed_titles['monthly-summary']}</h1>
                                                                <h4 className="number-files-title m-0">{authUser?.current_month}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-7 finances-box bg-white seperated-div">
                                                        <div className="row w-100 h-100 align-items-center justify-content-center">
                                                            <div className="col-6 justify-content-center align-items-center">
                                                                <div className="row d-flex justify-content-center align-items-cente">
                                                                    <div className="col-auto p-0 d-flex align-items-center">
                                                                        <h1 className="big-numbers-finances">{authUser?.transactions_count}</h1>
                                                                    </div>
                                                                    <div className="col-auto p-0 d-flex align-items-center">
                                                                        <TransactionsArrows className="ms-2" />
                                                                    </div>
                                                                    <div className="row d-flex justify-content-center alig-items-center">
                                                                        <div className="col-auto">
                                                                            <h2 className="big-titles-finances m-0">{generalData?.users_cost_settings['transactions']}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6 justify-content-center align-items-center">
                                                                <div className="row d-flex justify-content-center align-items-cente">
                                                                    <div className="col-auto p-0 d-flex align-items-center">
                                                                        <h1 className="big-numbers-finances">{authUser?.expenses_count}</h1>
                                                                    </div>
                                                                    <div className="col-auto p-0 d-flex align-items-center">
                                                                        <BlueCalculators className="ms-2" />
                                                                    </div>
                                                                </div>
                                                                <div className="row d-flex justify-content-center alig-items-center">
                                                                    <div className="col-auto">
                                                                        <h2 className="big-titles-finances m-0">{generalData?.users_cost_settings['expenses-title']}</h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {
                                                (costType === 2) ?
                                                    <>
                                                        {
                                                            wallet['total'] !== 'x' ?
                                                                <>
                                                                    <div className="col-12 pt-4 pb-3">
                                                                        <div className="row">
                                                                            <div className="col-12 col-lg-4 d-flex flex-column">
                                                                                <div className="d-flex flex-column h-100">
                                                                                    <div className="col-12 pb-3">
                                                                                        <div>
                                                                                            <h2 className="cost-big-titles pb-1">{generalData?.users_cost_settings['available-balance']}</h2>
                                                                                        </div>
                                                                                        <div className="costs-box-styling bg-white position-relative">
                                                                                            <div className="row justify-content-between my-2">
                                                                                                <div className="col my-auto">
                                                                                                    <p className="m-0 fw-700">{authUser?.available_balance ? authUser?.available_balance[showCurr] : 0}</p>
                                                                                                </div>
                                                                                                <div className="col-auto">
                                                                                                    <div className="currency-box d-flex flex-row px-2">
                                                                                                        {
                                                                                                            generalData?.currencies?.map((singleCurr, ind) => (
                                                                                                                <div onClick={() => setShowCurr(singleCurr?.slug)} key={ind} className={'currency-text d-flex ' + (singleCurr?.slug === showCurr ? 'active ' : ' ') + (Number(ind + 1) === Number(generalData?.currencies?.length) ? ' ' : 'me-1')}>{singleCurr?.title}</div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-12 d-flex flex-column flex-grow-1">
                                                                                        <div>
                                                                                            <h2 className="cost-big-titles pb-1">{generalData?.users_cost_settings['quick-overview']}</h2>
                                                                                        </div>
                                                                                        <div className="costs-box-styling bg-white position-relative height-100 d-flex flex-g-1 flex-column justify-content-between">
                                                                                            <div className="col-12">
                                                                                                <div className="row justify-content-between g-0 w-100">
                                                                                                    <div className="col-auto currency-box mb-2 d-flex flex-row px-2">
                                                                                                        {
                                                                                                            generalData?.currencies?.map((singleCurr, ind) => (
                                                                                                                <div onClick={() => setCostCurrency(singleCurr?.slug)} key={ind} className={'currency-text d-flex ' + (singleCurr?.slug === costCurrency ? 'active ' : ' ') + (Number(ind + 1) === Number(generalData?.currencies?.length) ? ' ' : 'me-1')}>{singleCurr?.title}</div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="col-auto filter-picker-expenses position-relative">
                                                                                                        <Select
                                                                                                            classNamePrefix="filters-date-select"
                                                                                                            className="filters-date-select w-100"
                                                                                                            styles={customStylesDateFilter}
                                                                                                            options={allExpenseFilters()}
                                                                                                            components={{
                                                                                                                IndicatorSeparator: () => null
                                                                                                            }}
                                                                                                            isSearchable={false}
                                                                                                            value={currentFilterExpenses?.value !== 8 ? currentFilterExpenses : (filterDateFrom && filterDateTo ? { value: 8, label: '' + formatDate(filterDateFrom) + ' / ' + formatDate(filterDateTo) + '' } : currentFilterExpenses)}
                                                                                                            onChange={setCurrentFilterExpenses}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="row">
                                                                                                <div className="col-12">
                                                                                                    <div className="chart-container mx-auto d-flex flex-g-1 justify-content-center postion-relative">
                                                                                                        {
                                                                                                            wallet?.total !== 'x' && walletChart && walletChartData && wallet?.total_all ?
                                                                                                                <>
                                                                                                                    <Doughnut
                                                                                                                        width={'100%'}
                                                                                                                        height={'100%'}
                                                                                                                        data={walletChart}
                                                                                                                        options={{
                                                                                                                            plugins: {
                                                                                                                                tooltip: {
                                                                                                                                    enabled: false
                                                                                                                                }
                                                                                                                            },
                                                                                                                            cutout: "85%",
                                                                                                                        }}
                                                                                                                        plugins={[{
                                                                                                                            afterUpdate: function (chart) {
                                                                                                                                const arcs = chart.getDatasetMeta(0).data;

                                                                                                                                arcs.forEach(function (arc) {
                                                                                                                                    arc.round = {
                                                                                                                                        x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                                                                                                                        y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                                                                                                                        radius: (arc.outerRadius + arc.innerRadius) / 2,
                                                                                                                                        thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                                                                                                                        backgroundColor: arc.options.backgroundColor
                                                                                                                                    }
                                                                                                                                });
                                                                                                                            },
                                                                                                                            afterDraw: (chart) => {
                                                                                                                                const {
                                                                                                                                    ctx,
                                                                                                                                } = chart;
                                                                                                                                chart.getDatasetMeta(0).data.forEach(arc => {
                                                                                                                                    const endAngle = Math.PI / 2 - arc.endAngle;

                                                                                                                                    ctx.save();
                                                                                                                                    ctx.translate(arc.round.x, arc.round.y);
                                                                                                                                    ctx.fillStyle = arc.options.backgroundColor;
                                                                                                                                    ctx.beginPath();
                                                                                                                                    ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                                                                                                                                    ctx.closePath();
                                                                                                                                    ctx.fill();
                                                                                                                                    ctx.restore();
                                                                                                                                });
                                                                                                                            }
                                                                                                                        }]}
                                                                                                                    />
                                                                                                                    <div className="title-middle-chart">
                                                                                                                        {wallet?.total_all} {costCurrency}
                                                                                                                        <span>Total</span>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                <Doughnut
                                                                                                                    width={'100%'}
                                                                                                                    height={'100%'}
                                                                                                                    data={{
                                                                                                                        datasets: [
                                                                                                                            {
                                                                                                                                data: [100],
                                                                                                                                backgroundColor: [
                                                                                                                                    '#91a8c1',
                                                                                                                                ],
                                                                                                                                borderColor: [
                                                                                                                                    '#91a8c1',
                                                                                                                                ],
                                                                                                                            },
                                                                                                                        ],
                                                                                                                    }}
                                                                                                                    options={{
                                                                                                                        cutout: "85%",
                                                                                                                    }}
                                                                                                                    plugins={[{
                                                                                                                        afterUpdate: function (chart) {
                                                                                                                            const arcs = chart.getDatasetMeta(0).data;

                                                                                                                            arcs.forEach(function (arc) {
                                                                                                                                arc.round = {
                                                                                                                                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                                                                                                                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                                                                                                                    radius: (arc.outerRadius + arc.innerRadius) / 2,
                                                                                                                                    thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                                                                                                                    backgroundColor: arc.options.backgroundColor
                                                                                                                                }
                                                                                                                            });
                                                                                                                        },
                                                                                                                        afterDraw: (chart) => {
                                                                                                                            const {
                                                                                                                                ctx,
                                                                                                                            } = chart;
                                                                                                                            chart.getDatasetMeta(0).data.forEach(arc => {
                                                                                                                                const endAngle = Math.PI / 2 - arc.endAngle;

                                                                                                                                ctx.save();
                                                                                                                                ctx.translate(arc.round.x, arc.round.y);
                                                                                                                                ctx.fillStyle = arc.options.backgroundColor;
                                                                                                                                ctx.beginPath();
                                                                                                                                ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                                                                                                                                ctx.closePath();
                                                                                                                                ctx.fill();
                                                                                                                                ctx.restore();
                                                                                                                            });
                                                                                                                        }
                                                                                                                    }]}
                                                                                                                />
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                            <div className="chart-legend pt-4">
                                                                                                {
                                                                                                    (wallet && wallet['wallet'] && wallet['total_all'] !== 0) ?
                                                                                                        generalData?.transaction_types?.map((singleType, indexx) => {
                                                                                                            if (indexx === 0) {
                                                                                                                x = -1;
                                                                                                            }
                                                                                                            if ((wallet['wallet'][(singleType?.slug?.replace('-', ''))] > 0)) {
                                                                                                                x = x + 1;
                                                                                                            }
                                                                                                            return (
                                                                                                                wallet['wallet'][(singleType?.slug?.replace('-', ''))] > 0 &&
                                                                                                                <div key={indexx} className={'d-flex justify-content-between chart-legend-expenses i' + (x)}>
                                                                                                                    {singleType.title}( {Math.round(((Number(wallet['wallet'][(singleType?.slug?.replace('-', ''))]) * 100) / wallet['total_all']), 2)}%) <span className="d-flex">{wallet['wallet'][singleType?.slug.replace("-", "")]}</span></div>
                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-lg-8 pt-2 pt-lg-0">
                                                                                <div className="row pt-3 pt-lg-0">
                                                                                    <div className="col-auto pe-0">
                                                                                        <h4 onClick={() => costType === 1 ? history('/finances/transactions') : null} className={'finance-menu-titles pb-2 ' + (costType === 2 ? ' active' : '')}>{generalData?.users_cost_settings['transactions']}</h4>
                                                                                        {
                                                                                            costType === 2 ?
                                                                                                <div className="buttom-light-effect"></div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-auto">
                                                                                        <h4 onClick={() => costType === 2 ? history('/finances/expenses') : null} className={'finance-menu-titles pb-2 ' + (costType === 1 ? ' active' : '')}>{generalData?.users_cost_settings['expenses-title']}</h4>
                                                                                        {
                                                                                            costType === 1 ?
                                                                                                <div className="buttom-light-effect"></div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <div className="row">
                                                                                            <div className="col-12">
                                                                                                <div className="costs-box-styling bg-white">
                                                                                                    <div className="col-12">
                                                                                                        <h2 className="cost-big-titles m-0">{generalData?.users_cost_settings['transactions']} <img className="cursor-pointer" onClick={(e) => addTransactionsClick(e)} src={'/assets/images/plus.svg'} alt="add" /></h2>
                                                                                                    </div>
                                                                                                    <div className="col-12 col-md-8 col-xl-4 pt-2 pb-3">
                                                                                                        <input onKeyDown={(e) => (e.key === "Enter" ? costType === 2 ? (setChangeWallet(changeWallet + 1), setTransactionCurrentPage(1)) : (setChangeExpenses(changeExpenses + 1), setExpensesCurrentPage(1)) : null)} name="search" className="table-search add-user-inputs" placeholder={generalData?.fixed_titles['search']} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                                                                                    </div>
                                                                                                    <div className="col-12">
                                                                                                        <div className="table-wrapper-settings">
                                                                                                            <table className="users-table" width="100%">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <td></td>
                                                                                                                        <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['transaction-type']}</td>
                                                                                                                        <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['date']}</td>
                                                                                                                        <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['amount']}</td>
                                                                                                                        <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['related-matter']}</td>
                                                                                                                        <td className="table-sorting" style={{ minWidth: "200px" }}>{generalData?.users_cost_settings['related-to']}</td>
                                                                                                                        <td className="table-sorting" style={{ minWidth: "220px" }}>{generalData?.users_cost_settings['status']}</td>
                                                                                                                        <td className="table-sorting" style={{ minWidth: "400px", width: "400px", maxWidth: "400px" }}>{generalData?.users_cost_settings['note']}</td>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {
                                                                                                                        wallet?.data?.data?.length > 0 &&
                                                                                                                        wallet?.data?.data?.map((singleTransaction, indexxx) => (
                                                                                                                            <tr key={indexxx} >
                                                                                                                                <td className="text-start p-0 pe-2 position-relative" >
                                                                                                                                    {
                                                                                                                                        authUser?.id === Number(singleTransaction?.user_id) ?
                                                                                                                                            <>
                                                                                                                                                <EditIcon className="m-2" onClick={(e) => { e.stopPropagation(); openEditTransaction?.id === singleTransaction?.id ? setOpenEditTransaction(null) : setOpenEditTransaction(singleTransaction) }} />
                                                                                                                                                <div style={indexxx + 1 === wallet?.data?.data?.length ? { top: "-15px" } : {}} className={"settings-clickmenu first-td-buttons text-start " + (singleTransaction?.id === openEditTransaction?.id ? ' d-block' : '')}>
                                                                                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editClick(e, singleTransaction) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                                                                    <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleTransaction); setDeleteType('transaction') }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                                                                                </div>
                                                                                                                                            </>
                                                                                                                                            :
                                                                                                                                            null
                                                                                                                                    }

                                                                                                                                </td>
                                                                                                                                <td>{singleTransaction?.transaction_type?.title}</td>
                                                                                                                                <td>{singleTransaction?.date}</td>
                                                                                                                                <td>{singleTransaction?.amount}</td>
                                                                                                                                <td>{singleTransaction?.litigation_id ? singleTransaction?.litigation?.title :''}</td>
                                                                                                                                <td>
                                                                                                                                    {
                                                                                                                                        Number(singleTransaction?.user_id) === Number(authUser?.id) ?
                                                                                                                                            <div style={{ display: "-webkit-box" }}>
                                                                                                                                                <div className="position-relative user-image-in-table">
                                                                                                                                                    <img className="table-user-image" src={
                                                                                                                                                        (singleTransaction?.litigation_id || singleTransaction?.client_id) ?
                                                                                                                                                            singleTransaction?.litigation?.clients[0]?.client?.client_type_id === 1 ?

                                                                                                                                                                generalData?.fixed_settings?.full_path_no_company_picture
                                                                                                                                                                :
                                                                                                                                                                singleTransaction?.litigation?.clients[0]?.client?.client_type_id === 2 ?

                                                                                                                                                                    generalData?.fixed_settings?.full_path_no_individual_picture
                                                                                                                                                                    :
                                                                                                                                                                    generalData?.fixed_settings?.full_path_no_ngo_picture

                                                                                                                                                            :
                                                                                                                                                            singleTransaction?.user?.picture ? singleTransaction?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture
                                                                                                                                                    }
                                                                                                                                                    alt="users" />
                                                                                                                                                    <div className="triangle-down"></div>
                                                                                                                                                    <p className="hover-username">
                                                                                                                                                        {
                                                                                                                                                            singleTransaction?.litigation_id ?
                                                                                                                                                                singleTransaction?.litigation?.clients[0]?.client?.name
                                                                                                                                                                :

                                                                                                                                                                singleTransaction?.user_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.user?.name
                                                                                                                                                        }
                                                                                                                                                    </p>
                                                                                                                                                </div>
                                                                                                                                                <TableArrow1 className="ms-2 me-2 flip-ar" />
                                                                                                                                                <div className="position-relative user-image-in-table">
                                                                                                                                                    <img className="table-user-image" src={singleTransaction?.receiver?.picture ? singleTransaction?.receiver?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                                                    <div className="triangle-down"></div>
                                                                                                                                                    <p className="hover-username">{singleTransaction?.receiver_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.receiver?.name}</p>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <div style={{ display: "-webkit-box" }}>
                                                                                                                                                <div className="position-relative user-image-in-table">
                                                                                                                                                    <img className="table-user-image" src={singleTransaction?.receiver?.picture ? singleTransaction?.receiver?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                                                    <div className="triangle-down"></div>
                                                                                                                                                    <p className="hover-username">{singleTransaction?.receiver_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.receiver?.name}</p>
                                                                                                                                                </div>
                                                                                                                                                <TableArrow2 className="ms-2 me-2 flip-ar" />
                                                                                                                                                <div className="position-relative user-image-in-table">
                                                                                                                                                    <img className="table-user-image" src={singleTransaction?.user?.picture ? singleTransaction?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                                                    <div className="triangle-down"></div>
                                                                                                                                                    <p className="hover-username">{singleTransaction?.user_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.user?.name}</p>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                    }
                                                                                                                                </td>
                                                                                                                                <td>
                                                                                                                                    {
                                                                                                                                        authUser?.id === singleTransaction?.receiver_id && singleTransaction?.transaction_stat_id === 3 ?
                                                                                                                                            <div className="d-flex">
                                                                                                                                                <div onClick={(e) => approveTransactionClick(e, singleTransaction)} className="buttons-in-table popup-button me-2">
                                                                                                                                                    {generalData?.users_cost_settings['approve']}
                                                                                                                                                </div>
                                                                                                                                                <div onClick={(e) => rejectTransactionClick(e, singleTransaction)} className="buttons-in-table popup-button">
                                                                                                                                                    {generalData?.users_cost_settings['reject']}
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            :
                                                                                                                                            <>
                                                                                                                                                <div style={{ width: "fit-content" }} className="position-relative user-image-in-table">
                                                                                                                                                    <span style={singleTransaction?.transaction_stat_id === 1 ? { color: "#30D309" } : (singleTransaction?.transaction_stat_id === 2 ? { color: "#D30915" } : { color: "#FDC32D" })} >{singleTransaction?.transaction_stat?.title}</span>
                                                                                                                                                    {
                                                                                                                                                        singleTransaction?.rejection_reason ?
                                                                                                                                                            <>
                                                                                                                                                                <div className="triangle-down"></div>
                                                                                                                                                                <p className="hover-username">{singleTransaction?.rejection_reason}</p>
                                                                                                                                                            </>
                                                                                                                                                            :
                                                                                                                                                            null
                                                                                                                                                    }
                                                                                                                                                </div>
                                                                                                                                            </>
                                                                                                                                    }
                                                                                                                                </td>
                                                                                                                                <td>{singleTransaction?.note}</td>
                                                                                                                            </tr>
                                                                                                                        ))

                                                                                                                    }
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                            {
                                                                                                                wallet?.data?.data?.length === 0 &&
                                                                                                                <>
                                                                                                                    <div className="w-100 text-center mt-4">
                                                                                                                        {generalData?.fixed_titles['you-didnt-add-any-company-yet'].replace('%record%', generalData?.users_cost_settings['transactions'])}
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {
                                                                                                        wallet?.data?.data?.length > 0 &&
                                                                                                        <div className="col-12 justify-content-center align-items-center py-2">
                                                                                                            <Link to={'/finances/transactions/all'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                                                                                        </div>
                                                                                                    }

                                                                                                    {/* <div className="col-12 text-center py-2 d-flex justify-content-center">
                                                                                                        <Pagination
                                                                                                            className="pagination-bar"
                                                                                                            currentPage={transactionCurrentPage}
                                                                                                            totalCount={12 * transactionLastPage}
                                                                                                            pageSize={12}
                                                                                                            onPageChange={(e) => e <= transactionLastPage && e > 0 ? setTransactionCurrentPage(e) : null}
                                                                                                        />
                                                                                                    </div> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                    :
                                                    <>

                                                        {
                                                            costType === 1 ?
                                                                <div className="col-12 pt-4 pb-3">
                                                                    <div className="row">
                                                                        <div className="col-12 col-lg-4 d-flex flex-column">
                                                                            <div className="d-flex flex-column h-100">
                                                                                <div className="col-12 pb-3">
                                                                                    <div>
                                                                                        <h2 className="cost-big-titles pb-1">{generalData?.users_cost_settings['available-balance']}</h2>
                                                                                    </div>
                                                                                    <div className="costs-box-styling bg-white position-relative">
                                                                                        <div className="row justify-content-between my-2">
                                                                                            <div className="col my-auto">
                                                                                                <p className="m-0 fw-700">{authUser?.available_balance ? authUser?.available_balance[showCurr] : 0}</p>
                                                                                            </div>
                                                                                            <div className="col-auto">
                                                                                                <div className="currency-box d-flex flex-row px-2">
                                                                                                    {
                                                                                                        generalData?.currencies?.map((singleCurr, ind) => (
                                                                                                            <div onClick={() => setShowCurr(singleCurr?.slug)} key={ind} className={'currency-text d-flex ' + (singleCurr?.slug === showCurr ? 'active ' : ' ') + (Number(ind + 1) === Number(generalData?.currencies?.length) ? ' ' : 'me-1')}>{singleCurr?.title}</div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 d-flex flex-column flex-grow-1">
                                                                                    <div>
                                                                                        <h2 className="cost-big-titles pb-1">{generalData?.users_cost_settings['quick-overview']}</h2>
                                                                                    </div>
                                                                                    <div className="costs-box-styling bg-white position-relative height-100 d-flex flex-column flex-g-1 justify-content-between">
                                                                                        <div className="col-12">
                                                                                            <div className="row justify-content-between g-0 w-100">
                                                                                                <div className="col-auto currency-box mb-2 d-flex flex-row px-2">
                                                                                                    {
                                                                                                        generalData?.currencies?.map((singleCurr, ind) => (
                                                                                                            <div onClick={() => setCostCurrency(singleCurr?.slug)} key={ind} className={'currency-text d-flex ' + (singleCurr?.slug === costCurrency ? 'active ' : ' ') + (Number(ind + 1) === Number(generalData?.currencies?.length) ? ' ' : 'me-1')}>{singleCurr?.title}</div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="col-auto filter-picker-expenses position-relative">
                                                                                                    <Select
                                                                                                        classNamePrefix="filters-date-select"
                                                                                                        className="filters-date-select w-100"
                                                                                                        styles={customStylesDateFilter}
                                                                                                        options={allExpenseFilters()}
                                                                                                        components={{
                                                                                                            IndicatorSeparator: () => null
                                                                                                        }}
                                                                                                        isSearchable={false}
                                                                                                        value={currentFilterExpenses?.value !== 8 ? currentFilterExpenses : (filterDateFrom && filterDateTo ? { value: 8, label: '' + formatDate(filterDateFrom) + ' / ' + formatDate(filterDateTo) + '' } : currentFilterExpenses)}
                                                                                                        onChange={setCurrentFilterExpenses}
                                                                                                    />

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="chart-container mx-auto d-flex flex-g-1 justify-content-center postion-relative">
                                                                                                {
                                                                                                    expenses?.total > 0 && chartData && expensesChartData ?
                                                                                                        <>
                                                                                                            <Doughnut
                                                                                                                width={'100%'}
                                                                                                                height={'100%'}
                                                                                                                data={chartData}
                                                                                                                options={{
                                                                                                                    plugins: {
                                                                                                                        tooltip: {
                                                                                                                            enabled: false
                                                                                                                        }
                                                                                                                    },
                                                                                                                    cutout: "85%",
                                                                                                                }}
                                                                                                                plugins={[{
                                                                                                                    afterUpdate: function (chart) {
                                                                                                                        const arcs = chart.getDatasetMeta(0).data;

                                                                                                                        arcs.forEach(function (arc) {
                                                                                                                            arc.round = {
                                                                                                                                x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                                                                                                                y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                                                                                                                radius: (arc.outerRadius + arc.innerRadius) / 2,
                                                                                                                                thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                                                                                                                backgroundColor: arc.options.backgroundColor
                                                                                                                            }
                                                                                                                        });
                                                                                                                    },
                                                                                                                    afterDraw: (chart) => {
                                                                                                                        const {
                                                                                                                            ctx,
                                                                                                                        } = chart;
                                                                                                                        chart.getDatasetMeta(0).data.forEach(arc => {
                                                                                                                            const endAngle = Math.PI / 2 - arc.endAngle;

                                                                                                                            ctx.save();
                                                                                                                            ctx.translate(arc.round.x, arc.round.y);
                                                                                                                            ctx.fillStyle = arc.options.backgroundColor;
                                                                                                                            ctx.beginPath();
                                                                                                                            ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                                                                                                                            ctx.closePath();
                                                                                                                            ctx.fill();
                                                                                                                            ctx.restore();
                                                                                                                        });
                                                                                                                    }
                                                                                                                }]}
                                                                                                            />
                                                                                                            <div className="title-middle-chart">
                                                                                                                {expenses?.total} {costCurrency}
                                                                                                                <span>Total</span>
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <Doughnut
                                                                                                            width={'100%'}
                                                                                                            height={'100%'}
                                                                                                            data={{
                                                                                                                datasets: [
                                                                                                                    {
                                                                                                                        data: [100],
                                                                                                                        backgroundColor: [
                                                                                                                            '#91a8c1',
                                                                                                                        ],
                                                                                                                        borderColor: [
                                                                                                                            '#91a8c1',
                                                                                                                        ],
                                                                                                                    },
                                                                                                                ],
                                                                                                            }}
                                                                                                            options={{
                                                                                                                cutout: "85%",
                                                                                                            }}
                                                                                                            plugins={[{
                                                                                                                afterUpdate: function (chart) {
                                                                                                                    const arcs = chart.getDatasetMeta(0).data;

                                                                                                                    arcs.forEach(function (arc) {
                                                                                                                        arc.round = {
                                                                                                                            x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                                                                                                            y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                                                                                                            radius: (arc.outerRadius + arc.innerRadius) / 2,
                                                                                                                            thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                                                                                                            backgroundColor: arc.options.backgroundColor
                                                                                                                        }
                                                                                                                    });
                                                                                                                },
                                                                                                                afterDraw: (chart) => {
                                                                                                                    const {
                                                                                                                        ctx,
                                                                                                                    } = chart;
                                                                                                                    chart.getDatasetMeta(0).data.forEach(arc => {
                                                                                                                        const endAngle = Math.PI / 2 - arc.endAngle;

                                                                                                                        ctx.save();
                                                                                                                        ctx.translate(arc.round.x, arc.round.y);
                                                                                                                        ctx.fillStyle = arc.options.backgroundColor;
                                                                                                                        ctx.beginPath();
                                                                                                                        ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                                                                                                                        ctx.closePath();
                                                                                                                        ctx.fill();
                                                                                                                        ctx.restore();
                                                                                                                    });
                                                                                                                }
                                                                                                            }]}
                                                                                                        />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="chart-legend pt-4">
                                                                                            {
                                                                                                expenses && expenses['expenses'] ?
                                                                                                    generalData?.expense_types?.map((singleType, indexx) => {
                                                                                                        if (indexx === 0) {
                                                                                                            x = -1;
                                                                                                        }
                                                                                                        if ((expenses['expenses'][(singleType?.slug?.replace('-', ''))] > 0)) {
                                                                                                            x = x + 1;
                                                                                                        }
                                                                                                        return (
                                                                                                            expenses['expenses'][(singleType?.slug?.replace('-', ''))] > 0 &&
                                                                                                            <div key={indexx} className={'d-flex justify-content-between chart-legend-expenses i' + (x)}>
                                                                                                                {singleType.title}( {Math.round(((Number(expenses['expenses'][(singleType?.slug?.replace('-', ''))]) * 100) / expenses['total']), 2)}%) <span className="d-flex">{expenses['expenses'][singleType?.slug.replace("-", "")]}</span></div>
                                                                                                        )
                                                                                                    })
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-lg-8 pt-2 pt-lg-0">
                                                                            <div className="row pt-3 pt-lg-0">
                                                                                <div className="col-auto pe-0">
                                                                                    <h4 onClick={() => costType === 1 ? history('/finances/transactions') : null} className={'finance-menu-titles pb-2 ' + (costType === 2 ? ' active' : '')}>{generalData?.users_cost_settings['transactions']}</h4>
                                                                                    {
                                                                                        costType === 2 ?
                                                                                            <div className="buttom-light-effect"></div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <h4 onClick={() => costType === 2 ? history('/finances/expenses') : null} className={'finance-menu-titles pb-2 ' + (costType === 1 ? ' active' : '')}>{generalData?.users_cost_settings['expenses-title']}</h4>
                                                                                    {
                                                                                        costType === 1 ?
                                                                                            <div className="buttom-light-effect"></div>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className={'costs-box-styling bg-white'}>
                                                                                <div className="col-12">
                                                                                    <h2 className="cost-big-titles m-0">{generalData?.users_cost_settings['expenses-title']} <img style={{ width: "32px", height: "32px" }} onClick={() => setAddExpenses(true)} className="cursor-pointer" src="/assets/images/plus.svg" alt="add" /></h2>
                                                                                </div>
                                                                                <div className="col-12 col-md-8 col-xl-4 pt-2 pb-3">
                                                                                    <input onKeyDown={(e) => (e.key === "Enter" ? costType === 2 ? (setChangeWallet(changeWallet + 1), setTransactionCurrentPage(1)) : (setChangeExpenses(changeExpenses + 1), setExpensesCurrentPage(1)) : null)} name="search" className="table-search add-user-inputs" placeholder={generalData?.fixed_titles['search']} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                                                                </div>
                                                                                <div className="col-12 pt-1">
                                                                                    <div className="table-wrapper-settings">
                                                                                        <table className="users-table" width="100%">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <td></td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['expenses-type']}</td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['related-to']}</td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['related-matter']}</td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['date']}</td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "200px" }}>{generalData?.users_cost_settings['amount']}</td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['added-by']}</td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['status']}</td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['document']}</td>
                                                                                                    <td className="table-sorting" style={{ minWidth: "400px", width: "400px", maxWidth: "400px" }}>{generalData?.users_cost_settings['note']}</td>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    expenses?.data?.data?.length > 0 ?
                                                                                                        expenses?.data?.data?.map((singleExpense, index) => (
                                                                                                            <tr key={index} >
                                                                                                                <td className="text-end p-0 pe-2 position-relative" >
                                                                                                                    {
                                                                                                                        authUser?.id === singleExpense?.user_id ?
                                                                                                                            <>
                                                                                                                                <EditIcon onClick={(e) => { e.stopPropagation(); editExpense?.id === singleExpense?.id ? setEditExpense(null) : setEditExpense(singleExpense) }} />
                                                                                                                                <div style={{}} className={"settings-clickmenu first-td-buttons text-start " + (singleExpense?.id === editExpense?.id ? ' d-block' : '')}>
                                                                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editExpenses(e, singleExpense) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                                                    <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); setDeletePopup(singleExpense); setDeleteType('expense') }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                            :
                                                                                                                            null
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td>{singleExpense?.expense_type?.title}</td>
                                                                                                                <td>{singleExpense?.client?.name}</td>
                                                                                                                <td>{singleExpense?.litigation?.title || '-'}</td>
                                                                                                                <td>{singleExpense?.date}</td>
                                                                                                                <td>{singleExpense?.amount}</td>
                                                                                                                <td><img className="table-user-image" src={singleExpense?.user?.picture ? singleExpense?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" /></td>
                                                                                                                <td>{singleExpense?.billable === 1 ? generalData?.users_cost_settings['billable'] : generalData?.users_cost_settings['not-billable']}</td>
                                                                                                                <td>{singleExpense?.file ? <a className="text-underlined" href={singleExpense?.full_path_file} target="_blank" rel="noreferrer">{generalData?.users_cost_settings['document-name']} {index + 1}</a> : null}</td>
                                                                                                                <td>{singleExpense?.note}</td>
                                                                                                            </tr>
                                                                                                        ))
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                        {
                                                                                            expenses?.data?.data?.length === 0 &&
                                                                                            <>
                                                                                                <div className="w-100 text-center mt-4">
                                                                                                    {generalData?.fixed_titles['you-didnt-add-any-company-yet'].replace('%record%', generalData?.users_cost_settings['expenses-title'])}
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    expenses?.data?.data?.length > 0 &&
                                                                                    <div className="col-12 justify-content-center align-items-center py-2">
                                                                                        <Link to={'/finances/expenses/all'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                                                                    </div>
                                                                                }

                                                                                {/* {
                                                                                    expensesLastPage > 1 ?
                                                                                        <div className="col-12 text-center py-2 d-flex justify-content-center">
                                                                                            <Pagination
                                                                                                className="pagination-bar"
                                                                                                currentPage={expensesCurrentPage}
                                                                                                totalCount={12 * expensesLastPage}
                                                                                                pageSize={12}
                                                                                                onPageChange={(e) => setExpensesCurrentPage(e)}
                                                                                            />
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                } */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </>
                        :
                        null
                }
                {
                    //expenses popup
                    addExpenses ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeAddExpense(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-title'] : generalData?.users_cost_settings['add-expenses-title']}</h1>
                                            </div>
                                            <form onSubmit={submitExpenses}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div style={{ zIndex: '4', }} className="position-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allExpensesTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['expenses-type']}
                                                                    isSearchable={false}
                                                                    value={addExpensesType ? addExpensesType : null}
                                                                    onChange={setAddExpensesType} />
                                                                <input
                                                                    type="text"
                                                                    className='position-x-center'
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0,
                                                                    }}
                                                                    value={addExpensesType || ''}
                                                                    required
                                                                />

                                                            </div>
                                                            {
                                                                errorsReturned?.expense_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.expense_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allCurrencies()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['currency']}
                                                                    isSearchable={false}
                                                                    value={addExpensesCurrency ? addExpensesCurrency : null}
                                                                    onChange={setAddExpensesCurrency} />
                                                                <input
                                                                    type="text"
                                                                    className='position-x-center'
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0,
                                                                    }}
                                                                    value={addExpensesCurrency || ''}
                                                                    required
                                                                />

                                                            </div>
                                                            {
                                                                errorsReturned?.currency_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input required name="amount" value={addExpensesAmount} onChange={(e) => setAddExpensesAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={'Date'}
                                                                    value={addExpensesDate ? addExpensesDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={addExpensesDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setAddExpensesDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("LmsLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <div ref={ref} onClick={(e) => setShowCustomSelectMenu(!showCustomSelectMenu)} className="select-drop-type">
                                                                    {
                                                                        customRelationFilter === 'individual' ?
                                                                            <>
                                                                                <IndividualIcon />
                                                                            </>
                                                                            :
                                                                            customRelationFilter === 'ngo' ?
                                                                                <NgoIcon />
                                                                                :
                                                                                <CompanyIcon />

                                                                    }
                                                                    <div className={'menu-select-type ' + (showCustomSelectMenu ? ' d-block' : '')}>
                                                                        {
                                                                            clientTypes().map((singleType, typeIndex) => (
                                                                                <p onClick={() => { setCustomRelationFilter(singleType?.slug); setShowCustomSelectMenu(false); }} key={typeIndex} className="ps-2">
                                                                                    {
                                                                                        singleType?.slug === 'individual' ?
                                                                                            <>
                                                                                                <IndividualIcon className="menu-select-type-svg" />
                                                                                            </>
                                                                                            :
                                                                                            singleType?.slug === 'ngo' ?
                                                                                                <NgoIcon className="menu-select-type-svg" />
                                                                                                :
                                                                                                <CompanyIcon className="menu-select-type-svg" />

                                                                                    }
                                                                                    {singleType?.label}
                                                                                </p>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <Select
                                                                    classNamePrefix="relations-select"
                                                                    className="w-100 relations-select"
                                                                    styles={customStylesPopupMulti}
                                                                    options={customRelationFilter === 'individual' ? individualRelations() : (customRelationFilter === 'ngo' ? ngoRelations() : (customRelationFilter === 'company' ? companyRelations() : allRelations()))}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.website_titles['relation-name']}
                                                                    isSearchable={true}
                                                                    value={relationName}
                                                                    onChange={setRelationName} />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 position-relative">
                                                            <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                            <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                            <AttachIconInput className="download-icon download-svg-popup" />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addExpensesNote} onChange={(e) => setAddExpensesNote(e.target.value)}></textarea>
                                                        </div>
                                                        <div className="col-12 pt-2 d-flex my-auto">
                                                            <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className={'my-auto billable-div ' + (addExpensesBillable ? 'active' : '')}>
                                                            </div>
                                                            <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className="ms-2 billable-popup">{generalData?.users_cost_settings['billable']}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-button'] : generalData?.users_cost_settings['add-expenses-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //transactions popup
                    addTransaction ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => closeAddTransaction(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-title'] : generalData?.users_cost_settings['add-transaction-title']}</h1>
                                            </div>
                                            <form onSubmit={submitTransactions}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={allTransactionTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['transaction-type']}
                                                                    isSearchable={false}
                                                                    isDisabled={authUser?.user_role_id !== 1 ? true : false}
                                                                    value={transactionType}
                                                                    onChange={setTransactionType} />
                                                                <input
                                                                    type="text"
                                                                    className='position-x-center'
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0,
                                                                    }}
                                                                    value={transactionType || ''}
                                                                    required
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.transaction_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.transaction_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <input required name="amount" value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                                <Select
                                                                    classNamePrefix="curr-select"
                                                                    className="curr-select w-100"
                                                                    styles={customStylesPopupCurr}
                                                                    options={allCurrencies()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['curr-transaction']}
                                                                    isSearchable={false}
                                                                    value={transactionCurrency ? transactionCurrency : null}
                                                                    onChange={setTransactionCurrency} />
                                                                <input
                                                                    type="text"
                                                                    className='position-absolute bottom-0 end-0'
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0,
                                                                    }}
                                                                    value={transactionCurrency || ''}
                                                                    required
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.currency_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.users_cost_settings['date']}
                                                                    value={addTransactionDate ? addTransactionDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={addTransactionDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setAddTransactionDate(date)}
                                                                    minDate={addYears(new Date(), -21)}
                                                                    maxDate={new Date()}
                                                                    locale={localStorage.getItem("LmsLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesUsersPopup}
                                                                    options={allUsers()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.users_cost_settings['transaction-to']}
                                                                    isSearchable={false}
                                                                    value={transactionTo ? transactionTo : null}
                                                                    onChange={setTransactionTo} />
                                                                <input
                                                                    type="text"
                                                                    className='position-x-center'
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0,
                                                                    }}
                                                                    value={transactionTo || ''}
                                                                    required
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.receiver_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.receiver_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addTransactionNote} onChange={(e) => setAddTransactionNote(e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-button'] : generalData?.users_cost_settings['add-transaction-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //custom dates popup
                    customDatesPopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => { setCurrentFilterExpenses(allExpenseFilters()[2]); setCustomDatesPopup(false); setFilteDateFrom(new Date()); setFilteDateTo(new Date()) }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">Custom Dates</h1>
                                            </div>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={'Date'}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={onChangeDate}
                                                                startDate={filterDateFrom}
                                                                endDate={filterDateTo}
                                                                selectsRange
                                                                minDate={addYears(new Date(), -100)}
                                                                maxDate={new Date()}
                                                                locale={localStorage.getItem("LmsLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="button" onClick={() => { setCustomDatesPopup(false); costType === 2 ? setChangeWallet(changeWallet + 1) : setChangeExpenses(changeExpenses + 1) }} className="popup-button my-auto">Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //reject transaction popup
                    rejectTransaction ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => cancelStatusTransaction(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.users_cost_settings['reject-popup-title']}</h1>
                                                <h4 style={{ fontSize: "14px", fontWeight: "500" }} className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.users_cost_settings['reject-popup-subtitle']}</h4>
                                            </div>
                                            <form onSubmit={submitRejectTransaction}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['reject-placeholder']} value={transactionRejectReason} onChange={(e) => setTransactionRejectReason(e.target.value)}></textarea>
                                                            {
                                                                errorsReturned?.rejection_reason ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.rejection_reason}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{generalData?.users_cost_settings['reject']}</button>
                                                    <button onClick={(e) => cancelStatusTransaction(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.users_cost_settings['cancel']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //delete transaction popup
                    deletePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => { setDeletePopup(null); setDeleteType(null); setOpenEditTransaction(null); setEditExpense(null); }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={(e) => deleteAction(e)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={() => { setDeletePopup(''); setDeleteType(''); setOpenEditTransaction(null); setEditExpense(null); }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //approve transaction popup
                    approveTransaction ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => cancelStatusTransaction(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.users_cost_settings['approve-popup-title']}</h1>
                                            </div>
                                            <form onSubmit={submitApproveTransaction}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row">
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{generalData?.users_cost_settings['approve']}</button>
                                                    <button onClick={(e) => cancelStatusTransaction(e)} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.users_cost_settings['cancel']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                <Api options={usersApiOptions} />
                <Api options={generalDataApiOptions} />
                <Api options={expensesCall} />
            </Layout>
        </>
    )
}