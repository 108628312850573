import 'react-calendar/dist/Calendar.css';
import React, { useContext, useEffect, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import Api from './Api';
import { AddIconCircle, AttachIcon, CloseIcon, FileIcon, MinusCircle } from './Icons';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import RelationsSelect from './RelationsSelect';
import Draggable from 'react-draggable';
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { addYears, getIndex, getOptions, formatDate } from '../Helpers';
registerLocale('ar', ar)

export default function ShareOperationManageComponent(props) {

    const { profileClientGlobal, generalDataGlobal, setOperations } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const [generalData,] = generalDataGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [numberOfShares, setNumberOfShares] = useState('');
    const [percentage, setPercentage] = useState('');
    const [formData, setFormData] = useState({});

    const { operation, setIsShareOperationPopupOpen } = props;
    const [selectedRelation, setSelectedRelation] = useState('');
    const [clientType,] = useState(operation?.shareholder?.client?.client_type_id);

    const [selectedShareOperation, setSelectedShareOperation] = useState()
    const [investmentAmount, setInvestmentAmount] = useState();
    const [selectedShareType, setSelectedShareType] = useState(getOptions(generalData.share_types, 'slug', 'id', 'title')[0]);
    const [transactionDate, setTransactionDate] = useState(operation ? operation?.transaction_date ? new Date(operation?.transaction_date) : '' : '');
    const [executionDate, setExecutionDate] = useState();
    const [registrationDate, setRegistrationDate] = useState(operation ? operation?.registration_date ? new Date(operation?.registration_date) : new Date() : new Date());
    const [shareParValue, setShareParValue] = useState();
    const [certificateNb, setCertificateNb] = useState();
    const [comment, setComment] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [selectedLoanType, setSelectedLoanType] = useState();
    const [reminderNotifyBeforeType, setReminderNotifyBeforeType] = useState(getOptions(generalData?.notify_before_types, 'slug', 'id', 'title')[getIndex('hour', getOptions(generalData?.notify_before_types, 'slug', 'id', 'title'), 'slug')]);
    const [reminderNotifyBefore, setReminderNotifyBefore] = useState();
    const [selectedTo, setSelectedTo] = useState();
    const [selectedShareholder, setSelectedShareholder] = useState();
    const [exchangeRate, setExchangeRate] = useState();
    const [fileName, setFileName] = useState('');

    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();

    const [openEditMenu,] = useState(null);

    const [avaibaleFields, setAvailableFields] = useState([])

    const [disabledFields,] = useState([]);
    const [confirmationPopup, setConfirmationPopup] = useState(false);
    const [selectedShareClass, setSelectedShareClass] = useState();

    const [operationDocument, setOperationDocument] = useState();
    const [operationDocumentLink, setOperationDocumentLink] = useState();
    const [operationFileName, setOperationFileName] = useState('');


    const getShareholdersOptions = () => {
        const shareholdersToMap = operation ? profileClient?.all_shareholders : profileClient?.shareholders;
        const shareholders = shareholdersToMap?.map(shareholder => {
            return ({ slug: `${shareholder?.client?.client_type?.title}-${shareholder?.client?.name}`, value: shareholder.id, label: shareholder.client.name })
        }
        )
        return shareholders;
    }

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            minWidth: '40px !important'
        })
    };

    //custom select styles
    const customStylesPopupCurr = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                // "&:hover": {
                //     backgroundColor: "#f2f9fc"
                // },
                margin: "auto",
                // margin: "0",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "80px",
            borderRadius: "7px",
            // border: "1px solid transparent",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            display: 'flex',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // margin: "0px",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    // validate operation request
    const validateOperationRequest = (e) => {

        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (operation) {
            fd.append('operation_id', operation?.id)
        }
        fd.append('validation', true);
        fd.append('share_operation_id', selectedShareOperation ? selectedShareOperation.value : operation?.share_operation_id ? operation?.share_operation_id : '')
        fd.append('relation_id', selectedRelation ? selectedRelation?.value : '');
        fd.append('number_of_shares', numberOfShares ? numberOfShares : '');
        fd.append('loan_type', selectedLoanType ? selectedLoanType.value : '');
        fd.append('amount', investmentAmount ? investmentAmount : '');
        fd.append('share_type_id', selectedShareType ? selectedShareType.value : getOptions(generalData.share_types, 'slug', 'id', 'title')[0]?.value);
        fd.append('share_class_id', selectedShareClass ? selectedShareClass.value : '');
        fd.append('from_id', selectedShareholder ? selectedShareholder.value : '');
        fd.append('to_id', selectedTo ? selectedTo.value : '');
        fd.append('transaction_date', transactionDate ? formatDate(transactionDate) : '');
        fd.append('registration_date', registrationDate ? formatDate(registrationDate) : '');
        fd.append('execution_date', executionDate ? executionDate : '');
        fd.append('notify_before', reminderNotifyBefore ? reminderNotifyBefore : '');
        fd.append('notify_timeframe_id', reminderNotifyBeforeType ? reminderNotifyBeforeType.value : '');
        fd.append('share_par_value', shareParValue ? shareParValue : '');
        fd.append('certificateNb', certificateNb ? certificateNb : '');
        fd.append('exchange_rate', exchangeRate ? exchangeRate : selectedCurrency?.value === profileClient?.currency?.id ? 1 : '');
        fd.append('currency', selectedCurrency ? selectedCurrency.value : '');
        fd.append('comment', comment ? comment : '');
        fd.append('user_id', profileClient?.id);
        fd.append('company_id', profileClient?.id);
        fd.append('ubos', JSON.stringify(formData.ubos));

        fd.append('force', 1);
        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 2);
        fd.append('folder_name', 'Shares Operations');
        fd.append('from_home', 1);
        fd.append('file_name', fileName ? fileName : '');

        if (document) {
            fd.append('file', document[0]);
        }

        if (operationDocument) {
            fd.append('operation_file_name', operationFileName);
            fd.append('operation_document', operationDocument[0]);
        }

        setGeneralDataApiOptions({
            url: 'add-edit-share-operation',
            method: 'post',
            data: fd,
            callback: res => {
                setConfirmationPopup(true);
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    //submit operation request
    const submitShareholdersOperation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (operation) {
            fd.append('operation_id', operation?.id)
        }
        fd.append('share_operation_id', selectedShareOperation ? selectedShareOperation.value : operation?.share_operation_id ? operation?.share_operation_id : '')
        fd.append('relation_id', selectedRelation ? selectedRelation?.value : '');
        fd.append('number_of_shares', numberOfShares ? numberOfShares : '');
        fd.append('loan_type', selectedLoanType ? selectedLoanType.value : '');
        fd.append('amount', investmentAmount ? investmentAmount : '');
        fd.append('share_type_id', selectedShareType ? selectedShareType.value : getOptions(generalData.share_types, 'slug', 'id', 'title')[0]?.value);
        fd.append('share_class_id', selectedShareClass ? selectedShareClass.value : '');
        fd.append('from_id', selectedShareholder ? selectedShareholder.value : '');
        fd.append('to_id', selectedTo ? selectedTo.value : '');
        fd.append('transaction_date', transactionDate ? formatDate(transactionDate) : '');
        fd.append('registration_date', registrationDate ? formatDate(registrationDate) : '');
        fd.append('execution_date', executionDate ? executionDate : '');
        fd.append('notify_before', reminderNotifyBefore ? reminderNotifyBefore : '');
        fd.append('notify_timeframe_id', reminderNotifyBeforeType ? reminderNotifyBeforeType.value : '');
        fd.append('share_par_value', shareParValue ? shareParValue : '');
        fd.append('certificateNb', certificateNb ? certificateNb : '');
        fd.append('exchange_rate', exchangeRate ? exchangeRate : selectedCurrency?.value === profileClient?.currency?.id ? 1 : '');
        fd.append('currency', selectedCurrency ? selectedCurrency.value : '');
        fd.append('comment', comment ? comment : '');
        fd.append('user_id', profileClient?.id);
        fd.append('company_id', profileClient?.id);
        fd.append('ubos', JSON.stringify(formData.ubos));

        fd.append('force', 1);
        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 2);
        fd.append('file_id', openEditMenu?.uploaded_files_id ? openEditMenu?.uploaded_files_id : '');
        fd.append('folder_name', 'Shares Operations');
        fd.append('from_home', 1);
        fd.append('file_name', fileName ? fileName : '');

        fd.append('date_from', props?.filters?.date_from ? formatDate(props?.filters?.date_from) : '');
        fd.append('date_to', props?.filters?.date_to ? formatDate(props?.filters?.date_to) : '');
        fd.append('shareholderFilter', !!props?.filters?.shareholderFilter ? props.filters.shareholderFilter.value : '');

        if (document) {
            fd.append('file', document[0]);
        }

        fd.append('operation_file_name', operationFileName);
        if (operationDocument) {
            fd.append('operation_document', operationDocument[0]);
        }

        setGeneralDataApiOptions({
            url: 'add-edit-share-operation',
            method: 'post',
            data: fd,
            callback: res => {
                setIsShareOperationPopupOpen(false);
                setProfileClient(res.data.client_user);
                setOperations(res.data.operations)
            },
            catch: err => {
                setErrorsReturned(err);
            }
        });
    }

    //get individual realtions
    const individualRelations = () => {
        const relOptions = profileClient?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = profileClient?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = profileClient?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //handle document upload
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0]);
            setDocumentLink(file);
        }
    }

    const operationDocumentHandler = (files) => {
        if (files.length > 0) {
            setOperationDocument(files);
            let file = URL.createObjectURL(files[0]);
            setOperationDocumentLink(file);
        }
    }

    const handleNoOfSharesChange = (e) => {
        setNumberOfShares(e.target.value)
        if (selectedShareOperation?.slug === 'initial-subscription' || Number(operation?.share_operation_id) === 6) {
            setInvestmentAmount(e.target.value * profileClient?.share_values)
        }
        setPercentage(e.target.value ? `${e.target.value * 100 / profileClient.total_issued_shares}` : '')
    }

    const handlePercentageChange = (e) => {
        setNumberOfShares(e.target.value * profileClient.capital / 100);
        setPercentage(e.target.value)
    }

    const shareOperationOptions = () => {
        const shareOperations = generalData?.shareOperations?.map(
            option => ({ slug: option?.slug, value: option.id, label: option.title }))
        return shareOperations;
    }

    const resetForm = () => {
        setInvestmentAmount('')
        setSelectedShareType('')
        setTransactionDate('')
        setExecutionDate('')
        setRegistrationDate(new Date())
        setShareParValue('')
        setCertificateNb('')
        setComment('')
        setSelectedCurrency('')
        setSelectedLoanType('')
        setSelectedTo('')
        setSelectedShareholder('')
        setExchangeRate('')
        setFileName('')
        setDocument('')
        setDocumentLink('')
        setOperationDocument('')
        setOperationDocumentLink('')
        setOperationFileName('')
        setErrorsReturned({});
        setNumberOfShares('')
        setPercentage('')
        let emptyObject = {};
        emptyObject = { email: '', shareholder_id: selectedRelation.id, shares: '' }
        let newFormData = { ...formData };
        newFormData['ubos'] = []
        newFormData['ubos'].push(emptyObject);
        setFormData(newFormData);
    }

    useEffect(() => {
        switch (selectedShareOperation?.slug) {
            case 'transfer-of-shares':
                setAvailableFields([
                    'no-of-shares',
                    'share-types',
                    'share-class',
                    'certificateNb',
                    'from',
                    'to',
                    'transaction-date',
                    'registration-date',
                    'ubos',
                    'comment',
                    'operation_file',
                ])
                break;
            case 'increase-in-capital':
                setAvailableFields([
                    'no-of-shares',
                    'amount',
                    'share-types',
                    'share-class',
                    'to',
                    'transaction-date',
                    'registration-date',
                    'certificateNb',
                    'currency',
                    'ubos',
                    'comment',
                    'operation_file',

                ])
                break;
            case 'equity-advance':
                setAvailableFields([
                    'no-of-shares',
                    'loan-type',
                    'share-types',
                    'share-class',
                    'to',
                    'transaction-date',
                    'registration-date',
                    'certificateNb',
                    'ubos',
                    'comment',
                    'operation_file',

                ])

                break;
            case 'transfer-of-loan-into-equity':
                setAvailableFields([
                    'no-of-shares',
                    'amount',
                    'share-types',
                    'share-class',
                    'to',
                    'transaction-date',
                    'registration-date',
                    'certificateNb',
                    'currency',
                    'ubos',
                    'comment',
                    'operation_file',

                ])

                break;
            case 'initial-subscription':
                setAvailableFields([
                    'no-of-shares',
                    'amount',
                    'registration-date',
                    'share-types',
                    'share-class',
                    'to',
                    'comment',
                    'percentage',
                    'operation_file',

                ])
                break;
            case 'add-shareholder':
                setAvailableFields([
                    'no-of-shares',
                    'amount',
                    'share-types',
                    'registration-date',
                    'share-class',
                    'to',
                    'comment',
                    'percentage',
                    'operation_file',

                ])
                break;
            default: setAvailableFields([]);
                break;
        }
    }, [selectedShareOperation]);// eslint-disable-line react-hooks/exhaustive-deps

    const initUbos = () => {
        let emptyObject = [];
        emptyObject['ubos'] = [
            { email: '', shares: '' }
        ];
        setFormData(emptyObject);
    }

    useEffect(() => {
        initUbos();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //add new empty field to formdata by key
    const addToFormData = (key) => {
        let emptyObject = {};
        if (key === 'ubos') {
            emptyObject = { email: '', shareholder_id: selectedRelation.id, shares: '' }
            let newFormData = { ...formData };
            newFormData[key].push(emptyObject);
            setFormData(newFormData);
        }
    }

    const handleUboChange = ({ target }, index) => {
        let newFormData = { ...formData }
        newFormData['ubos'][index].email = target.value;
        setFormData(newFormData);
    }

    const handleUboSharesChange = ({ target }, index) => {
        let newFormData = { ...formData }
        newFormData['ubos'][index].shares = target.value;
        setFormData(newFormData);
    }

    const handleCurrencyChange = (value) => {
        if (value.slug !== profileClient.currency.slug) {
            setAvailableFields(prev => ([...prev, 'exchange_rate']));
        } else {
            setAvailableFields(avaibaleFields.filter(field => field !== 'exchange_rate'));
            setExchangeRate('')
        }
        setSelectedCurrency(value);
    }

    const handleLoantypeChange = (e) => {
        setSelectedLoanType(e);
        if (e.slug === 'cash') {
            setAvailableFields([
                'no-of-shares',
                'loan-type',
                'share-types',
                'share-class',
                'to',
                'transaction-date',
                'registration-date',
                'certificateNb',
                'amount',
                'currency',
                'ubos',
                'operation_file',
                'comment',
            ])
        } else if (e.slug === 'asset') {
            setAvailableFields([
                'no-of-shares',
                'loan-type',
                'share-class',
                'share-types',
                'to',
                'transaction-date',
                'registration-date',
                'certificateNb',
                'amount',
                'currency',
                'file',
                'operation_file',
                'ubos',
                'comment',
            ])
        }
    }

    const handleOperationChange = (e) => {
        setSelectedShareOperation(e);
        resetForm()
    }

    const handleAmmountChange = (e) => {
        setInvestmentAmount(e.target.value)
        if (selectedShareOperation?.slug === 'initial-subscription' || Number(operation?.share_operation_id) === 6) {
            setNumberOfShares(e.target.value / profileClient?.share_values);
            setPercentage((e.target.value / profileClient?.share_values) * 100 / profileClient?.total_issued_shares)
        }
    }

    const handleRelationChange = (e) => {
        setSelectedTo(e);
        initUbos();
    }

    const removeFromFormData = (dataField, index, id = null) => {
        let newFd = { ...formData };
        newFd[dataField].splice(index, 1);
        setFormData(newFd);
    }

    useEffect(() => {
        if (operation) {
            if (Number(operation?.share_operation_id) === 6 || Number(operation.share_operation_id) === 5) {
                setAvailableFields([
                    'no-of-shares',
                    'share-types',
                    'share-class',
                    'operation_file',
                    'to',
                    'comment',
                    'percentage',
                    'registration-date',
                ])
                if (parseInt(operation?.number_of_shares ? operation?.number_of_shares : 0) + parseInt(operation?.preffered_shares ? operation?.preffered_shares : 0) > 0) {
                    setPercentage(((parseInt(operation?.number_of_shares ? operation?.number_of_shares : 0) + parseInt(operation?.preffered_shares ? operation?.preffered_shares : 0)) * 100) / profileClient?.total_issued_shares)
                }
            }

            setSelectedShareOperation(shareOperationOptions()[getIndex(operation?.share_operation_id, shareOperationOptions(), 'value')])

            if (Number(operation?.share_operation_id) === 1) {

                let allShareholders = getShareholdersOptions();
                setSelectedShareholder(allShareholders[getIndex(operation?.from.id, allShareholders, 'value')])
            }

            // setSelectedShareholder(getShareholdersOptions()[getIndex(operation?.from?.id, getShareholdersOptions(), 'value')]);
            setNumberOfShares(operation?.number_of_shares)
            let sharetypesOptions = getOptions(generalData.share_types, 'slug', 'id', 'title');
            setSelectedShareType(sharetypesOptions[getIndex(operation?.share_type?.slug, sharetypesOptions, 'slug')]);

            let shareClassOptions = getOptions(generalData?.share_class, 'slug', 'id', 'title');
            setSelectedShareClass(shareClassOptions[getIndex(operation?.share_class_id, shareClassOptions, 'value')]);

            if (operation?.shareholder?.client?.client_type_id === 1) {
                let text = 'client-' + operation?.shareholder?.client?.id;

                setTimeout(() => {
                    setSelectedTo(companyRelations()[getIndex(text, companyRelations(), 'value')]);
                }, 300)
            }
            if (operation?.shareholder?.client?.client_type_id === 2) {
                let text = 'client-' + operation?.shareholder?.client?.id;
                setTimeout(() => {
                    setSelectedTo(individualRelations()[getIndex(text, individualRelations(), 'value')]);
                }, 300);
            }
            if (operation?.shareholder?.client?.client_type_id === 3) {
                let text = 'client-' + operation?.shareholder?.client?.id;
                setTimeout(() => {
                    setSelectedTo(ngoRelations()[getIndex(text, ngoRelations(), 'value')]);
                }, 300);
            }

            let currencies = getOptions(generalData.currencies, 'slug', 'id', 'title');
            setSelectedCurrency(currencies[getIndex(operation?.currency_id, currencies, 'value')])

            let loanTypes = getOptions(generalData.loanTypes, 'slug', 'id', 'title');
            setSelectedLoanType(loanTypes[getIndex(operation?.loan_type?.id, loanTypes, 'value')]);

            setDocumentLink(operation?.file);
            setFileName(operation?.uploaded_files?.file_name);
            setOperationDocumentLink(operation?.op_file);
            setOperationFileName(operation?.operation_files?.file_name)
            setInvestmentAmount(
                operation?.currency_id ?

                    operation?.currency_id !== profileClient?.currency?.id ?
                        (parseFloat(operation?.amount) / parseFloat(operation?.exchange_rate))
                        :
                        operation?.amount
                    : operation?.amount
            )

            setExchangeRate(operation?.exchange_rate);

            // setTransactionDate(new Date(operation?.transaction_date));
            // setRegistrationDate(new Date(operation?.registrationDate));
            setCertificateNb(operation?.certificateNb);
            setComment(operation?.comment);

            let emptyObject = [];
            emptyObject['ubos'] = [
                { email: '', shares: '' }
            ];

            setFormData((prev) => (
                operation?.ubos?.length > 0 ?
                    {
                        ubos: operation.ubos.map((ubo) => (
                            { id: ubo.id, email: ubo.email, shares: ubo.shares }
                        )),
                    }
                    : emptyObject
            ))
        }
    }, [operation]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedLoanType) {
            handleLoantypeChange(selectedLoanType)
        }
    }, [selectedLoanType]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedCurrency && selectedCurrency?.slug !== profileClient?.currency?.slug) {
            handleCurrencyChange(selectedCurrency);
        }
    }, [selectedCurrency]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                {
                    <Draggable handle='.drag-handle' bounds={'parent'}>
                        <div className={`${confirmationPopup ? 'd-none' : ''} pop-up-api-box operation-popup`}>
                            <CloseIcon className="cursor-pointer" onClick={(e) => setIsShareOperationPopupOpen(false)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue drag-handle cursor-grab">{generalData?.website_titles['shareholders-operations']}</h1>
                                        </div>
                                        <form onSubmit={validateOperationRequest}>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-12 pt-2 mx-auto">
                                                        <div className="position-relative text-start justify-content-start w-100" >
                                                            {
                                                                (operation?.share_operation_id !== 6) &&

                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={shareOperationOptions()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.website_titles['share-operation-type']}
                                                                    value={selectedShareOperation ? selectedShareOperation : null}
                                                                    isSearchable={false}
                                                                    onChange={handleOperationChange}
                                                                    isDisabled={!!props.operation}
                                                                />
                                                            }

                                                            {
                                                                errorsReturned?.share_operation_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.share_operation_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>

                                                    {
                                                        avaibaleFields.includes('relation') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <RelationsSelect
                                                                    selectedRelation={selectedRelation}
                                                                    setSelectedRelation={setSelectedRelation}
                                                                    profile={profileClient}
                                                                    defaultSelected={clientType ? (clientType === 1 ? 'company' : (clientType === 2 ? 'individual' : 'ngo')) : 'individual'}
                                                                    excludeMatters
                                                                />

                                                            </div>
                                                            {
                                                                errorsReturned?.relation_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.relation_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        avaibaleFields.includes('from') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100" >
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getShareholdersOptions()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.website_titles['from']}
                                                                    value={selectedShareholder ? selectedShareholder : null}
                                                                    isSearchable={false}
                                                                    onChange={setSelectedShareholder}
                                                                    isDisabled={!!props.operation}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className='position-x-center'
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0,
                                                                    }}
                                                                    value={selectedShareholder || ''}
                                                                    required
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.from_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.from_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('to') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100" >
                                                                <RelationsSelect
                                                                    selectedRelation={selectedTo}
                                                                    setSelectedRelation={handleRelationChange}
                                                                    profile={profileClient}
                                                                    placeholder={(selectedShareOperation && selectedShareOperation.slug === 'increase-in-capital') ? generalData?.shareholdersFixedTitles['from'] : (selectedShareOperation && selectedShareOperation.slug !== 'initial-subscription' && generalData?.shareholdersFixedTitles['to'])}
                                                                    defaultSelected={clientType ? (clientType === 1 ? 'company' : (clientType === 2 ? 'individual' : 'ngo')) : 'individual'}
                                                                    excludeValue={selectedShareholder ? selectedShareholder : null}
                                                                    excludeMatters
                                                                // isDisabled={!!props.operation}
                                                                />
                                                                <input
                                                                    type="text"
                                                                    className='position-x-center'
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0,
                                                                    }}
                                                                    value={selectedTo || ''}
                                                                    required
                                                                />

                                                            </div>
                                                            {
                                                                errorsReturned?.to_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.to_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('no-of-shares') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100" >
                                                                <input
                                                                    name="no_of_shares"
                                                                    className="add-user-inputs text-start"
                                                                    placeholder={generalData?.shareholdersFixedTitles['no-of-shares']}
                                                                    value={numberOfShares}
                                                                    onChange={handleNoOfSharesChange}
                                                                    type="number"
                                                                    required
                                                                />

                                                            </div>
                                                            {
                                                                errorsReturned?.number_of_shares ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.number_of_shares}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('loan-type') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100" >
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getOptions(generalData.loanTypes, 'slug', 'id', 'title')}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.website_titles['loan-types']}
                                                                    value={selectedLoanType ? selectedLoanType : null}
                                                                    isSearchable={false}
                                                                    onChange={handleLoantypeChange}
                                                                />

                                                                {
                                                                    errorsReturned?.loan_type ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.loan_type}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        avaibaleFields.includes('file') &&
                                                        <>
                                                            <div className="col-12 col-md-6 pt-2 position-relative">
                                                                <input
                                                                    hidden
                                                                    id='upload-document-file'
                                                                    type="file"
                                                                    onChange={(e) => { documentHandler(e.target.files) }}
                                                                />
                                                                <label
                                                                    className="pop-upload-file position-relative"
                                                                    htmlFor="upload-document-file"
                                                                >
                                                                    {documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}
                                                                </label>

                                                                <AttachIcon />
                                                                {
                                                                    errorsReturned?.file ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-12 col-md-6 pt-2 position-relative">
                                                                <input
                                                                    className='add-user-inputs'
                                                                    id='file_name'
                                                                    type="text"
                                                                    placeholder={generalData?.website_titles['file-name']}
                                                                    onChange={(e) => { setFileName(e.target.value) }}
                                                                    value={fileName}
                                                                />
                                                                {
                                                                    errorsReturned?.file_name ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_name}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        avaibaleFields.includes('operation_file') &&
                                                        <>
                                                            <div className="col-12 col-md-6 pt-2 position-relative">
                                                                <input
                                                                    hidden
                                                                    id='upload-operation-file'
                                                                    type="file"
                                                                    onChange={(e) => { operationDocumentHandler(e.target.files) }}
                                                                />
                                                                <label
                                                                    className="pop-upload-file position-relative"
                                                                    htmlFor="upload-operation-file"
                                                                >
                                                                    {operationDocumentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}
                                                                </label>

                                                                <AttachIcon />
                                                                {
                                                                    errorsReturned?.operation_document ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.operation_document}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="col-12 col-md-6 pt-2 position-relative">
                                                                <input
                                                                    className='add-user-inputs'
                                                                    id='file_name'
                                                                    type="text"
                                                                    placeholder={generalData?.website_titles['file-name']}
                                                                    onChange={(e) => { setOperationFileName(e.target.value) }}
                                                                    value={operationFileName}
                                                                />
                                                                {
                                                                    errorsReturned?.operation_file_name ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.operation_file_name}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </>
                                                    }

                                                    {
                                                        avaibaleFields.includes('amount') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <input
                                                                    name="amount"
                                                                    className="add-user-inputs text-start"
                                                                    placeholder={generalData?.website_titles['amount-investment']}
                                                                    value={investmentAmount}
                                                                    onChange={handleAmmountChange}
                                                                    type="number"
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.amount ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        avaibaleFields.includes('percentage') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <input disabled name="percentage" type='percentage' className="add-user-inputs" placeholder={generalData?.shareholdersFixedTitles['percentage']} value={percentage} onChange={handlePercentageChange} />
                                                                <span className='position-y-center end-0 me-3'>%</span>
                                                            </div>
                                                            {
                                                                errorsReturned?.percentage ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.percentage}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        avaibaleFields.includes('currency') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getOptions(generalData.currencies, 'slug', 'id', 'title')}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.website_titles['currency']}
                                                                    value={selectedCurrency ? selectedCurrency : null}
                                                                    isSearchable={false}
                                                                    onChange={handleCurrencyChange}
                                                                />

                                                                {
                                                                    errorsReturned?.currency ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.currency}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        (selectedCurrency && Number(selectedCurrency?.value) !== Number(profileClient?.currency?.id)) &&
                                                        <div className="col-12 col-md-6 pt-2" >
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <input
                                                                    type='number'
                                                                    name="exhangeRate"
                                                                    step={'any'}
                                                                    className="add-user-inputs exchange-padding"
                                                                    // placeholder={`${generalData?.website_titles['exchange-rate']} ${selectedCurrency.label}->${profileClient.currency.title}`}
                                                                    value={exchangeRate}
                                                                    onChange={e => setExchangeRate(e.target.value)}
                                                                />
                                                                <div className='position-y-center start-0 ms-2'>
                                                                    1 {selectedCurrency.label} =
                                                                </div>
                                                                <div className='position-y-center end-0 me-2'>
                                                                    {profileClient?.currency?.title}
                                                                </div>
                                                            </div>
                                                            {
                                                                errorsReturned?.exchange_rate ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.exchange_rate}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        avaibaleFields.includes('share-types') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100" >
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getOptions(generalData.share_types, 'slug', 'id', 'title')}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.website_titles['share-type']}
                                                                    value={selectedShareType ? selectedShareType : getOptions(generalData.share_types, 'slug', 'id', 'title')[0]}
                                                                    isSearchable={false}
                                                                    onChange={setSelectedShareType}
                                                                    disabled={disabledFields.includes('share-type')}
                                                                />

                                                                {
                                                                    errorsReturned?.share_type_id ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.share_type_id}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        avaibaleFields.includes('share-class') &&
                                                        <div className="col-12 col-md-6 pt-2" >
                                                            <div className="position-relative text-start justify-content-start w-100" >
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getOptions(generalData.share_class, 'slug', 'id', 'title')}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.website_titles['share-class']}
                                                                    value={selectedShareClass ? selectedShareClass : ''}
                                                                    isSearchable={false}
                                                                    onChange={setSelectedShareClass}
                                                                    disabled={disabledFields.includes('share-class')}
                                                                />

                                                                {
                                                                    errorsReturned?.share_class_id ?
                                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.share_class_id}</h5>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('transaction-date') &&
                                                        <div className="col-12 col-md-6 pt-2" >
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.website_titles['transactionloan-date']}
                                                                    value={transactionDate ? transactionDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={transactionDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => { setTransactionDate(date); }}
                                                                    minDate={addYears(new Date(), -50)}
                                                                    maxDate={addYears(new Date(), +50)}
                                                                    disabled={disabledFields.includes('transaction-date')}
                                                                    locale={localStorage.getItem("LmsLanguage")}

                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.transaction_date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.transaction_date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('registration-date') &&
                                                        <div className="col-12 col-md-6 pt-2" >
                                                            <div className="postion-relative text-start justify-content-start w-100" >
                                                                {/* <input name="to" className="add-user-inputs" placeholder={generalData?.website_titles['registration-date']} value={''} /> */}
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.website_titles['registration-date']}
                                                                    value={registrationDate ? registrationDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={registrationDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setRegistrationDate(date)}
                                                                    minDate={addYears(new Date(), -50)}
                                                                    maxDate={addYears(new Date(), +50)}
                                                                    disabled={disabledFields.includes('transaction-date')}
                                                                    locale={localStorage.getItem("LmsLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.registration_date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.registration_date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('execution-date') &&
                                                        <div className="col-12 col-md-6 pt-2"  >
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                {/* <input name="to" className="add-user-inputs" placeholder={generalData?.website_titles['registration-date']} value={''} /> */}
                                                                <DatePicker
                                                                    className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.website_titles['execution-date']}
                                                                    value={executionDate ? executionDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={executionDate}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => setExecutionDate(date)}
                                                                    minDate={addYears(new Date(), -50)}
                                                                    maxDate={addYears(new Date(), +50)}
                                                                    disabled={disabledFields.includes('execution-date')}
                                                                    locale={localStorage.getItem("LmsLanguage")}

                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.execution_date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.execution_date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('notification') &&
                                                        <div className="col-12 col-md-6 mt-2 position-relative" >
                                                            <input
                                                                name="notify_before"
                                                                value={reminderNotifyBefore}
                                                                onChange={(e) => setReminderNotifyBefore(e.target.value)}
                                                                className="add-user-inputs"
                                                                placeholder={generalData?.website_titles['notify-before']}
                                                                type="number"
                                                            />
                                                            <Select
                                                                classNamePrefix="curr-select"
                                                                className="curr-select curr-select-edited w-100"
                                                                styles={customStylesPopupCurr}
                                                                options={getOptions(generalData?.notify_before_types, 'slug', 'id', 'title')}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                isSearchable={false}
                                                                value={reminderNotifyBeforeType}
                                                                onChange={setReminderNotifyBeforeType}
                                                            />
                                                            {
                                                                errorsReturned?.notify_before ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start  pop-errors-text">{errorsReturned?.notify_before}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('share-par-value') &&
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <input
                                                                    name="to"
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.website_titles['share-par-value']}
                                                                    value={shareParValue}
                                                                    onChange={(e) => setShareParValue(e.target.value)}
                                                                    disabled={disabledFields.includes('share-par-value')}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.to ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.client_stat_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('certificateNb') &&
                                                        <div className="col-12 col-md-6 pt-2" >
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <input
                                                                    type='text'
                                                                    name="to"
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.website_titles['certificate-nb']}
                                                                    value={certificateNb}
                                                                    onChange={e => setCertificateNb(e.target.value)}
                                                                    disabled={disabledFields.includes('certificate-nb')}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.certificateNb ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.certificateNb}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        avaibaleFields.includes('comment') &&
                                                        <div className="col-12 pt-2">

                                                            <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.website_titles['comment']} value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                                            {
                                                                errorsReturned?.comment ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                errorsReturned?.board_exists ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start  pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    }

                                                    {
                                                        avaibaleFields.includes('ubos') &&
                                                        <div className="col-12 pt-2">
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                {
                                                                    formData?.ubos?.map((ubo, index) => (
                                                                        <div className='row gx-2 align-items-center mb-2'>
                                                                            <div className='col-6'>
                                                                                <input
                                                                                    name="to"
                                                                                    className="add-user-inputs text-start"
                                                                                    placeholder={generalData?.website_titles['ubo']}
                                                                                    value={ubo.email}
                                                                                    onChange={(e) => handleUboChange(e, index)}
                                                                                />
                                                                            </div>

                                                                            <div className='col-4'>
                                                                                <div className='position-relative w-100 h-100'>
                                                                                    <input
                                                                                        type='number'
                                                                                        name="to"
                                                                                        className="add-user-inputs pe-4 text-start"
                                                                                        placeholder={generalData?.website_titles['percentage']}
                                                                                        value={ubo.shares}
                                                                                        onChange={e => handleUboSharesChange(e, index)}
                                                                                    />
                                                                                    <div className='position-y-center end-0 me-2'>%</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-1'>

                                                                                {
                                                                                    formData.ubos.length > 1 && Number(index) !== Number(formData.ubos.length) - 1 ?
                                                                                        <button className="btn" type='button' onClick={() => removeFromFormData('ubos', index, ubo?.id)}><MinusCircle className="position-relative start-0" /></button>
                                                                                        :
                                                                                        <button className="btn" type='button' onClick={() => addToFormData('ubos')}><AddIconCircle className="position-relative start-0" /></button>
                                                                                }
                                                                            </div>


                                                                            {
                                                                                errorsReturned && errorsReturned[`ubos.${index}.shares`]
                                                                                    ?
                                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start  pop-errors-text">{errorsReturned[`ubos.${index}.shares`]}</h5>
                                                                                    :
                                                                                    errorsReturned[`ubos.${index}.email`] ?
                                                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned[`ubos.${index}.email`]}</h5>
                                                                                        :
                                                                                        null
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }

                                                                {
                                                                    errorsReturned?.ubos &&
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned.ubos}</h5>

                                                                }

                                                            </div>
                                                        </div>
                                                    }

                                                </div>

                                            </div>
                                            {
                                                errorsReturned?.invalid_operation &&
                                                <h5 className="py-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.invalid_operation}</h5>
                                            }
                                            <div className="col-12 d-flex justify-content-center">
                                                <button disabled={!selectedShareOperation && !operation} type="submit" className="popup-button my-auto">{generalData?.website_titles['save']}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Draggable>
                }


                {
                    confirmationPopup &&

                    <Draggable handle='.drag-handle' bounds={'parent'}>
                        <div className="pop-up-api-box operation-popup">
                            <CloseIcon className="cursor-pointer" onClick={(e) => { setConfirmationPopup(false) }} />
                            <div className="">
                                <div className="containter-fluid">
                                    <div className="row d-flex align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center mb-4 drag-handle pt-4 cursor-grab">
                                            {/* <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1> */}
                                        </div>
                                        {
                                            Number(operation?.share_operation_id) !== 6 &&
                                            <div className="col-12 mb-4 border-bottom text-start">
                                                <h5 className="text-start"><strong>{generalData?.shareholdersFixedTitles['operation-title']}</strong></h5>
                                                {
                                                    selectedShareOperation ? selectedShareOperation.label : operation?.title ? operation?.title : '-'

                                                }
                                            </div>
                                        }

                                        {
                                            avaibaleFields.includes('from') &&
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className="text-start"><strong>{generalData?.shareholdersFixedTitles['from']}</strong></h5>
                                                {
                                                    selectedShareholder ? selectedShareholder.label : '-'

                                                }
                                            </div>
                                        }

                                        {
                                            avaibaleFields.includes('to') &&
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersFixedTitles['to']}</strong></h5>
                                                {selectedTo ? selectedTo?.label : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('no-of-shares') &&
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersFixedTitles['no-of-shares']}</strong></h5>
                                                {numberOfShares ? numberOfShares : '-'}
                                            </div>
                                        }
                                        {/* <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles.to_title}</strong></h5>
                                                {selectedTo ? selectedTo.value : '-'}
                                            </div> */}
                                        {
                                            avaibaleFields.includes('loan-type') &&
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['loan-types']}</strong></h5>
                                                {selectedLoanType ? selectedLoanType.label : '-'}
                                            </div>
                                        }

                                        {
                                            avaibaleFields.includes('file') &&
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['file-name']}</strong></h5>
                                                <div>

                                                    <FileIcon className="py-2 me-2 position-relative end-0" />
                                                    {fileName ? fileName : '-'}
                                                </div>

                                            </div>

                                        }

                                        {
                                            avaibaleFields.includes('amount') &&
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['amount-investment']}</strong></h5>
                                                {investmentAmount ? investmentAmount : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('percentage') &&
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersFixedTitles['percentage']}</strong></h5>
                                                {percentage ? `${percentage} %` : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('currency') &&
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['currency']}</strong></h5>
                                                {selectedCurrency ? selectedCurrency.label : ''}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('exchange_rate') &&
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['exchange-rate']}</strong></h5>
                                                {exchangeRate ? exchangeRate : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('share-types') &&
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['share-type']}</strong></h5>
                                                {selectedShareType ? selectedShareType.label : getOptions(generalData.share_types, 'slug', 'id', 'title')[0]?.label}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('share-class') &&
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['share-class']}</strong></h5>
                                                {selectedShareClass ? selectedShareClass.label : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('transaction-date') &&
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['transactionloan-date']}</strong></h5>
                                                {transactionDate ? formatDate(transactionDate) : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('registration-date') &&
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['registration-date']}</strong></h5>
                                                {registrationDate ? formatDate(registrationDate) : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('certificateNb') &&
                                            <div className="col-6 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['certificate-nb']}</strong></h5>
                                                {certificateNb ? certificateNb : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('comment') &&
                                            <div className="col-12 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['comment']}</strong></h5>
                                                {comment ? comment : '-'}
                                            </div>
                                        }
                                        {
                                            avaibaleFields.includes('ubos') &&
                                            <div className="col-12 pt-2">
                                                <h5 className=" text-start"><strong>{generalData?.website_titles['ubo']}</strong></h5>
                                                <div className="position-relative text-start justify-content-start w-100">
                                                    {
                                                        formData?.ubos?.length > 0 ?
                                                            formData?.ubos?.map((ubo, index) => (
                                                                ubo.email !== '' && ubo.shares !== '' &&
                                                                <div className='row gx-2 align-items-center mb-2'>
                                                                    <div className='col'>
                                                                        {ubo.email}
                                                                    </div>

                                                                    <div className='col-auto'>
                                                                        {/* <div className='position-relative w-100 h-100'> */}
                                                                        {/* <div className='position-y-center end-0 me-2'> */}
                                                                        {ubo.shares}%
                                                                        {/* </div> */}
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                            ))
                                                            : '-'
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            errorsReturned?.invalid_operation &&
                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.invalid_operation}</h5>
                                        }
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button
                                                onClick={submitShareholdersOperation}
                                                type="button"
                                                className="popup-button my-auto mx-2 w-auto px-3">{generalData?.website_titles['save']}
                                            </button>
                                            <button onClick={(e) => { setConfirmationPopup(false) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Draggable>
                }
            </div>

            <Api options={generalDataApiOptions} />
        </>
    )
}