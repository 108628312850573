import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useParams } from "react-router";
import React from 'react';
import Api from "../components/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import Pagination from "../components/Pagination";

import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { BackIcon, CloseIcon, EditIcon, ExportIcon, FileIcon } from "../components/Icons";
import ShareOperationManageComponent from "../components/ShareOperationManageComponent";
import showCommaSeperator from "../useCommaSeparator";
import { registerLocale } from "react-datepicker";
import { formatDate } from '../Helpers';
import ar from 'date-fns/locale/ar';
registerLocale('ar', ar)


export default function ShareholdersTransactions(props) {

    const { generalDataGlobal, authUserGlobal, profileClientGlobal, operations, setOperations } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalData] = generalDataGlobal;
    const [authUser,] = authUserGlobal;
    const [usersApiOptions,] = useState({});
    const [profileClient, setProfileClient] = profileClientGlobal;
    const { user_id, slug } = useParams();
    const [errorsReturned, setErrorsReturned] = useState({});

    const [, setOperationsLink] = useState([]);
    const [operationsCurrentPage, setOperationsCurrentPage] = useState(1);
    const [operationsLastPage, setOperationsLastPage] = useState(1);
    const [filterDateFrom, setFilteDateFrom] = useState(null);
    const [filterDateTo, setFilteDateTo] = useState(null);

    //get all expenses filters
    const allExpenseFilters = () => {
        const expFilter = generalData?.expense_filters?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expFilter;
    }

    const [, setCurrentFilterExpenses] = useState(allExpenseFilters()[2]);
    const [changeExpenses, setChangeExpenses] = useState(0);
    const [changeWallet, setChangeWallet] = useState(0);
    const [customDatesPopup, setCustomDatesPopup] = useState(false);
    /* expenses end */

    const [filterDates, setFilterDates] = useState(null);
    const [filterShareholder, setFilterShareholder] = useState()

    const [operation, setOperation] = useState(null);
    const [isShareOperationPopupOpen, setIsShareOperationPopupOpen] = useState(false);

    const [targetOperationUpdate, setTargetOperationUpdate] = useState();
    const [deleteOperation, setDeleteOperation] = useState({});


    const [editMenu, setEditMenu] = useState();

    //handle date change
    const onChangeDate = (dates) => {
        const [start, end] = dates;
        setFilterDates(
            start && end ? dates : null
        )
        setFilteDateFrom(start);
        setFilteDateTo(end);
    };

    //export expenses excel
    const exportOperations = (e) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'export-operations',
            method: 'post',
            data: {
                user_id: Number(user_id),
                company_id: Number(user_id),
                date_from: filterDateFrom ? formatDate(filterDateFrom) : null,
                date_to: filterDateTo ? formatDate(filterDateTo) : null,
                shareholderFilter: filterShareholder ? filterShareholder?.value : null,
            },
            callback: res => {
                clickUrl(e, res.data);
            },
        });
    };


    //open url _blank
    const clickUrl = (e, url) => {
        e.preventDefault();
        window.open(url, "_blank");
    };

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    //function to add months to a date
    function addMonths(date, months) {
        date.setMonth(date.getMonth() + months);
        return date;
    }

    // get operations data
    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'get-share-operation?page=' + operationsCurrentPage,
            method: 'post',
            data: {
                user_id: Number(user_id),
                company_id: Number(user_id),
                date_from: filterDateFrom ? formatDate(filterDateFrom) : null,
                date_to: filterDateTo ? formatDate(filterDateTo) : null,
                shareholderFilter: filterShareholder ? filterShareholder?.value : null,
            },
            callback: ({ data }) => {
                setOperations(data.operations);
                setProfileClient(data.client_user);
            },
        });
    }, [operationsCurrentPage, filterDates, filterShareholder]);// eslint-disable-line react-hooks/exhaustive-deps

    //set expenses pagination data
    useEffect(() => {
        if (operations?.links?.length > 0) {
            let links = [];
            operations?.links?.forEach((element, index) => {
                links.push(index);
            });
            setOperationsLink(links);
            setOperationsCurrentPage(operations?.current_page);
            setOperationsLastPage(operations?.last_page);
        }
    }, [operations]);

    //custom select styles
    const customStylesDateFilter = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '500',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "4px",
                maxWidth: "100%",
                paddingLeft: "4px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "1px solid transparent",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '500',
            boxShadow: "none",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid white",
            marginTop: "-10px",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            opacity: "0.4",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '500',
            padding: "0",
            marginTop: "1px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    const handleOperationInputChange = (value, targetOperation, field) => {
        setOperations(prev => (
            {
                ...prev,
                data: [
                    ...prev.data.map(operation => (
                        Number(operation.id) === Number(targetOperation) ? { ...operation, [field]: value } : operation
                    )),
                ]
            }
        ))
        setTargetOperationUpdate({
            value: value,
            operation: targetOperation,
            field: field
        })
    }

    useEffect(() => {
        const submitData = setTimeout(() => {
            const fd = new FormData();
            fd.append('field', targetOperationUpdate.field);
            fd.append('value', targetOperationUpdate.value);
            fd.append('operation', targetOperationUpdate.operation);
            setGeneralDataApiOptions({
                url: 'update-operation',
                method: 'post',
                data: fd,
                showLoader: true,
                callback: res => {
                },
                catch: err => {
                    if (err) {
                        setErrorsReturned(err);
                    }
                }
            })
        }, 500)
        return () => clearTimeout(submitData);
    }, [targetOperationUpdate])

    useEffect(() => {
    }, [operations])

    const handleDeleteOperation = (e, operation) => {
        e.stopPropagation();
        e.preventDefault();

        let fd = new FormData();

        if (deleteOperation?.id) {
            fd.append('operation_id', deleteOperation?.id);
        }

        fd.append('company_id', user_id);
        fd.append('user_id', user_id);

        setGeneralDataApiOptions({
            url: 'operation-delete',
            method: 'post',
            data: fd,
            callback: res => {
                setProfileClient(res?.data?.client_user);
                setDeleteOperation({});
                setErrorsReturned({});
                setOperations(res?.data?.operations)
                setEditMenu('')
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });

    }

    const getShareholdersOptions = () => {
        const shareholders = profileClient?.all_shareholders?.map(shareholder => {
            return ({ slug: `${shareholder?.client?.client_type?.title}-${shareholder.client.name}`, value: shareholder.id, label: shareholder.client.name })
        }
        )
        return shareholders;
    }

    return (
        <>
            <Layout activePage="contacts">
                {
                    authUser?.id ?
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="row justify-content-between">
                                        <div className="col-auto d-flex pe-0">
                                            <Link to={'/client/' + (user_id) + '/shareholders'} className="m-0 page-titles d-flex align-items-center">
                                                <BackIcon className='me-2 back-icon' /> {generalData?.shareholdersSectionSettings['transactions-title']}
                                            </Link>
                                        </div>
                                        <div className="col-auto ps-0">
                                            <button className=" popup-button my-auto w-auto px-3 mb-3" onClick={(e) => exportOperations(e)}>
                                                <ExportIcon className="export-button-svg me-1" /> {generalData?.fixed_titles['export']}</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 pt-2 pb-3">
                                    <div className="row">

                                        <div className="col-12 pt-lg-0 height-100">
                                            <div className={'costs-box-styling bg-white'}>
                                                <div className="col-12 ">
                                                    <div className="row gx-0 justify-content-end">

                                                        <div className="col-12 col-xl-8 pb-2 pt-1">
                                                            <div className="row gx-0 w-100 justify-content-md-end">
                                                                <div className="col-auto me-4  postion-relative">
                                                                    <div className="filter-picker-expenses w-100">
                                                                        <div className="shareholder-transaction-datepicker position-relative w-100 h-100">

                                                                            <DatePicker
                                                                                className="pe-4 popup-date"
                                                                                placeholderText={generalData?.website_titles['date']}
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                onChange={onChangeDate}
                                                                                startDate={filterDateFrom}
                                                                                endDate={filterDateTo}
                                                                                selectsRange
                                                                                minDate={addYears(new Date(), -21)}
                                                                                maxDate={addMonths(new Date(), 1)}
                                                                                isClearable={true}
                                                                                locale={localStorage.getItem("LmsLanguage")}

                                                                            />
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div style={{ zIndex: "997" }} className="col-auto filter-status-expeneses  filters-date-select postion-relative">
                                                                    <Select
                                                                        classNamePrefix="filter-picker-users"
                                                                        className="filter-status-expeneses"
                                                                        placeholder={generalData?.shareholdersSectionSettings['shareholder-name-title']}
                                                                        styles={customStylesDateFilter}
                                                                        options={getShareholdersOptions()}
                                                                        components={{
                                                                            IndicatorSeparator: () => null
                                                                        }}
                                                                        isSearchable={false}
                                                                        isClearable={true}
                                                                        value={filterShareholder}
                                                                        onChange={setFilterShareholder}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 pt-1">
                                                    <div className="table-wrapper-settings shareholders-transactions">
                                                        <table className="users-table" width="100%">
                                                            <thead>
                                                                <tr>
                                                                    <td></td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['operation-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['shareholder-name-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['from-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['to-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['operation-type-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['par-value-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['shares-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.website_titles['share-type']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.website_titles['share-class']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['loan-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['note-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['operation-file']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['amount-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['nominal-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['premium-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['premium-share-title']}</td>

                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['registration-date-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['transaction-date-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['ubo-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['ubo-shares-title']}</td>
                                                                    <td className="table-sorting" style={{ whiteSpace: 'nowrap', minWidth: '300px' }}>{generalData?.shareholdersSectionSettings['comment-title']}</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    operations?.data?.length > 0 &&
                                                                    operations.data?.map((operation, index) => (
                                                                        <tr key={index} className="my-3" >
                                                                            <td className="text-end p-2 position-relative" >
                                                                                <EditIcon onClick={(e) => { e.stopPropagation(); setEditMenu(editMenu ? '' : operation) }} />
                                                                                <div style={{ top: '0px' }} className={`settings-clickmenu first-td-buttons text-start ${editMenu?.id === operation?.id ? 'd-block' : ''}`}>
                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setIsShareOperationPopupOpen(true); setOperation(operation); setEditMenu('') }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                    <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); setDeleteOperation(operation); setEditMenu('') }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                </div>

                                                                                {/* <button onClick={() => { setIsShareOperationPopupOpen(true); setOperation(operation) }} className="edit-pencil">
                                                                                    <Pencil />
                                                                                </button> */}
                                                                            </td>
                                                                            <td>{operation?.id}</td>
                                                                            <td>{operation?.shareholder?.shareholder_name ? operation?.shareholder?.shareholder_name : '-'}</td>
                                                                            <td>{operation?.from?.shareholder_name ? operation?.from?.shareholder_name : '-'}</td>
                                                                            <td>{operation?.share_operation?.slug === 'increase-in-capital' ? '-' : (operation?.to?.shareholder_name ? operation?.to?.shareholder_name : '-')}</td>
                                                                            <td>{operation?.share_operation.title ? operation?.share_operation.title : '-'}</td>
                                                                            <td>{operation?.share_value ? showCommaSeperator(operation?.share_value) : '-'}</td>
                                                                            <td>{operation?.number_of_shares ? showCommaSeperator(operation?.number_of_shares) : '-'}</td>
                                                                            <td>{operation?.share_type ? operation?.share_type.title : '-'}</td>
                                                                            <td>{operation?.share_class ? operation?.share_class.title : '-'}</td>
                                                                            <td>{operation?.loan_type?.title ? operation?.loan_type?.title : '-'}</td>
                                                                            <td>
                                                                                {
                                                                                    operation?.uploaded_files ?
                                                                                        <a target='_blank' rel="noreferrer" href={operation?.uploaded_files?.full_path_file} >
                                                                                            <FileIcon className="me-2" />
                                                                                            {operation?.uploaded_files?.file_name}
                                                                                        </a>
                                                                                        : '-'
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    operation?.operation_files ?
                                                                                        <a target='_blank' rel="noreferrer" href={operation?.operation_files?.full_path_file} >
                                                                                            <FileIcon className="me-2" />
                                                                                            {operation?.operation_files?.file_name}
                                                                                        </a>
                                                                                        : '-'
                                                                                }
                                                                            </td>
                                                                            <td>{operation?.amount ? showCommaSeperator(operation?.amount) : '-'}</td>
                                                                            <td>
                                                                                {
                                                                                    operation?.share_operation.slug !== 'transfer-of-shares' && operation?.share_operation.slug !== 'initial-subscription' && operation?.share_operation?.slug !== 'add-shareholder' ?
                                                                                        <div className="row">
                                                                                            <div className="col-6">
                                                                                                <div className="w-100 h-100 d-flex  justify-content-between">
                                                                                                    <span>
                                                                                                        {showCommaSeperator(profileClient.share_values)}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        x
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {showCommaSeperator(operation?.number_of_shares)}

                                                                                                    </span>
                                                                                                    <span>
                                                                                                        =
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <p className="text-center mb-0">
                                                                                                            {showCommaSeperator(operation?.nominal_total_value)}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <input onChange={({ target }) => { handleOperationInputChange(target.value, operation.id, 'editable_nominal_total_value') }} className="transaction-editable-input" value={showCommaSeperator(operation?.editable_nominal_total_value)} />
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                        :
                                                                                        '-'
                                                                                }

                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    operation?.share_operation.slug !== 'transfer-of-shares' && operation?.share_operation.slug !== 'initial-subscription' && operation?.share_operation?.slug !== 'add-shareholder' ?
                                                                                        <div className="row flex-nowrap">
                                                                                            <div className="col-6">
                                                                                                <div className="w-100 h-100 d-flex justify-content-between">
                                                                                                    <span>
                                                                                                        {showCommaSeperator(operation?.amount)}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        -
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {showCommaSeperator(operation?.nominal_total_value)}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        =
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <p className="text-center mb-0">
                                                                                                            {showCommaSeperator(operation?.premium_total_value)}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <input onChange={({ target }) => { handleOperationInputChange(target.value, operation.id, 'editable_premium_total_value') }} className="transaction-editable-input" value={showCommaSeperator(operation?.editable_premium_total_value)} />
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                        : '-'
                                                                                }
                                                                            </td>
                                                                            <td className="overflow-hidden">
                                                                                {
                                                                                    operation?.share_operation.slug !== 'transfer-of-shares' && operation?.share_operation.slug !== 'initial-subscription' && operation?.share_operation?.slug !== 'add-shareholder' ?
                                                                                        <div className="row flex-nowrap">
                                                                                            <div className="col-6">
                                                                                                <div className="w-100 h-100 d-flex justify-content-between">
                                                                                                    <span>
                                                                                                        {
                                                                                                            profileClient?.currency?.id === 2 ?
                                                                                                                <>
                                                                                                                    {showCommaSeperator(parseFloat(operation?.premium_total_value / operation?.exchange_rate).toFixed(2))} {operation?.currency?.title}
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    {showCommaSeperator(operation?.premium_total_value)} {profileClient?.currency?.title}
                                                                                                                </>

                                                                                                        }
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        /
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {showCommaSeperator(operation?.number_of_shares)}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        =
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-6">
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <p className="text-center mb-0">
                                                                                                            {
                                                                                                                profileClient?.currency?.id === 2 ?
                                                                                                                    <>
                                                                                                                        {showCommaSeperator(operation?.premium_share)} {operation?.currency?.title}

                                                                                                                    </>
                                                                                                                    :
                                                                                                                    <>
                                                                                                                        {showCommaSeperator(operation?.premium_share)} {profileClient?.currency?.title}
                                                                                                                    </>
                                                                                                            }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <input onChange={({ target }) => { handleOperationInputChange(target.value, operation.id, 'editable_premium_share') }} className="transaction-editable-input" value={showCommaSeperator(operation?.editable_premium_share)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : '-'
                                                                                }
                                                                            </td>
                                                                            <td>{operation?.registration_date ? operation?.registration_date : '-'}</td>
                                                                            <td>{operation?.transaction_date ? operation?.transaction_date : '-'}</td>
                                                                            <td>
                                                                                {
                                                                                    operation?.ubos.length > 0 ?
                                                                                        operation.ubos.map((ubo) => (
                                                                                            <div className="my-2">{ubo.email}</div>
                                                                                        ))
                                                                                        :
                                                                                        '-'
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    operation?.ubos.length > 0 ?
                                                                                        operation.ubos.map((ubo) => (
                                                                                            <div className="my-2">{showCommaSeperator(ubo.shares)} %</div>
                                                                                        ))
                                                                                        : '-'
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {
                                                                                    operation.comment ?
                                                                                        operation?.comment?.length > 40 ?
                                                                                            <>
                                                                                                <div className="position-relative user-image-in-table">
                                                                                                    <span >
                                                                                                        <>{`${operation?.comment.substring(0, 27)}....`}</>
                                                                                                    </span>
                                                                                                    {

                                                                                                        <>
                                                                                                            <div className="triangle-down"></div>
                                                                                                            <p className="hover-username" style={{ maxWidth: '100%', fontSize: '22px !important' }}>{operation?.comment}</p>
                                                                                                        </>

                                                                                                    }
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            operation.comment
                                                                                        :
                                                                                        '-'
                                                                                }


                                                                            </td>
                                                                        </tr>
                                                                    ))

                                                                }
                                                            </tbody>
                                                        </table>

                                                        {
                                                            operations?.data?.length === 0 &&
                                                            <>
                                                                <div className="w-100 text-center mt-4">
                                                                    {generalData?.shareholdersSectionSettings['empty-records']}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    operationsLastPage > 1 ?
                                                        <div className="col-12 text-center py-2 d-flex justify-content-center">
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={operationsCurrentPage}
                                                                totalCount={6 * operationsLastPage}
                                                                pageSize={6}
                                                                onPageChange={(e) => setOperationsCurrentPage(e)}
                                                            />
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        null
                }

                {
                    isShareOperationPopupOpen &&
                    <ShareOperationManageComponent
                        filters={{
                            date_from: filterDateFrom,
                            date_to: filterDateTo,
                            shareholderFilter: filterShareholder,
                        }}
                        operation={operation}
                        setIsShareOperationPopupOpen={setIsShareOperationPopupOpen}
                    />
                }

                {
                    //custom dates popup
                    customDatesPopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={() => { setCurrentFilterExpenses(allExpenseFilters()[2]); setCustomDatesPopup(false); setFilteDateFrom(new Date()); setFilteDateTo(new Date()) }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">Custom Dates</h1>
                                            </div>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={'Date'}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="dd/MM/yyyy"
                                                                onChange={onChangeDate}
                                                                startDate={filterDateFrom}
                                                                endDate={filterDateTo}
                                                                selectsRange
                                                                minDate={addYears(new Date(), -21)}
                                                                maxDate={addMonths(new Date(), 1)}
                                                                locale={localStorage.getItem("LmsLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="button" onClick={() => { setCustomDatesPopup(false); slug === 'transactions' ? setChangeWallet(changeWallet + 1) : setChangeExpenses(changeExpenses + 1) }} className="popup-button my-auto">Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    //delete license popup
                    deleteOperation?.id ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer" onClick={(e) => { setDeleteOperation(e); setErrorsReturned('') }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center mb-4">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className="text-start"><strong>{generalData?.shareholdersSectionSettings['operation-title']}</strong></h5>
                                                {deleteOperation?.id}
                                            </div>
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersSectionSettings['shareholder-name-title']}</strong></h5>
                                                {deleteOperation?.shareholder?.shareholder_name ? deleteOperation?.shareholder?.shareholder_name : '-'}
                                            </div>
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersSectionSettings['operation-type-title']}</strong></h5>
                                                {deleteOperation?.share_operation.title ? deleteOperation?.share_operation.title : '-'}
                                            </div>
                                            <div className="col-6 mb-4 border-bottom text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersSectionSettings['par-value-title']}</strong></h5>
                                                {deleteOperation?.share_value ? showCommaSeperator(deleteOperation?.share_value) : '-'}
                                            </div>
                                            <div className="col-12 mb-3  text-start">
                                                <h5 className=" text-start"><strong>{generalData?.shareholdersSectionSettings['shares-title']}</strong></h5>
                                                {deleteOperation?.number_of_shares ? showCommaSeperator(deleteOperation?.number_of_shares) : '-'}
                                            </div>
                                            {
                                                errorsReturned?.invalid_operation &&
                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.invalid_operation}</h5>
                                            }
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button
                                                    onClick={(e) => { handleDeleteOperation(e, deleteOperation) }}
                                                    type="button"
                                                    className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}
                                                </button>
                                                <button onClick={(e) => { setDeleteOperation(e); setErrorsReturned('') }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        :
                        null
                }
                <Api options={usersApiOptions} />
                <Api options={generalDataApiOptions} />
            </Layout>
        </>
    )
}