import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import { useNavigate } from 'react-router-dom';
import Api from "../components/Api";
import axios from "axios";
import { PasswordHidden, PasswordVisible } from '../components/Icons';

export default function SignIn() {
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [passwordVisible, setPasswordVisible] = useState(0);
    const [errorsReturned, setErrorsReturned] = useState({});
    const [password, setPassword] = useState('');
    const [generalData] = generalDataGlobal;
    const [, setAuthUser] = authUserGlobal;
    const [email, setEmail] = useState('');
    const history = useNavigate();

    //login request 
    const submitLogin = (e) => {
        e.preventDefault();
        initiateLogin();
    }

    function initiateLogin() {
        setGeneralDataApiOptions({
            url: 'login',
            method: 'post',
            data: {
                email: email,
                password: password,
            },
            callback: res => {
                localStorage.setItem('auth_token', res.data.token);
                axios.defaults.headers.common['Authorization'] = res.data.token;
                setAuthUser(res.data.user);
                history('/');
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6 full-screen-min light-bg-color d-flex align-items-center justify-content-center d-none d-md-flex">
                        <img className="logo-web" alt="Logo Web" src="https://hellotree-lms-demo.hellotree.dev/asset?path=images/logo.png" />
                    </div>
                    <div className="col-12 col-md-6 full-screen-min dark-blue-bg d-flex align-items-center justify-content-center py-4 py-md-0">
                        <div className="row w-100 justify-content-center">
                            <div className="col-12 d-flex d-md-none small-screens-auth-logo py-4 pt-md-0 justify-content-center">
                                <img className="logo-mobile" class="logo-web" alt="Logo Web" src="https://hellotree-lms-demo.hellotree.dev/asset?path=images/logo.png" />
                            </div>
                            <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
                                <h1 className="login-title white-text pb-4">{generalData?.authentication_pages_settings['login-title']}</h1>
                                <form className="w-100" onSubmit={submitLogin}>
                                    <div className="row w-100 m-0">
                                        <div className="col-12 pb-3">
                                            <input name="email" className={errorsReturned?.email ? 'auth-input w-100 border-red' : 'auth-input w-100'} placeholder={generalData?.authentication_pages_settings['email-placeholder']} onChange={e => setEmail(e.target.value)} value={email} />
                                            {
                                                errorsReturned?.email ?
                                                    <p className="m-0 errors-auth">{errorsReturned.email}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="col-12 pb-3 position-relative">
                                            <input type={passwordVisible ? 'text' : 'password'} name="password" className={errorsReturned?.password ? 'auth-input w-100 border-red' : 'auth-input w-100'} placeholder={generalData?.authentication_pages_settings['password-placeholder']} onChange={e => setPassword(e.target.value)} value={password} />
                                            <div className="pass-icon" onClick={() => passwordVisible === 0 ? setPasswordVisible(1) : setPasswordVisible(0)}>
                                                {
                                                    passwordVisible ?
                                                        <PasswordVisible />
                                                        :
                                                        <PasswordHidden />
                                                }
                                            </div>
                                            {
                                                errorsReturned?.password ?
                                                    <p className="m-0 errors-auth">{errorsReturned.password}</p>
                                                    :
                                                    null
                                            }
                                            {
                                                errorsReturned && !errorsReturned?.password && !errorsReturned?.email ?
                                                    <p className="m-0 errors-auth">{errorsReturned[0]}</p>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="col-12 d-flex justify-content-end">
                                            <Link to="/forgot-password" className="forgot-pass-text">{generalData?.authentication_pages_settings['forgot-password']}</Link>
                                        </div>
                                        <div className="col-12 pt-4 d-flex justify-content-center">
                                            <button className="auth-button">{generalData?.authentication_pages_settings['login-button']}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>

    )
}