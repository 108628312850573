import { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import Api from "./Api";
import { addYears, formatDate, formatDateTime, getIndex } from '../Helpers';

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import RelationsSelect from "./RelationsSelect";
import { CloseIcon } from "./Icons";
registerLocale('ar', ar)

export default function ReminderPopup(props) {
    const { handleClose, onSuccess, showClient } = props;
    const {
        addReminderGlobal,
        currentReminderGlobal,
        openAddReminderGlobal,
        currentReminderEditGlobal,
        generalDataGlobal,
        profileClientGlobal,
        profileUserGlobal,
        setActiveMatter,
        authUserGlobal: [authUser],
    } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [currentReminderEditGl, setCurrentReminderEditGl] = currentReminderEditGlobal;
    const [, setProfileClient] = profileClientGlobal;
    const [, setOpenAddReminder] = openAddReminderGlobal;
    const [ profileUser, setProfileUser] = profileUserGlobal;

    //get all notify types
    const notifyTypes = () => {
        const nots = generalData?.notify_before_types?.map(
            not => ({ value: not.id, label: not.title, slug: not.slug }))
        return nots;
    }

    //get all reminder types
    const reminderTypes = () => {
        const reminders = generalData?.reminder_types?.map(
            reminder => ({ value: reminder.id, label: reminder.title }))
        return reminders;
    }

    const [, setAddReminder] = addReminderGlobal;


    const [reminderType, setReminderType] = useState('');
    const [reminderName, setReminderName] = useState('');
    const [reminderDate, setReminderDate] = useState('');
    const [errorsReturned, setErrorsReturned] = useState({});
    const [, setCurrentReminder] = currentReminderGlobal;
    const [reminderSummary, setReminderSummary] = useState('');
    const [reminderNotifyBefore, setReminderNotifyBefore] = useState('');
    const [reminderNotifyBeforeType, setReminderNotifyBeforeType] = useState(notifyTypes()[getIndex('hour', notifyTypes(), 'slug')]);
    const [reminderUsers, setReminderUsers] = useState('');
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

    const [selectedRelation, setSelectedRelation] = useState();
    const [clientType,] = useState(currentReminderEditGl?.client?.client_type_id);



    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //custom select styles
    const customStyles = {
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A"
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: 'Poppins',
                fontWeight: '400',
                color: '#00366A',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                "&:hover": {
                    backgroundColor: "#f2f9fc"
                },
            };
        },
        singleValue: base => ({
            ...base,
            backgroundColor: "red"
        }),
        control: provided => ({
            ...provided,
            minHeight: 40,
            width: '100%',
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: '#393939' },
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "1px",
        }),
        input: (base, state) => ({
            ...base,
            color: '#00366A',
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "-8px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //get all relation users
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: singleOption?.name, image: singleOption?.full_path_picture }))
        return curren;
    }

    //get individual realtions
    const individualRelations = () => {
        const relOptions = authUser?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = authUser?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = authUser?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }


    // close reminder and reset states
    const closeAddReminder = (e) => {
        setAddReminder(false);
    }

    const resetForm = () => {
        setCurrentReminder('');
        setCurrentReminderEditGl('');
        setReminderDate('');
        setReminderName('');
        setReminderNotifyBefore('');
        setReminderNotifyBeforeType('');
        setReminderSummary('');
        setReminderType('');
        setReminderUsers('');
        setOpenAddReminder(false);
        setSelectedRelation('');
        if (props?.setCurrentReminderSnooze) {
            props.setCurrentReminderSnooze(null);
        }
        if (props?.setCurrentReminderEdit) {
            props.setCurrentReminderEdit(null);
        }
    }

    //submit reminder to backend
    const submitReminders = (e) => {
        e.preventDefault();
        e.stopPropagation();


        let fd = new FormData();

        fd.append('reminder_type_id', reminderType ? reminderType?.value : '');
        fd.append('name', reminderName ? reminderName : '');
        fd.append('date', reminderDate ? formatDateTime(reminderDate) : '');
        fd.append('notify_before', reminderNotifyBefore ? reminderNotifyBefore : '');
        fd.append('notify_before_type_id', reminderNotifyBeforeType ? reminderNotifyBeforeType?.value : '');
        fd.append('summary', reminderSummary ? reminderSummary : '');
        if (reminderUsers?.length > 0) {
            fd.append('remind_users', (reminderUsers?.length > 0 ? reminderUsers?.map((single, index) => (
                single.value
            )).toString() : ''));
        }

        if (currentReminderEditGl?.id) {
            fd.append('reminder_id', currentReminderEditGl?.id);
        }

        if (props.clientId) {
            fd.append('client_id', props?.clientId);
            fd.append('user_id', props?.clientId);
        } else if (props?.userId) {
            fd.append('user_id', props?.userId);
        } else if (props?.taskId) {
            fd.append('task_id', props?.taskId);
        }

        if (!props?.clientId && showClient) {
            fd.append('client_id', selectedRelation?.value?.split('-')[1] || '');
        }

        if (props?.returnUser) {
            fd.append('return_user', props.returnUser ? 1 : 0);
            fd.append('user_id',profileUser?.id);
        }

        if (props?.litigationId) {
            fd.append('litigation_id', props?.litigationId);
            fd.append('type', 'litigations');
        }

        if (props.selectedDate) {
            fd.append('reminder_date_selected', formatDate(props.selectedDate));
        }

        if(props?.returnDashboard){
            fd.append('return_dashboard',props?.returnDashboard);
        }

        if(props?.tasksType){
            fd.append('tasksType',props?.tasksType);
        }

        if(props?.returnEvents === 1){
            fd.append('return_events',1);
            fd.append('meetingCheckbox', props?.meetingCheckbox ? 1 : 0);
            fd.append('hearingCheckbox', props?.hearingCheckbox ? 1 : 0);
            fd.append('tasksCheckbox', props?.tasksCheckbox ? 1 : 0);
            fd.append('remindersCheckbox', props?.remindersCheckbox ? 1 : 0);
        }
        setGeneralDataApiOptions({
            url: 'add-edit-reminder',
            method: 'post',
            data: fd,
            callback: res => {
                if (onSuccess) {
                    onSuccess(res?.data);
                } else if (props.clientId) {
                    setProfileClient(res?.data);
                } else if (props?.userId) {
                    setProfileUser(res?.data);
                }
                if (props?.litigationId) {
                    setActiveMatter(res.data);
                }
                setCurrentReminderEditGl(null);
                closeAddReminder(e);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }


    //custom select styles
    const customStylesPopupCurr = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                // "&:hover": {
                //     backgroundColor: "#f2f9fc"
                // },
                margin: "auto",
                // margin: "0",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "80px",
            borderRadius: "7px",
            // border: "1px solid transparent",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            display: 'flex',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // margin: "0px",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle edit reminders by setting states with reminder data
    useEffect(() => {
        if (currentReminderEditGl) {
            setReminderName(currentReminderEditGl?.name);
            setReminderType(reminderTypes()[getIndex(currentReminderEditGl?.reminder_type_id, reminderTypes(), 'value')]);
            setReminderDate(new Date(currentReminderEditGl?.date.replace(/-/g, "/")));
            setReminderNotifyBefore(currentReminderEditGl?.notify_before);
            setReminderNotifyBeforeType(notifyTypes()[getIndex(currentReminderEditGl?.notify_before_type_id, notifyTypes(), 'value')]);
            setReminderSummary(currentReminderEditGl?.summary ? currentReminderEditGl?.summary : '');

            if (currentReminderEditGl?.client?.client_type_id === 1) {
                let text = 'client-' + currentReminderEditGl?.client?.id;

                setTimeout(() => {
                    setSelectedRelation(companyRelations()[getIndex(text, companyRelations(), 'value')]);
                }, 300)
            }
            if (currentReminderEditGl?.client?.client_type_id === 2) {
                let text = 'client-' + currentReminderEditGl?.client?.id;
                setTimeout(() => {
                    setSelectedRelation(individualRelations()[getIndex(text, individualRelations(), 'value')]);
                }, 300);
            }
            if (currentReminderEditGl?.client?.client_type_id === 3) {
                let text = 'client-' + currentReminderEditGl?.client?.id;
                setTimeout(() => {
                    setSelectedRelation(ngoRelations()[getIndex(text, ngoRelations(), 'value')]);
                }, 300);
            }

            if (currentReminderEditGl?.remind_users?.length > 0) {
                setReminderUsers(
                    currentReminderEditGl?.remind_users?.map(
                        singleUser => ({ value: singleUser.id, label: singleUser.name, image: singleUser?.full_path_picture })
                    )
                );
            }
        }
    }, [currentReminderEditGl]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleCloseClick = (e) => {
        resetForm();

        if (handleClose) {
            handleClose(e)
        } else {
            closeAddReminder(e)
        }
    }

    const Control = (props) => {
        return (
            <div className="bg-gray d-flex px-2 mb-2" {...props.innerProps}><img alt="icon" className="me-2" style={{ width: 30, height: 30, borderRadius: '50%' }} src={props?.data?.image || generalData?.fixed_settings?.full_path_no_profile_picture} />{props?.children}</div>
        )
    }

    const ValueContainer = (props) => {
        return (
            <div style={{ backgroundColor: '#F1F4FB' }} className="d-flex p-1 ms-1 mb-1"><img alt="icon" className="me-2" style={{ width: 30, height: 30, borderRadius: '50%' }} src={props?.data?.image || generalData?.fixed_settings?.full_path_no_profile_picture} />{props?.children}</div>
        )
    }


    return (
        <>
            <div id="errors-popup" className="error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                <div className="pop-up-api-box">
                    <CloseIcon className="cursor-pointer" onClick={handleCloseClick} />
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currentReminderEditGl ? generalData?.website_titles['edit-reminder'] : generalData?.website_titles['add-new-reminder']}</h1>
                                </div>
                                <form onSubmit={submitReminders}>
                                    <div className="col-12 pt-2 pb-4">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="position-relative text-start justify-content-start w-100">
                                                    <Select
                                                        classNamePrefix="popup-select"
                                                        className="popup-select w-100"
                                                        styles={customStylesPopup}
                                                        options={reminderTypes()}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        placeholder={generalData?.website_titles['reminder-type']}
                                                        isSearchable={false}
                                                        value={reminderType}
                                                        onChange={setReminderType} />
                                                    <input
                                                        type="text"
                                                        className='position-x-center'
                                                        style={{
                                                            opacity: 0,
                                                            width: 0,
                                                            height: 0,
                                                        }}
                                                        value={reminderType || ''}
                                                        required
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.reminder_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.reminder_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <div className="col-12 col-md-6 pt-2">
                                                <input required name="name" value={reminderName} onChange={(e) => setReminderName(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['name']} />
                                                {
                                                    errorsReturned?.name ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.name}</h5>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <DatePicker
                                                        className="popup-date add-user-inputs"
                                                        placeholderText={generalData?.website_titles['date']}
                                                        value={reminderDate ? reminderDate : ''}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        showTimeSelect
                                                        use12Hours={true}
                                                        dropdownMode="select"
                                                        selected={reminderDate}
                                                        dateFormat="d MMMM yyyy hh:mm a"
                                                        onChange={(date) => setReminderDate(date)}
                                                        maxDate={addYears(new Date(), 21)}
                                                        minDate={new Date()}
                                                        locale={localStorage.getItem("LmsLanguage")}
                                                        required
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.date ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <div className="col-12 col-md-6 mt-2 position-relative">
                                                <input required name="notify_before" value={reminderNotifyBefore} onChange={(e) => setReminderNotifyBefore(e.target.value)} className="add-user-inputs" placeholder={generalData?.website_titles['notify-before']} />
                                                <Select
                                                    classNamePrefix="curr-select"
                                                    className="curr-select curr-select-edited w-100"
                                                    styles={customStylesPopupCurr}
                                                    options={notifyTypes()}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    isSearchable={false}
                                                    value={reminderNotifyBeforeType}
                                                    onChange={setReminderNotifyBeforeType} />

                                                {
                                                    errorsReturned?.notify_before ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.notify_before}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {
                                                showClient &&
                                                <div className="col-12 col-md-6 pt-2">
                                                    <div className="postion-relative text-start justify-content-start w-100" >
                                                        <RelationsSelect
                                                            selectedRelation={selectedRelation}
                                                            setSelectedRelation={setSelectedRelation}
                                                            profile={authUser}
                                                            excludeMatters
                                                            // placeholder={selectedShareOperation && selectedShareOperation.slug != 'initial-subscription' && generalData?.shareholdersFixedTitles['to']}
                                                            defaultSelected={clientType ? Number(clientType) === 5 ? 'task' : (clientType === 1 ? 'company' : (clientType === 2 ? 'individual' : 'ngo')) : 'individual'}
                                                        // isDisabled={!!props.operation}
                                                        />

                                                    </div>
                                                    {
                                                        errorsReturned?.to_id ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.to_id}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            }
                                            <div className="col-12 pt-2 justify-content-start">
                                                <div className="w-100 h-100 position-relative">

                                                    <Select
                                                        classNamePrefix="select-multiple-users"
                                                        className="select-multiple-users w-100"
                                                        isClearable={false}
                                                        styles={customStyles}
                                                        isMulti={true}
                                                        placeholder={generalData?.website_titles['select-users']}
                                                        options={allUsers()}
                                                        value={reminderUsers}
                                                        onChange={setReminderUsers}
                                                        components={{ Option: Control, MultiValueContainer: ValueContainer }}

                                                    />

                                                    {
                                                        errorsReturned?.remind_users ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.remind_users}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </div>
                                            <div className="col-12 pt-2">
                                                <textarea type="text-area" name="summary" className="add-user-inputs" placeholder={generalData?.website_titles['summary']} value={reminderSummary} onChange={(e) => setReminderSummary(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{currentReminderEditGl ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}