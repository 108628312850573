import React from 'react'
import { useContext } from 'react';
import GlobalState from '../contexts/GlobalState';
import DatePicker from "react-datepicker";
import { addYears } from '../Helpers';
import { CloseIcon } from "./Icons";

export default function CustomSnoozePopupComponent({shortcutLayout, snoozeDate, setSnoozeDate, setCustomSnoozePopup, setCurrentReminderEdit, setCurrentReminderSnooze, setCurrentReminder, submitSnooze, setSnoozeType = ()=>{} }) {
    const {generalDataGlobal:[generalData]} = useContext(GlobalState);

    return (
        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
            <div className="pop-up-api-box">
                <CloseIcon className="cursor-pointer primary-color" onClick={(e) => {e.stopPropagation(); setCustomSnoozePopup(false); setSnoozeDate(''); setCurrentReminderEdit(null); setCurrentReminderSnooze(null); setSnoozeType(null); setCurrentReminder(null) }} />
                <div className="text-center ">
                    <div className="containter-fluid">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.website_titles['custom-date']}</h1>
                            </div>
                            <div className="col-12 pt-2 pb-4">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-6 pt-2">
                                        <div className={'postion-relative text-start justify-content-start w-100 '+(shortcutLayout === 1 && 'remove-customs')}>
                                            <DatePicker
                                                onClick={(e) => e.stopPropagation()}
                                                className={'popup-date add-user-inputs '}
                                                placeholderText={generalData?.website_titles['date']}
                                                value={snoozeDate ? snoozeDate : ''}
                                                showMonthDropdown
                                                showYearDropdown
                                                showTimeSelect
                                                dropdownMode="select"
                                                selected={snoozeDate ? snoozeDate : null}
                                                dateFormat="d MMMM yyyy HH:mm"
                                                onChange={(date) => setSnoozeDate(date)}
                                                maxDate={addYears(new Date(), 50)}
                                                minDate={new Date()}
                                                locale={localStorage.getItem("LmsLanguage")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center">
                                <button type="button" onClick={(e) => submitSnooze(e)} className="popup-button my-auto">{generalData?.website_titles['save']}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
