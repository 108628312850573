import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReminderPopup from './ReminderPopup';
import React, { useContext, useEffect, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import Api from './Api';
import ReminderCard from './ReminderCard';
import { formatDate } from '../Helpers';
import { CalendarNextLabel, CalendarPreviousLabel } from './Icons';
import { useSearchParams } from 'react-router-dom';

export default function UserReminders(props) {
    const { addReminderGlobal, profileUserGlobal, generalDataGlobal } = useContext(GlobalState);
    const [profileUser, setProfileUser] = profileUserGlobal;
    const [generalData,] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [addReminder, setAddReminder] = addReminderGlobal;
    const [currentReminderEdit, setCurrentReminderEdit] = useState(null);
    const [currentReminderSnooze, setCurrentReminderSnooze] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const marks = props.marks;

    const checkDateMarked = (value) => {
        if (marks) {
            return hilightedDate(formatDate(value), marks) >= 0 ? "position-relative highlight" : "2";
        }
        return "2";
    };

    const [selectedDate, setSelectedDate] = useState(() => {
        const urlDate = searchParams.get('date');
        return urlDate ? new Date(urlDate) : '';
    });

    const hilightedDate = (value, arr) => {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === value) {
                return i;
            }
        }
        return -1;
    };

    useEffect(() => {
        const urlDate = searchParams.get('date');
        setSelectedDate(urlDate ? new Date(urlDate) : '');

        setGeneralDataApiOptions({
            url: 'get-user-data',
            method: 'post',
            data: {
                user_id: props.userId,
                reminder_date_selected: urlDate ? urlDate : '',
            },
            callback: res => {
                setProfileUser(res.data);
            }
        });
    }, [searchParams, props.userId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Remove selectedDate from URL when it's falsy
        if (!selectedDate) {
            setSearchParams({ date: '' });
        }
    }, [selectedDate, setSearchParams]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid documents-div pt-3">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col d-flex flex-row">
                                <div className="d-flex align-items-center">
                                    <h1 className="m-0">{generalData?.website_titles['reminder']}</h1>
                                    <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={(e) => setAddReminder(true)} alt="add" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-12 col-md-5 col-xl-4 position-relative">
                        <Calendar
                            locale={localStorage.getItem('LmsLanguage')}
                            className="reminders-calendar pb-4"
                            formatWeekday={(locale, day) => formatDate(new Date())}
                            tileClassName={(e) => checkDateMarked(e?.date)}
                            onChange={(e) => {
                                const newDate = formatDate(e);
                                setSearchParams({ date: newDate });
                                setSelectedDate(new Date(newDate));
                            }}
                            value={selectedDate ?? new Date()}
                            prevLabel={<CalendarPreviousLabel />}
                            nextLabel={<CalendarNextLabel />}
                        />
                        <p className="calendar-reminders-legend color-red">{profileUser?.reminders?.length} Reminders</p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-8 pt-3 pt-md-0 pb-3">
                        {
                            !selectedDate &&
                            <h5 className='fw-700  mt-4 mb-2'>{generalData?.website_titles['today']}</h5>
                        }
                        {
                            profileUser?.reminders?.length > 0 ?
                                profileUser?.reminders?.map((singleRem, indRem) => (
                                    <ReminderCard
                                        returnUser={true}
                                        setCurrentReminderSnooze={setCurrentReminderSnooze}
                                        currentReminderSnooze={currentReminderSnooze}
                                        selectedDate={selectedDate}
                                        setCurrentReminderEdit={setCurrentReminderEdit}
                                        currentReminderEdit={currentReminderEdit}
                                        key={indRem}
                                        indRem={indRem}
                                        singleRem={singleRem}
                                        clientId={props.clientId}
                                        userId={props.userId}
                                        onActionSubmit={setProfileUser}
                                    />
                                ))
                                :
                                <p>{generalData?.website_titles['no-reminders-for-this-day']}</p>
                        }
                        {
                            !selectedDate &&
                            <>

                                <h5 className='fw-700 mt-4 mb-2'>{generalData?.website_titles['upcoming']}</h5>
                                {
                                    profileUser?.upcomingReminders?.length > 0 ?
                                        profileUser?.upcomingReminders?.map((singleRem, indRem) => (
                                            <ReminderCard
                                                returnUser={true}
                                                setCurrentReminderSnooze={setCurrentReminderSnooze}
                                                currentReminderSnooze={currentReminderSnooze}
                                                selectedDate={selectedDate}
                                                setCurrentReminderEdit={setCurrentReminderEdit}
                                                currentReminderEdit={currentReminderEdit}
                                                key={indRem}
                                                indRem={indRem}
                                                singleRem={singleRem}
                                                clientId={props.clientId}
                                                userId={props.userId}
                                                onActionSubmit={setProfileUser}
                                            />
                                        ))
                                        :
                                        <p>{generalData?.website_titles['no-reminders-for-this-day']}</p>
                                }
                            </>


                        }

                    </div>
                </div>
            </div>
            {
                addReminder ?
                    <ReminderPopup returnUser={1} selectedDate={selectedDate} marks={profileUser?.marks} userId={props.userId} />
                    :
                    null
            }
            <Api options={generalDataApiOptions} />
        </>
    )
}