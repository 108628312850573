import React, { useContext, useEffect, useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import GlobalState from '../contexts/GlobalState';
import { formatDate } from '../Helpers';
import MyCalendar from './CalendarComponent';
import Api from './Api';
const days = {
    en: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    ar: ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت']
};

function DashboardBigCalendar({ data, activeDay, setActiveDay, handleEditClick, handleDeleteClick, updateEventsData, calendarEvents }) {
    var daysInMonth;
    const { generalDataGlobal: [generalData], authUserGlobal: [authUser] } = useContext(GlobalState);

    // general states 
    const [apiOptions, ] = useState({});

    // manual days loop states
    const [currentDay, setCurrentDay] = useState(activeDay.getDay());
    const [currentDate, setCurrentDate] = useState(activeDay.getDate());
    const [currentMonth, setCurrentMonth] = useState(activeDay.getMonth());
    const [currentYear, setCurrentYear] = useState(activeDay.getFullYear());

    // calendar states 
    // const [calendarDate, setCalendarDate] = useState();

    function handlePrev() {

        if (Number(currentDay) === 0) {
            setCurrentDay(6)
        } else {
            setCurrentDay(currentDay - 1);
        }
        if (Number(currentDate) === 1) {
            daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Get the number of days in the current month

            setCurrentDate(daysInMonth);
            if (Number(currentMonth) === 0) {
                setCurrentMonth(11)
                setCurrentYear(currentYear - 1);
            } else {
                setCurrentMonth(currentMonth - 1);
            }
        } else {
            setCurrentDate(currentDate - 1);
        }
    }

    function handleNext() {
        daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Get the number of days in the current month

        if (Number(currentDay) === 6) {
            setCurrentDay(0);
        } else {
            setCurrentDay(currentDay + 1);
        }
        if (currentDate === daysInMonth) {
            setCurrentDate(1);
            if (Number(currentMonth) === 11) {
                setCurrentMonth(0)
                setCurrentYear(currentYear + 1)

            } else {
                setCurrentMonth(currentMonth + 1)
            }
        } else {
            setCurrentDate(currentDate + 1)

        }
    }

    const getFullDate = (month, date, year) => {
        return new Date((`${month + 1}-${date}-${year}`).replace(/-/g, "/"))
    }

    const handleDayClick = (count, type) => {
        if (type === 'prev') {
            daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Get the number of days in the current month

            setCurrentDay(
                currentDay + count < 0 ?
                    days[localStorage.getItem('LmsLanguage')]?.length + (currentDay + count)
                    :
                    currentDay + count
            );
            setCurrentDate(
                currentDate + count <= 0 ?
                    new Date(currentYear, currentMonth, 0).getDate() + (currentDate + count)
                    :
                    currentDate + count
            )
            if (currentDate + count <= 0) {
                if (Number(currentMonth) === 0) {
                    setCurrentMonth(11)
                    setCurrentYear(currentYear - 1);
                } else {
                    setCurrentMonth(currentMonth - 1);
                }
            }
        }

        if (type === 'next') {
            daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Get the number of days in the current month

            setCurrentDay(
                currentDay + count > 6 ?
                    currentDay + count - days[localStorage.getItem('LmsLanguage')]?.length
                    :
                    currentDay + count
            );
            setCurrentDate(
                currentDate + count > new Date(currentYear, currentMonth + 1, 0).getDate() ?
                    (currentDate + count) - new Date(currentYear, currentMonth + 1, 0).getDate()
                    :
                    currentDate + count
            )
            if (currentDate + count > daysInMonth) {
                if (Number(currentMonth) === 11) {
                    setCurrentMonth(0)
                    setCurrentYear(currentYear + 1)

                } else {
                    setCurrentMonth(currentMonth + 1)
                }
            }
        }
    }

    const EventsMarks = ({ date }) => {
        return (
            <div className='d-flex align-items-center w-100 justify-content-center mt-1' >
                {
                    data?.reminders?.includes(formatDate(date)) &&

                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '100%',
                            background: generalData?.eventsColor['reminder'],
                            marginLeft: 4

                        }}
                    ></div>
                }
                {
                    data?.meetings?.includes(formatDate(date)) &&
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '100%',
                            background: generalData?.eventsColor['meeting'],
                            marginLeft: 4

                        }}
                    ></div>
                }
                {
                    data?.tasks?.includes(formatDate(date)) &&
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '100%',
                            background: generalData?.eventsColor['task'],
                            marginLeft: 4

                        }}
                    ></div>

                }
                {
                    data?.hearings?.includes(formatDate(date)) &&
                    <div
                        style={{
                            width: 8,
                            height: 8,
                            borderRadius: '100%',
                            background: generalData?.eventsColor['hearing'],
                            marginLeft: 4

                        }}
                    ></div>

                }
            </div>
        )
    }

    const onNavigate = (date) => {
        updateEventsData(date);
        setActiveDay(date)
        // setCalendarDate(date);
    }

    useEffect(() => {
        onNavigate(getFullDate(currentMonth, currentDate, currentYear));
    }, [currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Api options={apiOptions} />
            <div className="calendar-header-month w-100 d-flex  bg-primary justify-content-between py-4 mb-4">
                <button className="btn text-white" onClick={handlePrev}>{'<'}</button>
                <div className='d-flex justify-content-around mx-3 flex-grow-1'>

                    {/* current day -3 */}
                    <div className="month-button cursor-pointer mx-2 d-flex align-items-center flex-1 justify-content-center"
                        onClick={() => {
                            handleDayClick(-3, 'prev');
                        }}
                    >
                        <div>
                            <p className='text-white text-center m-0'>
                                <span className='text-secondary'>
                                    {
                                        currentDay - 3 < 0 ?
                                            days[localStorage.getItem('LmsLanguage')][days[localStorage.getItem('LmsLanguage')]?.length + (currentDay - 3)]
                                            :
                                            days[localStorage.getItem('LmsLanguage')][currentDay - 3]
                                    }
                                </span>
                                <br />
                                <span className='fw-700'>
                                    {
                                        currentDate - 3 <= 0 ?
                                            new Date(currentYear, currentMonth, 0).getDate() + currentDate - 3
                                            :
                                            currentDate - 3
                                    }
                                </span>
                            </p>
                            <EventsMarks
                                date={
                                    getFullDate(currentMonth,
                                        currentDate - 3 <= 0 ?
                                            new Date(currentYear, currentMonth, 0).getDate() + currentDate - 3
                                            :
                                            currentDate - 3,
                                        currentYear)} />
                        </div>
                    </div>

                    {/* current day -2 */}
                    <div className="month-button cursor-pointer mx-2 d-flex align-items-center flex-1 justify-content-center"
                        onClick={() => { handleDayClick(-2, 'prev'); }}
                    >
                        <div>
                            <p className='text-white text-center m-0'>
                                <span className='text-secondary'>
                                    {
                                        currentDay - 2 < 0 ?
                                            days[localStorage.getItem('LmsLanguage')][days[localStorage.getItem('LmsLanguage')]?.length + (currentDay - 2)]
                                            :
                                            days[localStorage.getItem('LmsLanguage')][currentDay - 2]
                                    }
                                </span>
                                <br />
                                <span className='fw-700'>
                                    {
                                        currentDate - 2 <= 0 ?
                                            new Date(currentYear, currentMonth, 0).getDate() + currentDate - 2
                                            :
                                            currentDate - 2
                                    }
                                </span>
                            </p>
                            <EventsMarks
                                date={
                                    getFullDate(currentMonth,
                                        currentDate - 2 <= 0 ?
                                            new Date(currentYear, currentMonth, 0).getDate() + currentDate - 2
                                            :
                                            currentDate - 2,
                                        currentYear)} />
                        </div>
                    </div>

                    {/* current day -1 */}
                    <div className="month-button cursor-pointer mx-2 d-flex align-items-center flex-1 justify-content-center"
                        onClick={() => { handleDayClick(-1, 'prev'); }}
                    >
                        <div>
                            <p className='text-white text-center m-0'>
                                <span className='text-secondary'>
                                    {
                                        currentDay - 1 < 0 ?
                                            days[localStorage.getItem('LmsLanguage')][6]
                                            :
                                            days[localStorage.getItem('LmsLanguage')][currentDay - 1]
                                    }
                                </span>
                                <br />
                                <span className='fw-700'>
                                    {
                                        currentDate - 1 === 0 ?
                                            new Date(currentYear, currentMonth, 0).getDate()
                                            :
                                            currentDate - 1
                                    }
                                </span>
                            </p>
                            <EventsMarks date={getFullDate(currentMonth, currentDate - 1 === 0 ?
                                new Date(currentYear, currentMonth, 0).getDate()
                                :
                                currentDate - 1, currentYear)} />
                        </div>
                    </div>

                    {/* current day */}
                    <div className="month-button active p-3 mx-2 text-center  flex-1">
                        <p className='m-0'>
                            <span className='text-secondary'>
                                {days[localStorage.getItem('LmsLanguage')][currentDay]}
                            </span>
                            <br />
                            <span className='fw-700'>
                                {currentDate}
                            </span>
                        </p>
                        {/* <EventsMarks date={getFullDate(currentMonth, currentDate, currentYear)} /> */}

                        {/* <p>{`${getFullDate(currentMonth, currentDate, currentYear)}`}</p> */}
                    </div>

                    {/* current day  +1*/}
                    <div className="month-button cursor-pointer mx-2 d-flex align-items-center flex-1 justify-content-center"
                        onClick={() => { handleDayClick(1, 'next') }}
                    >
                        <div>
                            <p className='text-white text-center m-0'>
                                <span className='text-secondary'>
                                    {
                                        Number(currentDay) === 6 ?
                                            days[localStorage.getItem('LmsLanguage')][0]
                                            :
                                            days[localStorage.getItem('LmsLanguage')][currentDay + 1]
                                    }
                                </span>
                                <br />
                                <span className='fw-700'>
                                    {
                                        currentDate === new Date(currentYear, currentMonth + 1, 0).getDate() ?
                                            1
                                            :
                                            currentDate + 1
                                    }
                                </span>
                                <br />
                            </p>
                            <EventsMarks date={getFullDate(currentMonth,
                                currentDate === new Date(currentYear, currentMonth + 1, 0).getDate() ?
                                    1
                                    :
                                    currentDate + 1, currentYear)} />
                        </div>
                    </div>

                    {/* currnet day +2  */}
                    <div className="month-button cursor-pointer mx-2 d-flex align-items-center flex-1 justify-content-center"
                        onClick={() => { handleDayClick(2, 'next') }}
                    >
                        <div>
                            <p className='text-white text-center m-0'>
                                <span className='text-secondary'>
                                    {
                                        currentDay + 2 > 6 ?
                                            days[localStorage.getItem('LmsLanguage')][currentDay + 2 - days[localStorage.getItem('LmsLanguage')]?.length]
                                            :
                                            days[localStorage.getItem('LmsLanguage')][currentDay + 2]
                                    }
                                </span>
                                <br />
                                <span className='fw-700'>
                                    {
                                        currentDate + 2 > new Date(currentYear, currentMonth + 1, 0).getDate() ?
                                            currentDate + 2 - new Date(currentYear, currentMonth + 1, 0).getDate()
                                            :
                                            currentDate + 2
                                    }
                                </span>
                                <br />
                            </p>
                            <EventsMarks date={getFullDate(currentMonth,
                                currentDate + 2 > new Date(currentYear, currentMonth + 1, 0).getDate() ?
                                    currentDate + 2 - new Date(currentYear, currentMonth + 1, 0).getDate()
                                    :
                                    currentDate + 2, currentYear)} />
                        </div>
                    </div>

                    {/* current day + 3 */}
                    <div className="month-button cursor-pointer mx-2 d-flex align-items-center flex-1 justify-content-center"
                        onClick={() => { handleDayClick(3, 'next') }}
                    >
                        <div>
                            <p className='text-white text-center m-0'>
                                <span className='text-secondary'>
                                    {
                                        currentDay + 3 > 6 ?
                                            days[localStorage.getItem('LmsLanguage')][currentDay + 3 - days[localStorage.getItem('LmsLanguage')]?.length]
                                            :
                                            days[localStorage.getItem('LmsLanguage')][currentDay + 3]
                                    }
                                </span>
                                <br />
                                <span className='fw-700'>
                                    {
                                        currentDate + 3 > new Date(currentYear, currentMonth + 1, 0).getDate() ?
                                            currentDate + 3 - new Date(currentYear, currentMonth + 1, 0).getDate()
                                            :
                                            currentDate + 3
                                    }
                                </span>
                                <br />
                            </p>
                            <EventsMarks date={getFullDate(currentMonth,
                                currentDate + 3 > new Date(currentYear, currentMonth + 1, 0).getDate() ?
                                    currentDate + 3 - new Date(currentYear, currentMonth + 1, 0).getDate()
                                    :
                                    currentDate + 3, currentYear)} />
                        </div>
                    </div>

                </div>
                <button className="btn text-white text-center" onClick={handleNext}>{'>'}</button>
            </div>
            <div className='w-100 overflow-auto' style={{ height: '720px' }}>

                <MyCalendar
                    events={calendarEvents}
                    // onNavigate={handleClendarNavigate}
                    editFunction={(e, event) => { handleEditClick(e, event) }}
                    deleteFunction={(e, event) => { handleDeleteClick(e, event) }}
                    authUser={authUser}
                    activeDay={activeDay}
                    activeDate={getFullDate(currentMonth, currentDate, currentYear)}
                    // activeView={'day'}
                    defaultDate={getFullDate(currentMonth, currentDate, currentYear)}
                    defaultView={'day'}
                    disableHeader={true}
                    customTopSpacing={window?.innerWidth >= 992 ? -500 : -1500}
                />
            </div>

        </>
    );
}

export default DashboardBigCalendar;