import { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import Api from "./Api";
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import DatePicker from 'react-datepicker';
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { addYears, formatDate, formatDateTime, getOptions, getIndex } from "../Helpers";
import { AttachIcon, BackIcon, CloseIcon } from "./Icons";
registerLocale('ar', ar)

export default function HearingPopupComponent(props) {
    const {
        generalDataGlobal,
        activeMatter,
        setActiveMatter,
        setHearings,
        allLitigations,
    } = useContext(GlobalState);

    //custom select styles
    const customStyles = {
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A"
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: 'Poppins',
                fontWeight: '400',
                color: '#00366A',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                "&:hover": {
                    backgroundColor: "#f2f9fc"
                },
            };
        },
        singleValue: base => ({
            ...base,
            backgroundColor: "red"
        }),
        control: provided => ({
            ...provided,
            minHeight: 40,
            width: '100%',
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: '#393939' },
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "1px",
        }),
        input: (base, state) => ({
            ...base,
            color: '#00366A',
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "-8px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    const {
        setAddMatterPopup,
        addMatterPopup,
        editHearing,
        setEditHearing,
        activeLitigation,
        page,
        sort,
        direction,
        setTotalMatters,
        setTotalLitigations,
        setTotalHearings,
        returnTable,
        onSuccess,
        returnRecord = 0,
    } = props;

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalData] = generalDataGlobal;

    const [selectedHearingType, setSelectedHearingType] = useState();
    const [selectedHearingDate, setSelectedHearingDate] = useState()

    const [postponementDate, setPostponementDate] = useState(editHearing?.postponement_date ? new Date(editHearing?.postponement_date.replace(/-/g, "/")) : null);
    const [postponementReason, setPostponementReason] = useState(editHearing?.postponement_reason || '');

    const [hearingDescription, setHearingDescription] = useState(editHearing?.description || '');
    const [hearingSummary, setHearingSummary] = useState(editHearing?.hearing_summary || '');
    const [selectedStageCondition, setSelectedStageCondition] = useState();
    const [verdictDate, setVerdictDate] = useState(editHearing?.verdict_date ? new Date(editHearing?.verdict_date.replace(/-/g, "/")) : null);
    const [selectedHearingResult, setSelectedHearingResult] = useState();
    const [resultReason, setResultReason] = useState(editHearing?.result_reason || '');
    const [resultDescription, setResultDescription] = useState(editHearing?.result_description || '');
    const [clientSummary, setClientSummary] = useState(editHearing?.client_summary || '');

    const [formData, setFormData] = useState({});

    const [errorsReturned, setErrorsReturned] = useState({});

    const [selectedAssignees, setSelectedAssignees] = useState([])

    const [showMore, setShowMore] = useState(false);

    const [selectedLitigation, setSelectedLitigation] = useState();
    const [relatedStages, setRelatedStages] = useState();
    const [selectedStage, setSelectedStage] = useState();


    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();

    const [verdictDocument, setVerdictDocument] = useState();
    const [verdictDocumentLink, setVerdictDocumentLink] = useState();

    const [fileName, setFileName] = useState();
    const [verdictFileName, setVerdictFileName] = useState();
    //handle document upload
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0]);
            setDocumentLink(file);
        }
    }
    const verdictDocumentHandler = (files) => {
        if (files.length > 0) {
            setVerdictDocument(files);
            let file = URL.createObjectURL(files[0]);
            setVerdictDocumentLink(file);
        }
    }

    const initClientOpponent = (keys) => {
        let emptyObject = {};
        keys?.map(key => (

            emptyObject[key] = [
                { client: '', description: '' }
            ]
        ))
        setFormData({ ...formData, ...emptyObject })
    }

    useEffect(() => {
        let keys = ['clients', 'opponents']
        initClientOpponent(keys)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //set states when current client changes
    useEffect(() => {
        if (editHearing && editHearing?.id) {

            setSelectedLitigation(getOptions(allLitigations)[getIndex(editHearing?.litigation_id, getOptions(allLitigations), 'value')])
            setSelectedStage({ slug: editHearing?.stage?.stage?.slug, label: editHearing?.stage?.stage?.title, value: editHearing?.stage?.id });

            setRelatedStages(getRelatedStagesOption(getOptions(allLitigations)[getIndex(editHearing?.litigation_id, getOptions(allLitigations), 'value')]?.stages));
            setSelectedHearingType(getOptions(generalData?.hearingTypes)[getIndex(editHearing?.hearing_type_id, getOptions(generalData?.hearingTypes), 'value')])
            setSelectedHearingDate(editHearing?.hearing_date ? new Date(editHearing?.hearing_date.replace(/-/g, "/")) : new Date())
            setSelectedStageCondition(getOptions(generalData?.stageConditions)[getIndex(editHearing?.stage_condition_id, getOptions(generalData?.stageConditions), 'value')])
            setSelectedHearingResult(getOptions(generalData?.hearingResults)[getIndex(editHearing?.hearing_result_id, getOptions(generalData?.hearingResults), 'value')])
            setDocumentLink(editHearing?.file || '')
            setFileName(editHearing?.uploaded_files?.file_name || '')
            setVerdictDocumentLink(editHearing?.verdict_file || '')
            setVerdictFileName(editHearing?.verdict_files?.file_name || '')
            if (editHearing?.assignees?.length > 0) {
                setSelectedAssignees(
                    editHearing?.assignees?.map(
                        singleUser => ({ value: singleUser.id, label: singleUser.name, image: singleUser?.full_path_picture })
                    )
                );
            }
        }
        if (addMatterPopup?.stage) {
            setSelectedStage({ slug: addMatterPopup?.stage?.stage?.slug, label: addMatterPopup?.stage?.stage?.title, value: addMatterPopup?.stage?.id });
        }
        setShowMore(addMatterPopup?.showMore);
    }, [editHearing]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (activeLitigation) {
            setSelectedLitigation(getOptions(allLitigations)[getIndex(activeLitigation, getOptions(allLitigations), 'value')])
        }
    }, [activeLitigation]);// eslint-disable-line react-hooks/exhaustive-deps


    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //close add client and reset states
    const resetForm = (e) => {
        setSelectedLitigation({});
        setSelectedHearingType({});
        setHearingDescription('');
        setHearingSummary('');
        setSelectedAssignees();
    }

    //submit client to backend
    const submitHearing = (e) => {
        e.preventDefault();
        let fd = new FormData();
        fd.append('litigation_id', selectedLitigation?.value || '')
        fd.append('activeLitigation', activeMatter?.id || '');
        fd.append('hearing_id', editHearing?.id || '');
        fd.append('hearing_type_id', selectedHearingType ? selectedHearingType.value : '');
        fd.append('hearing_date', selectedHearingDate ? formatDateTime(selectedHearingDate) : '');

        if (selectedAssignees?.length > 0) {
            fd.append('assignees', (selectedAssignees?.length > 0 ? selectedAssignees?.map((single, index) => (
                single.value
            )).toString() : ''));
        }
        fd.append('postponement_date', postponementDate ? formatDateTime(postponementDate) : '');
        fd.append('postponement_reason', postponementReason || '');
        fd.append('description', hearingDescription || '');
        fd.append('hearing_summary', hearingSummary || '');
        fd.append('client_summary', clientSummary || '');

        fd.append('stage_condition_id', selectedStageCondition?.value || '');
        fd.append('verdict_date', verdictDate ? formatDate(verdictDate) : '');
        fd.append('hearing_result_id', selectedHearingResult?.value || '');
        fd.append('result_reason', resultReason || '');
        fd.append('result_description', resultDescription || '');

        fd.append('type', props?.type || 'hearings');

        fd.append('force', 1);
        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 3);
        fd.append('file_id', editHearing?.uploaded_files_id || '');
        fd.append('folder_name', 'Hearings');
        fd.append('from_home', 1);
        fd.append('file_name', fileName ? fileName : '');
        fd.append('verdict_file_name', verdictFileName || '')
        fd.append('verdict_file_id', editHearing?.verdict_file_id || '');

        if (addMatterPopup?.action === 'copy') {
            fd.append('action', 'copy');
        }

        if (document) {
            fd.append('file', document[0]);
        }
        if (verdictDocument) {
            fd.append('verdict_file', verdictDocument[0])
        }

        if(props?.returnDashboard && props?.returnDashboard === 1){
            fd.append('return_dashboard',1);
        }
        if(props?.tasksType){
            fd.append('tasksType',props?.tasksType);
        }

        fd.append('sort', sort || '');
        fd.append('direction', direction || '');
        fd.append('page', page || '')
        fd.append('stage_id', selectedStage?.value || '');
        fd.append('return_table', returnTable || addMatterPopup?.returnIndex);
        fd.append('returnRecord', returnRecord);

        if(props?.returnEvents === 1){
            fd.append('return_events',1);
            fd.append('meetingCheckbox', props?.meetingCheckbox ? 1 : 0);
            fd.append('hearingCheckbox', props?.hearingCheckbox ? 1 : 0);
            fd.append('tasksCheckbox', props?.tasksCheckbox ? 1 : 0);
            fd.append('remindersCheckbox', props?.remindersCheckbox ? 1 : 0);
        }

        setGeneralDataApiOptions({
            url: 'add-edit-hearing',
            method: 'post',
            data: fd,
            callback: res => {
                if (onSuccess) {
                    onSuccess(res);
                } else {

                    if (addMatterPopup?.returnIndex) {
                        setHearings(res?.data?.hearings);
                        setTotalMatters(res?.data?.total['hearings']?.length + res?.data?.total['litigations']?.length)
                        // setCurrentPage(data.litigations.current_page);
                        setTotalLitigations(res?.data?.total['litigations']?.length);
                        setTotalHearings(res?.data?.total['hearings'].length);
                    } else {
                        setActiveMatter(res?.data)
                    }
                }

                resetForm();
                setAddMatterPopup(false);
                setEditHearing(null)
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }


    //get users for relations
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: singleOption?.name, image: singleOption?.full_path_picture }))
        return curren;
    }


    useEffect(() => {
        setRelatedStages(() => getRelatedStagesOption(selectedLitigation?.additionalField));
    }, [selectedLitigation]);// eslint-disable-line react-hooks/exhaustive-deps


    const getRelatedStagesOption = (arr) => {
        let relOptions = arr?.map((single) => (
            {
                slug: single?.stage?.slug,
                value: single?.id,
                label: single?.stage?.title,
            }
        ))
        return relOptions
    }
    useEffect(() => {
        if (!!selectedLitigation) {
            setSelectedStage(editHearing?.stage_id ? relatedStages[getIndex(editHearing?.stage_id, relatedStages, 'value')] : null)
        }
    }, [relatedStages]);// eslint-disable-line react-hooks/exhaustive-deps

    const Control = (props) => {
        return (
            <div className="bg-gray d-flex px-2 mb-2" {...props.innerProps}><img alt="user" className="me-2" style={{ width: 30, height: 30, borderRadius: '50%' }} src={props?.data?.image || generalData?.fixed_settings?.full_path_no_profile_picture} />{props?.children}</div>
        )
    }

    const ValueContainer = (props) => {
        return (
            <div style={{ backgroundColor: '#F1F4FB' }} className="d-flex p-1 ms-1 mb-1"><img alt="user" className="me-2" style={{ width: 30, height: 30, borderRadius: '50%' }} src={props?.data?.image || generalData?.fixed_settings?.full_path_no_profile_picture} />{props?.children}</div>
        )
    }

    useEffect(() => {
        setErrorsReturned({ ...errorsReturned, date: '' })
    }, [postponementDate]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in" >
                <div className="pop-up-api-box" style={{ maxHeight: 540, overflow: 'auto' }}>
                    <div className="d-flex">
                        <CloseIcon className="cursor-pointer" onClick={(e) => { setAddMatterPopup({ open: false, type: '' }); resetForm(); setEditHearing(null) }} />
                        {
                            showMore &&
                            <button className="btn" type="button" onClick={() => setShowMore(false)}>
                                <BackIcon className="start-0 ms-3 back-btn position-relative" />
                            </button>
                        }

                    </div>
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    {
                                        showMore ?
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{editHearing?.id ? generalData?.matterSectionsSettings['edit-record'].replace('%record%', props?.popupTitle) : generalData?.matterSectionsSettings['add-record']?.replace('%record%', props?.popupTitle)}</h1>
                                            :
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{editHearing?.id ? generalData?.matterSectionsSettings['edit-record'].replace('%record%', props?.popupTitle) : generalData?.matterSectionsSettings['add-record']?.replace('%record%', props?.popupTitle)}</h1>
                                    }
                                </div>
                                <form onSubmit={submitHearing}>

                                    <div className="col-12 pt-2 pb-4">
                                        {
                                            !showMore &&
                                            <>
                                                <div className="row">
                                                    <div className="col-12 col-md-6 pt-2">

                                                        <Select
                                                            classNamePrefix="popup-select"
                                                            className="popup-select w-100"
                                                            styles={customStylesPopup}
                                                            options={getOptions(allLitigations, "slug", "id", "title", 'stages')}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.litigationFixedTitles['litigation-title']}
                                                            isSearchable={false}
                                                            value={selectedLitigation ? selectedLitigation : null}
                                                            onChange={setSelectedLitigation}
                                                            isDisabled={(!!editHearing || !!activeLitigation) && addMatterPopup?.action !== 'copy'}
                                                        />

                                                        {
                                                            errorsReturned?.litigation_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.litigation_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">

                                                        <Select
                                                            classNamePrefix="popup-select"
                                                            className="popup-select w-100"
                                                            styles={customStylesPopup}
                                                            options={relatedStages}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.litigationFixedTitles['stage']}
                                                            isSearchable={false}
                                                            value={selectedStage ? selectedStage : null}
                                                            onChange={setSelectedStage}
                                                            isDisabled={(!!editHearing || !!activeLitigation) && addMatterPopup?.action !== 'copy'}
                                                        />

                                                        {
                                                            errorsReturned?.name ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.name}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <>
                                                        <div className="col-12 col-md-6 pt-2 position-relative" style={{ zIndex: 9 }}>
                                                            <input
                                                                hidden
                                                                id='upload-operation-file'
                                                                type="file"
                                                                onChange={(e) => { documentHandler(e.target.files) }}
                                                            />
                                                            <label
                                                                className="pop-upload-file position-relative"
                                                                htmlFor="upload-operation-file"
                                                            >
                                                                {documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}
                                                            </label>

                                                            <AttachIcon />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 position-relative" style={{ zIndex: 9 }}>
                                                            <input
                                                                className='add-user-inputs'
                                                                id='file_name'
                                                                type="text"
                                                                placeholder={generalData?.website_titles['file-name']}
                                                                onChange={(e) => { setFileName(e.target.value) }}
                                                                value={fileName}
                                                            />
                                                            {
                                                                errorsReturned?.file_name ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_name}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </>


                                                    <div className="col-12 col-md-6 pt-2">
                                                        <DatePicker
                                                            className="popup-date add-user-inputs"
                                                            placeholderText={generalData?.website_titles['date']}
                                                            value={selectedHearingDate ? selectedHearingDate : ''}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            showTimeSelect
                                                            use12Hours={true}
                                                            dropdownMode="select"
                                                            selected={selectedHearingDate}
                                                            dateFormat="d MMMM yyyy hh:mm a"
                                                            onChange={(date) => setSelectedHearingDate(date)}
                                                            maxDate={addYears(new Date(), 50)}
                                                            minDate={addYears(new Date(), -20)}
                                                            locale={localStorage.getItem("LmsLanguage")}
                                                            required
                                                            readOnly={new Date(editHearing?.hearing_date.replace(/-/g, "/")) < new Date()}
                                                        />
                                                        {
                                                            errorsReturned?.hearing_date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.hearing_date}</h5>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errorsReturned?.date && !postponementDate ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getOptions(generalData?.hearingTypes)}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.hearingFixedTitles['hearing-type']}
                                                                    isSearchable={false}
                                                                    value={selectedHearingType}
                                                                    onChange={setSelectedHearingType} />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                    </>

                                                    <div className="col-12 col-md-12 pt-2">
                                                        <Select
                                                            classNamePrefix="select-multiple-users"
                                                            className="select-multiple-users w-100"
                                                            isClearable={false}
                                                            styles={customStyles}
                                                            isMulti={true}
                                                            placeholder={generalData?.mattersCommonTitles['assignees']}
                                                            options={allUsers()}
                                                            value={selectedAssignees}
                                                            onChange={setSelectedAssignees}
                                                            components={{ Option: Control, MultiValueContainer: ValueContainer }}
                                                        />
                                                        {
                                                            errorsReturned?.remind_users ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.remind_users}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div className="col-12 col-md-6 pt-2">
                                                        <DatePicker
                                                            className="popup-date add-user-inputs"
                                                            placeholderText={generalData?.hearingFixedTitles['postponement-date']}
                                                            value={postponementDate ? postponementDate : ''}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            use12Hours={true}
                                                            dropdownMode="select"
                                                            selected={postponementDate}
                                                            showTimeSelect
                                                            dateFormat="d MMMM yyyy hh:mm a"
                                                            onChange={setPostponementDate}
                                                            maxDate={addYears(new Date(), 50)}
                                                            minDate={new Date()}
                                                            locale={localStorage.getItem("LmsLanguage")}
                                                        />
                                                        {
                                                            errorsReturned?.date && postponementDate ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input
                                                            name="postponementReason"
                                                            value={postponementReason}
                                                            onChange={(e) => setPostponementReason(e.target.value)}
                                                            className="add-user-inputs"
                                                            placeholder={generalData?.hearingFixedTitles['postponement-reason']}
                                                        />
                                                        {
                                                            errorsReturned?.name ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.name}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.hearingFixedTitles['description']} value={hearingDescription} onChange={(e) => setHearingDescription(e.target.value)}></textarea>
                                                        {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errorsReturned?.board_exists ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.hearingFixedTitles['assignee-summary']} value={hearingSummary} onChange={(e) => { setHearingSummary(e.target.value) }}></textarea>
                                                        {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errorsReturned?.board_exists ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea
                                                            type="text-area"
                                                            name="description"
                                                            className="add-user-inputs"
                                                            placeholder={generalData?.hearingFixedTitles['client-summary']}
                                                            value={clientSummary}
                                                            onChange={(e) => { setClientSummary(e.target.value) }}
                                                        >
                                                        </textarea>
                                                        {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errorsReturned?.board_exists ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-auto text-start">
                                                    {
                                                        !editHearing?.result_description &&
                                                        <span>{generalData?.hearingFixedTitles['is-there-a-verdict']}</span>
                                                    }
                                                    <button className="btn font-sm" type="button" onClick={() => setShowMore(true)}>{editHearing?.result_description ? generalData?.hearingFixedTitles['view-judgment'] : generalData?.hearingFixedTitles['show-more']}</button>
                                                </div>
                                            </>
                                        }
                                        {
                                            showMore &&
                                            <>
                                                <div className="row">

                                                    <>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getOptions(generalData?.stageConditions)}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.hearingFixedTitles['stage-condition']}
                                                                    isSearchable={false}
                                                                    value={selectedStageCondition}
                                                                    onChange={setSelectedStageCondition}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                        </div>
                                                    </>
                                                    <>
                                                        <div className="col-12 col-md-6 pt-2 position-relative" style={{ zIndex: 9 }}>
                                                            <input
                                                                hidden
                                                                id='upload-operation-file'
                                                                type="file"
                                                                onChange={(e) => { verdictDocumentHandler(e.target.files) }}
                                                            />
                                                            <label
                                                                className="pop-upload-file position-relative"
                                                                htmlFor="upload-operation-file"
                                                            >
                                                                {verdictDocumentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}
                                                            </label>

                                                            <AttachIcon />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 position-relative" style={{ zIndex: 9 }}>
                                                            <input
                                                                className='add-user-inputs'
                                                                id='file_name'
                                                                type="text"
                                                                placeholder={generalData?.website_titles['file-name']}
                                                                onChange={(e) => { setVerdictFileName(e.target.value) }}
                                                                value={verdictFileName}
                                                            />
                                                            {
                                                                errorsReturned?.file_name ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_name}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={generalData?.hearingFixedTitles['verdict-date']}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                dateFormat="dd/MM/yyyy"
                                                                minDate={addYears(new Date(), -10)}
                                                                maxDate={addYears(new Date(), 50)}
                                                                isClearable={true}
                                                                selected={verdictDate}
                                                                value={verdictDate}
                                                                onChange={setVerdictDate}
                                                                locale={localStorage.getItem("LmsLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.legal_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStylesPopup}
                                                                    options={getOptions(generalData?.hearingResults)}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.hearingFixedTitles['hearing-result']}
                                                                    isSearchable={false}
                                                                    value={selectedHearingResult}
                                                                    onChange={setSelectedHearingResult} />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </>


                                                    <div className="col-12 col-md-12 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <input
                                                                name="result-reason"
                                                                value={resultReason}
                                                                onChange={(e) => setResultReason(e.target.value)}
                                                                className="add-user-inputs"
                                                                placeholder={generalData?.hearingFixedTitles['result-reason']}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.legal_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>


                                                    <div className="col-12 pt-2">
                                                        <textarea
                                                            type="text-area"
                                                            name="description"
                                                            className="add-user-inputs"
                                                            placeholder={generalData?.hearingFixedTitles['result-description']}
                                                            value={resultDescription}
                                                            onChange={(e) => { setResultDescription(e.target.value) }}
                                                        >
                                                        </textarea>
                                                        {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errorsReturned?.board_exists ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>


                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{editHearing?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}