import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { useParams, useLocation } from "react-router";
import React from 'react';
import Api from "./Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'swiper/css';
import Pagination from "./Pagination";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { CalculatorsIcon, CloseIcon, WalletIcon, ThreeDotsMenu, RedArrowRight, GreenArrowLeft, AttachIcon } from "./Icons";
import { getIndex, formatDate } from '../Helpers'

registerLocale('ar', ar)

ChartJS.register(ArcElement, Tooltip, Legend);

export default function LitigationCosts(props) {

    const location = useLocation();
    const [costType, setCostType] = useState(1);
    const [open, setOpen] = useState(0);
    const {
        generalDataGlobal,
        authUserGlobal,
        profileClientGlobal,
        activeMatter,
    } = useContext(GlobalState);
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [expensesCall, setExpensesCall] = useState({});
    let { slug, user_id, matter_id } = useParams();
    const [generalData] = generalDataGlobal;
    const [authUser,] = authUserGlobal;
    const [usersApiOptions,] = useState({});
    const [profileClient,] = profileClientGlobal;
    const [errorsReturned, setErrorsReturned] = useState({});
    const [costCurrency, setCostCurrency] = useState('usd');
    const [expenses, setExpenses] = useState({
        'total': 'x',
    });

    //get all users for select
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: <div><img className="select-image me-1" src={singleOption?.picture ? singleOption?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt={singleOption?.name} />{singleOption?.name}</div> }))
        return curren;
    }

    //to redirect between tabs
    useEffect(() => {
        if (location?.search) {
            if (location?.search === '?transactions') {
                setCostType(2);
                setOpen(1);
            } else {
                setOpen(1);
            }
        } else {
            setCostType(1);
            setOpen(1);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const [wallet, setWallet] = useState({
        "total": 0,
        "salary": 0,
        "other": 0,
        "bonuses": 0,
    });

    const [chartData, setChartData] = useState({});
    /* expenses start */
    const [addExpenses, setAddExpenses] = useState(false);
    const [editExpense, setEditExpense] = useState(null);
    const [currenEditExpense, setCurrenEditExpense] = useState('');
    const [addExpensesType, setAddExpensesType] = useState('');
    const [addExpensesAmount, setAddExpensesAmount] = useState('');
    const [addExpensesCurrency, setAddExpensesCurrency] = useState('');
    const [addExpensesDate, setAddExpensesDate] = useState(new Date());
    const [addExpensesNote, setAddExpensesNote] = useState('');
    const [addExpensesBillable, setAddExpensesBillable] = useState(0);
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState('');
    const [, setExpensesLinks] = useState([]);
    const [expensesCurrentPage, setExpensesCurrentPage] = useState(1);
    const [expensesLastPage, setExpensesLastPage] = useState(1);
    const [expensesChartData, setExpensesChartData] = useState([0, 0, 0, 0]);
    const [filterDateFrom, setFilteDateFrom] = useState();
    const [filterDateTo, setFilteDateTo] = useState();

    //get all expenses filters
    const allExpenseFilters = () => {
        const expFilter = generalData?.expense_filters?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expFilter;
    }
    const [currentFilterExpenses, setCurrentFilterExpenses] = useState(allExpenseFilters()[0]);
    const [changeExpenses, setChangeExpenses] = useState(0);
    const [customDatesPopup, setCustomDatesPopup] = useState(false);
    /* expenses end */

    //get all transaction types
    const allTransactionTypes = () => {
        const transactionType = generalData?.transaction_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return transactionType;
    }

    /* transactions start */
    const [addTransaction, setAddTransaction] = useState(false);
    const [openEditTransaction, setOpenEditTransaction] = useState(null);
    const [transactionType, setTransactionType] = useState(allTransactionTypes()[getIndex(5, allTransactionTypes(), 'value')]);
    const [transactionCurrency, setTransactionCurrency] = useState('');
    const [transactionTo, setTransactionTo] = useState(allUsers()[getIndex(Number(authUser?.id), allUsers(), 'value')]);
    const [currenEditTransaction, setCurrenEditTransaction] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');
    const [addTransactionDate, setAddTransactionDate] = useState(new Date());
    const [addTransactionNote, setAddTransactionNote] = useState('');
    const [, setTransactionLinks] = useState([]);
    const [transactionCurrentPage, setTransactionCurrentPage] = useState(1);
    const [transactionLastPage, setTransactionLastPage] = useState(1);

    const [deletePopup, setDeletePopup] = useState(null);
    const [deleteType, setDeleteType] = useState(null);
    /* transactions start */


    //when client is changed or tab is changed we reset the wallet and expenses
    useEffect(() => {
        setExpenses({
            'total': 'x',
        });
        setWallet({
            'total': 'x',
        });
        setExpensesCurrentPage(1);
        setCurrentFilterExpenses(allExpenseFilters()[0]);
    }, [user_id, slug]);// eslint-disable-line react-hooks/exhaustive-deps

    //on date change we set the states
    const onChangeDate = (dates) => {
        const [start, end] = dates;
        setFilteDateFrom(start);
        setFilteDateTo(end);
    };

    //when selected tab is changed we reset the page to 1
    useEffect(() => {
        setExpensesCurrentPage(1);
        setTransactionCurrentPage(1);
    }, [costType]);

    //handle date filters
    useEffect(() => {
        if ((currentFilterExpenses?.value >= 1) && (currentFilterExpenses?.value <= 7)) {
            if (currentFilterExpenses?.value === 1) {
                setFilteDateFrom(new Date());
                setFilteDateTo(new Date());
            }
            setExpensesCurrentPage(1);
            setChangeExpenses(changeExpenses + 1);
        }
        if (currentFilterExpenses?.value === 8) {
            setCustomDatesPopup(true);
        }
    }, [currentFilterExpenses]);// eslint-disable-line react-hooks/exhaustive-deps


    // set the states with values when editing current row
    function editClick(e, single) {
        e.preventDefault();
        e.stopPropagation();
        setAddTransaction(true);
        setCurrenEditTransaction(single);
        setTransactionType(allTransactionTypes()[getIndex(1, allTransactionTypes(), 'value')]);
        setTransactionAmount(single?.amount);
        setTransactionCurrency(single?.currency_id ? allCurrencies()[getIndex(single?.currency_id, allCurrencies(), 'value')] : '');
        setAddTransactionDate(single?.date ? new Date(single.date) : new Date());
        setAddTransactionNote(single?.note ? single?.note : '');
        setTransactionTo(single?.receiver_id ? allUsers()[getIndex(single?.receiver_id, allUsers(), 'value')] : '');
    }

    //delete action by type
    const deleteAction = (e) => {
        e.preventDefault();
        if (deleteType === 'transaction') {
            deleteTransaction(e, deletePopup);
        }
        if (deleteType === 'expense') {
            deleteExpense(e, deletePopup);
        }
    }

    //delete transaction backend
    const deleteTransaction = (e, single) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'delete-litigation-transaction',
            method: 'post',
            data: {
                transaction_id: single?.id,
                currency: transactionCurrency,
                user_id: user_id,
                id: activeMatter?.id,
                page: transactionCurrentPage,
            },
            callback: res => {
                setWallet(res.data.wallet);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
                setDeletePopup(null);
                setDeleteType(null);
            },
            catch: err => {
            }
        })
    }


    // delete expense backend
    const deleteExpense = (e, single) => {
        e.preventDefault();
        setGeneralDataApiOptions({
            url: 'delete-litigation-expense',
            method: 'post',
            data: {
                page: expensesCurrentPage ? expensesCurrentPage : 1,
                expense_id: single?.id,
                user_id: Number(user_id),
                id: activeMatter?.id,
                currency: costCurrency,
                date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
            },
            callback: res => {
                setExpenses(res.data.expenses);
                setErrorsReturned({});
                closeAddExpense(e);
                setDeletePopup(null);
                setDeleteType(null);
            },
            catch: err => {
            }
        })
    }

    //set expenses states with current editing row data
    const editExpenses = (e, single) => {
        e.preventDefault();
        e.stopPropagation();
        setAddExpenses(true);

        setCurrenEditExpense(single);
        setAddExpensesType(single?.expense_type_id ? allExpensesTypes()[getIndex(single?.expense_type_id, allExpensesTypes(), 'value')] : '');
        setAddExpensesAmount(single?.amount);
        setAddExpensesCurrency(single?.currency_id ? allCurrencies()[getIndex(single?.currency_id, allCurrencies(), 'value')] : '');
        setAddExpensesDate(single?.date ? new Date(single.date) : new Date());
        setAddExpensesNote(single?.note ? single?.note : '');
        setAddExpensesBillable(single?.billable === 1 ? 1 : 0);
        setDocumentLink(single?.file ? single?.full_path_file : '');
    }

    //reset expenses states once edit or add is canceled
    function closeAddExpense(e) {
        e.preventDefault();
        setAddExpenses(false);
        setCurrenEditExpense('');
        setAddExpensesType('');
        setAddExpensesAmount('');
        setAddExpensesCurrency('');
        setAddExpensesDate(new Date());
        setEditExpense(null);
        setAddExpensesNote('');
        setAddExpensesBillable('');
        setDocument();
        setDocumentLink('');
        setErrorsReturned({});
    }

    //reset transactions states once edit or add is canceled
    function closeAddTransaction(e) {
        e.preventDefault();
        setAddTransaction(false);
        setTransactionAmount('');
        setTransactionCurrency('');
        setAddTransactionDate(new Date());
        setCurrenEditTransaction(null);
        setAddTransactionNote('');
        if (authUser?.id !== user_id) {
            setTransactionTo(allUsers()[getIndex(Number(authUser?.id), allUsers(), 'value')]);
        }
        setOpenEditTransaction(null);
        setErrorsReturned({});
    }

    //get all expenses types
    const allExpensesTypes = () => {
        const expensesTypes = generalData?.expense_types?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title }))
        return expensesTypes;
    }

    //get all currencies
    const allCurrencies = () => {
        const curren = generalData?.currencies?.map(
            singleOption => ({ value: singleOption.id, label: singleOption.title, slug: singleOption.slug }))
        return curren;
    }

    //function to add years to a date
    function addYears(date, years) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    }

    //handle tabs auto selection based on location 
    useEffect(() => {
        if (slug === 'costs' && !(location?.search === '?transactions' && open === 0)) {
            setExpensesCall({
                url: 'get-litigation-expenses',
                method: 'post',
                data: {
                    user_id: Number(user_id),
                    currency: costCurrency,
                    date_from: filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()),
                    date_to: filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()),
                    filter_type: currentFilterExpenses ? currentFilterExpenses?.value : '',
                    id: matter_id,
                },
                callback: res => {
                    setExpenses(res.data?.expenses);
                },
            });
        }
    }, [slug, costCurrency, costType, generalData, profileClient, expensesCurrentPage, changeExpenses]);// eslint-disable-line react-hooks/exhaustive-deps


    // reset expenses pagination links
    useEffect(() => {
        if (expenses?.data?.links?.length > 0) {
            let links = [];
            expenses?.data?.links?.forEach((element, index) => {
                links.push(index);
            });
            setExpensesLinks(links);
            setExpensesCurrentPage(expenses?.data?.current_page);
            setExpensesLastPage(expenses?.data?.last_page);
        }
    }, [expenses]);

    // reset wallet pagination links
    useEffect(() => {
        if (wallet?.data?.links?.length > 0) {
            let links = [];
            wallet?.data?.links?.forEach((element, index) => {
                links.push(index);
            });
            setTransactionLinks(links);
            setTransactionCurrentPage(wallet?.data?.current_page);
            setTransactionLastPage(wallet?.data?.last_page);
        }
    }, [wallet]);

    //get wallet data
    useEffect(() => {
        if (slug && costType === 2) {
            setExpensesCall({
                url: 'get-litigation-wallet?page=' + transactionCurrentPage,
                method: 'post',
                data: {
                    currency: costCurrency,
                    id: matter_id,
                },
                callback: res => {
                    setWallet(res.data.wallet);
                },
                catch: err => {
                }
            });
        }
    }, [slug, profileClient, costCurrency, costType, transactionCurrentPage]);// eslint-disable-line react-hooks/exhaustive-deps

    //suubmit expenses backend
    const submitExpenses = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (currenEditExpense?.id) {
            fd.append('expense_id', currenEditExpense?.id);
        }

        fd.append('expense_type_id', addExpensesType ? addExpensesType?.value : '');
        fd.append('currency_id', addExpensesCurrency ? addExpensesCurrency?.value : '');
        fd.append('currency', costCurrency ? costCurrency : '');
        fd.append('user_id', user_id);
        fd.append('id', activeMatter?.id);
        fd.append('amount', addExpensesAmount ? addExpensesAmount : '');
        fd.append('date', addExpensesDate ? formatDate(addExpensesDate) : '');
        fd.append('note', addExpensesNote ? addExpensesNote : '');
        fd.append('billable', addExpensesBillable ? addExpensesBillable : 0);
        fd.append('date_from', filterDateFrom ? formatDate(filterDateFrom) : formatDate(new Date()));
        fd.append('date_to', filterDateTo ? formatDate(filterDateTo) : formatDate(new Date()));
        fd.append('filter_type', currentFilterExpenses ? currentFilterExpenses?.value : '');
        fd.append('page', expensesCurrentPage ? expensesCurrentPage : 1);

        if (document) {
            fd.append('file', document[0]);
        }

        setGeneralDataApiOptions({
            url: 'add-litigation-expense',
            method: 'post',
            data: fd,
            callback: res => {
                setExpenses(res.data.expenses);
                setErrorsReturned({});
                closeAddExpense(e);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //submit transactions backend
    const submitTransactions = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (currenEditTransaction?.id) {
            fd.append('expense_id', currenEditTransaction?.id);
        }

        fd.append('transaction_type_id', transactionType ? transactionType?.value : '');
        fd.append('currency_id', transactionCurrency ? transactionCurrency?.value : '');
        fd.append('receiver_id', transactionTo ? transactionTo?.value : '');
        fd.append('currency', costCurrency ? costCurrency : '');
        fd.append('user_id', user_id);
        fd.append('amount', transactionAmount ? transactionAmount : '');
        fd.append('date', addTransactionDate ? formatDate(addTransactionDate) : '');
        fd.append('note', addTransactionNote ? addTransactionNote : '');
        fd.append('page', transactionCurrentPage);
        fd.append('id', activeMatter?.id);


        setGeneralDataApiOptions({
            url: 'add-litigation-transaction',
            method: 'post',
            data: fd,
            callback: res => {
                setWallet(res.data.wallet);
                setErrorsReturned({});
                closeAddTransaction(e);
                setOpenEditTransaction(null);
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //handle document upload
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0]);
            setDocumentLink(file);
        }
    }

    //handle filtering transacion types
    const filterTransactions = (e) => {
        if (e.value !== 4) {
            return true;
        }
    }

    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //custom select styles
    const customStylesUsersPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "2px",
                paddingTop: "2px",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //custom select styles
    const customStylesPopupCurr = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                // "&:hover": {
                //     backgroundColor: "#f2f9fc"
                // },
                margin: "auto",
                // margin: "0",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            // border: "1px solid transparent",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // margin: "0px",
            marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //custom select styles
    const customStylesDateFilter = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '500',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "4px",
                maxWidth: "100%",
                paddingLeft: "4px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "500",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "1px solid transparent",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '500',
            boxShadow: "none",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid white",
            marginTop: "-10px",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            opacity: "0.4",
        }),
        input: (base, state) => ({
            ...base,
            color: "#00366A",
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '500',
            padding: "0",
            marginTop: "1px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle add transaction click
    const addTransactionsClick = (e) => {
        setAddTransaction(true);
    }

    // chart data handle
    useEffect(() => {
        if (expenses['expenses']) {
            let all_exp_data = [];
            generalData?.expense_types?.map((single) => (
                expenses['expenses'][single?.slug.replace('-', '')] > 0 &&
                all_exp_data.push(expenses['expenses'][single?.slug.replace('-', '')])
            ));
            setExpensesChartData(all_exp_data);
        }
    }, [expenses, costCurrency]);// eslint-disable-line react-hooks/exhaustive-deps

    //handle empty expenses chart data
    useEffect(() => {
        setChartData({
            datasets: [
                {
                    data: expensesChartData,
                    borderRadius: 0,
                    borderColor: "transparent",
                    backgroundColor: [
                        '#ED3E3E',
                        '#3EB0ED',
                        '#1562CB',
                        '#15CB5B',
                        '#077A5A',
                        'orange',
                        'gray',
                        'black',
                        'purple',
                    ],
                },
            ],
        });
    }, [expenses, expensesChartData]);

    let x = 0;

    return (
        <>
            {
                open === 1 && activeMatter?.id ?
                    <>

                        {
                            (slug === 'costs' && expenses['total'] !== 'x') || (slug === 'costs' && wallet['total'] !== 'x') ?
                                <>
                                    <div className="col-12 pt-4 px-4 d-flex">
                                        <div className="row gx-0">
                                            <div onClick={() => setCostType(1)} className={'button-box me-3 mt-2 py-2 d-flex justify-content-center align-items-center ' + (costType === 1 ? 'active' : '')}>
                                                <CalculatorsIcon />
                                                <div className="button-box-title ps-2 my-auto">{generalData?.users_cost_settings['expenses-title']}</div>
                                            </div>
                                            <div onClick={() => setCostType(2)} className={'button-box py-2 mt-2 d-flex justify-content-center align-items-center ' + (costType === 2 ? 'active' : '')} >
                                                <WalletIcon />
                                                <div className="button-box-title ps-2 my-auto">{generalData?.website_titles['fees']}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        costType === 1 ?
                                            <>
                                                {
                                                    expenses['total'] === 'x' ?
                                                        null
                                                        :
                                                        <>
                                                            <div className="col-12 pt-4 ps-4 d-flex pb-3">
                                                                <div className="row w-100">
                                                                    <div className="col-12 col-lg-4 d-flex flex-column">
                                                                        <div>
                                                                            <h2 className="cost-big-titles pb-1">{generalData?.users_cost_settings['quick-overview']}</h2>
                                                                        </div>
                                                                        <div className="costs-box-styling position-relative height-100 d-flex flex-column">
                                                                            <div className="row h-100">
                                                                                <div className="col-12">
                                                                                    <div className="row justify-content-between g-0 w-100">
                                                                                        <div className="col-auto currency-box mb-2 d-flex flex-row px-2">
                                                                                            {
                                                                                                generalData?.currencies?.map((singleCurr, ind) => (
                                                                                                    <div onClick={() => { setTransactionCurrentPage(1); setExpensesCurrentPage(1); setCostCurrency(singleCurr?.slug) }} key={ind} className={'currency-text d-flex ' + (singleCurr?.slug === costCurrency ? 'active ' : ' ') + (Number(ind + 1) === Number(generalData?.currencies?.length) ? ' ' : 'me-1')}>{singleCurr?.title}</div>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-auto filter-picker-expenses postion-relative">
                                                                                            <Select
                                                                                                classNamePrefix="filters-date-select"
                                                                                                className="filters-date-select w-100"
                                                                                                styles={customStylesDateFilter}
                                                                                                options={allExpenseFilters()}
                                                                                                components={{
                                                                                                    IndicatorSeparator: () => null
                                                                                                }}
                                                                                                isSearchable={false}
                                                                                                value={currentFilterExpenses?.value !== 8 ? currentFilterExpenses : (filterDateFrom && filterDateTo ? { value: 8, label: '' + formatDate(filterDateFrom) + ' / ' + formatDate(filterDateTo) + '' } : currentFilterExpenses)}
                                                                                                onChange={setCurrentFilterExpenses}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="chart-container mx-auto d-flex justify-content-center postion-relative">
                                                                                        {
                                                                                            expenses?.total > 0 && chartData && expensesChartData ?
                                                                                                <>
                                                                                                    <Doughnut
                                                                                                        data={chartData}
                                                                                                        options={{
                                                                                                            plugins: {
                                                                                                                tooltip: {
                                                                                                                    enabled: false
                                                                                                                }
                                                                                                            },
                                                                                                            cutout: "85%",
                                                                                                        }}
                                                                                                        plugins={[{
                                                                                                            afterUpdate: function (chart) {
                                                                                                                const arcs = chart.getDatasetMeta(0).data;

                                                                                                                arcs.forEach(function (arc) {
                                                                                                                    arc.round = {
                                                                                                                        x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                                                                                                        y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                                                                                                        radius: (arc.outerRadius + arc.innerRadius) / 2,
                                                                                                                        thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                                                                                                        backgroundColor: arc.options.backgroundColor
                                                                                                                    }
                                                                                                                });
                                                                                                            },
                                                                                                            afterDraw: (chart) => {
                                                                                                                const {
                                                                                                                    ctx,
                                                                                                                } = chart;
                                                                                                                chart.getDatasetMeta(0).data.forEach(arc => {
                                                                                                                    const endAngle = Math.PI / 2 - arc.endAngle;

                                                                                                                    ctx.save();
                                                                                                                    ctx.translate(arc.round.x, arc.round.y);
                                                                                                                    ctx.fillStyle = arc.options.backgroundColor;
                                                                                                                    ctx.beginPath();
                                                                                                                    ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                                                                                                                    ctx.closePath();
                                                                                                                    ctx.fill();
                                                                                                                    ctx.restore();
                                                                                                                });
                                                                                                            }
                                                                                                        }]}
                                                                                                    />
                                                                                                    <div className="title-middle-chart">
                                                                                                        {expenses?.total} {costCurrency}
                                                                                                        <span>Total</span>
                                                                                                    </div>
                                                                                                </>
                                                                                                :
                                                                                                <Doughnut
                                                                                                    data={{
                                                                                                        datasets: [
                                                                                                            {
                                                                                                                data: [100],
                                                                                                                backgroundColor: [
                                                                                                                    '#91a8c1',
                                                                                                                ],
                                                                                                                borderColor: [
                                                                                                                    '#91a8c1',
                                                                                                                ],
                                                                                                            },
                                                                                                        ],
                                                                                                    }}
                                                                                                    options={{
                                                                                                        cutout: "85%",
                                                                                                    }}
                                                                                                    plugins={[{
                                                                                                        afterUpdate: function (chart) {
                                                                                                            const arcs = chart.getDatasetMeta(0).data;

                                                                                                            arcs.forEach(function (arc) {
                                                                                                                arc.round = {
                                                                                                                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                                                                                                                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                                                                                                                    radius: (arc.outerRadius + arc.innerRadius) / 2,
                                                                                                                    thickness: (arc.outerRadius - arc.innerRadius) / 2,
                                                                                                                    backgroundColor: arc.options.backgroundColor
                                                                                                                }
                                                                                                            });
                                                                                                        },
                                                                                                        afterDraw: (chart) => {
                                                                                                            const {
                                                                                                                ctx,
                                                                                                            } = chart;
                                                                                                            chart.getDatasetMeta(0).data.forEach(arc => {
                                                                                                                const endAngle = Math.PI / 2 - arc.endAngle;

                                                                                                                ctx.save();
                                                                                                                ctx.translate(arc.round.x, arc.round.y);
                                                                                                                ctx.fillStyle = arc.options.backgroundColor;
                                                                                                                ctx.beginPath();
                                                                                                                ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                                                                                                                ctx.closePath();
                                                                                                                ctx.fill();
                                                                                                                ctx.restore();
                                                                                                            });
                                                                                                        }
                                                                                                    }]}
                                                                                                />
                                                                                        }
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="chart-legend pt-4">
                                                                                {
                                                                                    expenses && expenses['expenses'] ?
                                                                                        generalData?.expense_types?.map((singleType, indexx) => {
                                                                                            if (indexx === 0) {
                                                                                                x = -1;
                                                                                            }
                                                                                            if ((expenses['expenses'][(singleType?.slug?.replace('-', ''))] > 0)) {
                                                                                                x = x + 1;
                                                                                            }
                                                                                            return (
                                                                                                expenses['expenses'][(singleType?.slug?.replace('-', ''))] > 0 &&
                                                                                                <div key={indexx} className={'d-flex justify-content-between chart-legend-expenses i' + (x)}>
                                                                                                    {singleType.title}( {Math.round(((Number(expenses['expenses'][(singleType?.slug?.replace('-', ''))]) * 100) / expenses['total']), 2)}%) <span className="d-flex">{expenses['expenses'][singleType?.slug.replace("-", "")]}</span></div>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-lg-8 pt-3 pt-lg-0 d-flex flex-column">
                                                                        <div className="col d-flex flex-row">
                                                                            <div className="d-flex align-items-center">
                                                                                <h2 className="cost-big-titles m-0 ">{generalData?.users_cost_settings['expenses-title']}</h2>
                                                                                <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={() => setAddExpenses(true)} alt="add" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='height-100 costs-box-styling pb-0 mt-2'>
                                                                            <div className="col-12">
                                                                                <div className="table-wrapper-settings">
                                                                                    <table className="users-table" width="100%">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <td></td>
                                                                                                <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['expenses-type']}</td>
                                                                                                <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['date']}</td>
                                                                                                <td className="table-sorting" style={{ minWidth: "200px" }}>{generalData?.users_cost_settings['amount']}</td>
                                                                                                <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['added-by']}</td>
                                                                                                <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['status']}</td>
                                                                                                <td className="table-sorting" style={{ minWidth: "190px" }}>{generalData?.users_cost_settings['document']}</td>
                                                                                                <td className="table-sorting" style={{ minWidth: "400px", width: "400px", maxWidth: "400px" }}>{generalData?.users_cost_settings['note']}</td>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                expenses?.data?.data?.length > 0 &&
                                                                                                expenses?.data?.data?.map((singleExpense, index) => (
                                                                                                    <tr key={index} >
                                                                                                        <td className="text-end p-0 pe-2 position-relative" >
                                                                                                            {
                                                                                                                authUser?.id === singleExpense?.user_id ?
                                                                                                                    <>
                                                                                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); editExpense?.id === singleExpense?.id ? setEditExpense(null) : setEditExpense(singleExpense) }} />
                                                                                                                        <div style={{}} className={"settings-clickmenu first-td-buttons text-start " + (singleExpense?.id === editExpense?.id ? ' d-block' : '')}>
                                                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editExpenses(e, singleExpense) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                                            <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); setDeletePopup(singleExpense); setDeleteType('expense') }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>{singleExpense?.expense_type?.title}</td>
                                                                                                        <td>{singleExpense?.date}</td>
                                                                                                        <td>{singleExpense?.amount}</td>
                                                                                                        <td><img className="table-user-image" src={singleExpense?.user?.picture ? singleExpense?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" /></td>
                                                                                                        <td>{singleExpense?.billable === 1 ? generalData?.users_cost_settings['billable'] : generalData?.users_cost_settings['not-billable']}</td>
                                                                                                        <td>{singleExpense?.file ? <a href={singleExpense?.full_path_file} className="text-underlined" target="_blank" rel="noreferrer">{generalData?.users_cost_settings['document-name']} {index + 1}</a> : null}</td>
                                                                                                        <td>{singleExpense?.note}</td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                    {
                                                                                        expenses?.data?.data?.length === 0 &&
                                                                                        <>
                                                                                            <div className="w-100 text-center mt-4">
                                                                                                {generalData?.fixed_titles['you-didnt-add-any-company-yet'].replace('%record%', generalData?.users_cost_settings['expenses-title'])}
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                expenses?.data?.data?.length !== 0 &&
                                                                                <div className="col-12 justify-content-center align-items-center py-2">
                                                                                    <Link to={'/matters/litigations/' + matter_id + '/costs/expenses/all'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                                                                </div>
                                                                            }

                                                                            {
                                                                                expensesLastPage > 1 ?
                                                                                    <div className="col-12 text-center pb-3 d-flex justify-content-center">
                                                                                        <Pagination
                                                                                            className="pagination-bar"
                                                                                            currentPage={expensesCurrentPage}
                                                                                            totalCount={12 * expensesLastPage}
                                                                                            pageSize={12}
                                                                                            onPageChange={(e) => setExpensesCurrentPage(e)}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-12 my-4"></div> */}
                                                                </div>
                                                            </div>
                                                        </>
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    wallet['total'] !== 'x' ?
                                                        <div className="col-12 pt-4 ps-4 d-flex pb-3">
                                                            <div className="row w-100">
                                                                <div className="col-12 col-md-7 col-lg-5 col-xl-3">
                                                                    <h2 className="cost-big-titles pb-1">{generalData?.users_cost_settings['available-balance']}</h2>
                                                                    <div className="costs-box-styling position-relative">
                                                                        <div className="row justify-content-between my-2">
                                                                            <div className="col my-auto">
                                                                                <p style={{ lineHeight: "1.1" }} className="m-0 fw-700">{wallet['total']}</p>
                                                                            </div>
                                                                            <div className="col-auto">
                                                                                <div className="currency-box d-flex flex-row px-2">
                                                                                    {
                                                                                        generalData?.currencies?.map((singleCurr, ind) => (
                                                                                            <div onClick={() => { setTransactionCurrentPage(1); setExpensesCurrentPage(1); setCostCurrency(singleCurr?.slug) }} key={ind} className={'currency-text d-flex ' + (singleCurr?.slug === costCurrency ? 'active ' : ' ') + (Number(ind + 1) === Number(generalData?.currencies?.length) ? ' ' : 'me-1')}>{singleCurr?.title}</div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 pt-3">

                                                                    <div className="col d-flex flex-row">
                                                                        <div className="d-flex align-items-center">
                                                                            <h2 className="cost-big-titles m-0 ">{generalData?.users_cost_settings['transactions']}</h2>
                                                                            <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={(e) => addTransactionsClick(e)} alt="add" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="costs-box-styling pb-0">
                                                                        <div className="col-12">
                                                                            <div className="table-wrapper-settings">
                                                                                <table className="users-table" width="100%">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['transaction-type']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['date']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "170px" }}>{generalData?.users_cost_settings['amount']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "200px" }}>{generalData?.users_cost_settings['related-to']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "220px" }}>{generalData?.users_cost_settings['status']}</td>
                                                                                            <td className="table-sorting" style={{ minWidth: "400px", width: "400px", maxWidth: "400px" }}>{generalData?.users_cost_settings['note']}</td>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            wallet?.data?.data?.length > 0 &&
                                                                                            wallet?.data?.data?.map((singleTransaction, indexxx) => (
                                                                                                <tr key={indexxx} >
                                                                                                    <td className="text-start p-0 pe-2 position-relative" >
                                                                                                        <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); openEditTransaction?.id === singleTransaction?.id ? setOpenEditTransaction(null) : setOpenEditTransaction(singleTransaction) }} />
                                                                                                        <div style={indexxx + 1 === wallet?.data?.data?.length ? { top: "-15px" } : {}} className={"settings-clickmenu first-td-buttons text-start " + (singleTransaction?.id === openEditTransaction?.id ? ' d-block' : '')}>
                                                                                                            <p style={{ zIndex: "99" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); editClick(e, singleTransaction) }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['edit-title']}</p>
                                                                                                            <p style={{ zIndex: "200" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); setDeletePopup(singleTransaction); setDeleteType('transaction') }} className="p-0 py-1 m-0 ms-2">{generalData?.settings_section_settings['delete-title']}</p>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{singleTransaction?.transaction_type?.title}</td>
                                                                                                    <td>{singleTransaction?.date}</td>
                                                                                                    <td>{singleTransaction?.amount}</td>
                                                                                                    <td>
                                                                                                        {
                                                                                                            singleTransaction?.user_id === Number(user_id) ?
                                                                                                                <div style={{ display: "-webkit-box" }}>
                                                                                                                    <div className="position-relative user-image-in-table">
                                                                                                                        <img className="table-user-image" src={singleTransaction?.user?.picture ? singleTransaction?.user?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                        <div className="triangle-down"></div>
                                                                                                                        <p className="hover-username">{singleTransaction?.user_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.user?.name}</p>
                                                                                                                    </div>
                                                                                                                    <RedArrowRight />
                                                                                                                    <div className="position-relative user-image-in-table">
                                                                                                                        <img className="table-user-image" src={singleTransaction?.receiver?.picture ? singleTransaction?.receiver?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                        <div className="triangle-down"></div>
                                                                                                                        <p className="hover-username">{singleTransaction?.receiver_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.receiver?.name}</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div style={{ display: "-webkit-box" }}>
                                                                                                                    <div className="position-relative user-image-in-table">
                                                                                                                        <img className="table-user-image" src={singleTransaction?.receiver?.picture ? singleTransaction?.receiver?.full_path_picture : generalData?.fixed_settings?.full_path_no_profile_picture} alt="user" />
                                                                                                                        <div className="triangle-down"></div>
                                                                                                                        <p className="hover-username">{singleTransaction?.receiver_id === authUser?.id ? generalData?.fixed_titles['me'] : singleTransaction?.receiver?.name}</p>
                                                                                                                    </div>
                                                                                                                    <GreenArrowLeft />
                                                                                                                    <div className="position-relative user-image-in-table">
                                                                                                                        {/* <img className="table-user-image" src={''} alt="user" /> */}
                                                                                                                        <img className="table-user-image" src={
                                                                                                                            activeMatter?.clients[0]?.client?.client_type_id === 1 ?
                                                                                                                                generalData?.fixed_settings?.full_path_no_company_picture
                                                                                                                                :
                                                                                                                                activeMatter?.clients[0]?.client?.client_type_id === 2 ?
                                                                                                                                    generalData?.fixed_settings?.full_path_no_individual_picture
                                                                                                                                    :
                                                                                                                                    generalData?.fixed_settings?.full_path_no_ngo_picture
                                                                                                                            } alt="user" />
                                                             
                                                                                                                        <div className="triangle-down"></div>
                                                                                                                        <p className="hover-username">{activeMatter?.clients[0]?.client?.name}</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div style={{ width: "fit-content" }} className="position-relative user-image-in-table">
                                                                                                            <span style={singleTransaction?.transaction_stat_id === 1 ? { color: "#30D309" } : (singleTransaction?.transaction_stat_id === 2 ? { color: "#D30915" } : { color: "#FDC32D" })} >{singleTransaction?.transaction_stat?.title}</span>
                                                                                                            {
                                                                                                                singleTransaction?.rejection_reason ?
                                                                                                                    <>
                                                                                                                        <div className="triangle-down"></div>
                                                                                                                        <p className="hover-username">{singleTransaction?.rejection_reason}</p>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{singleTransaction?.note}</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                                {
                                                                                    wallet?.data?.data?.length === 0 &&
                                                                                    <>
                                                                                        <div className="w-100 text-center mt-4">
                                                                                            {generalData?.fixed_titles['you-didnt-add-any-company-yet'].replace('%record%', generalData?.users_cost_settings['transactions'])}
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            wallet?.data?.data?.length > 0 &&
                                                                            <div className="col-12 justify-content-center align-items-center py-2">
                                                                                <Link to={'/matters/litigations/' + matter_id + '/costs/transactions/all'} className="popup-button my-auto mx-auto">{generalData?.fixed_titles['view-all']}</Link>
                                                                            </div>
                                                                        }

                                                                        <div className="col-12 text-center pb-2 d-flex justify-content-center">
                                                                            <Pagination
                                                                                className="pagination-bar"
                                                                                currentPage={transactionCurrentPage}
                                                                                totalCount={12 * transactionLastPage}
                                                                                pageSize={12}
                                                                                onPageChange={(e) => e <= transactionLastPage && e > 0 ? setTransactionCurrentPage(e) : null}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </>
                                    }
                                </>
                                :
                                null
                        }
                    </>
                    :
                    null
            }
            {
                //expenses popup
                addExpenses ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => closeAddExpense(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-title'] : generalData?.users_cost_settings['add-expenses-title']}</h1>
                                        </div>
                                        <form onSubmit={submitExpenses}>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={allExpensesTypes()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.users_cost_settings['expenses-type']}
                                                                isSearchable={false}
                                                                value={addExpensesType ? addExpensesType : null}
                                                                onChange={setAddExpensesType} />
                                                        </div>
                                                        {
                                                            errorsReturned?.expense_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.expense_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={allCurrencies()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.users_cost_settings['currency']}
                                                                isSearchable={false}
                                                                value={addExpensesCurrency ? addExpensesCurrency : null}
                                                                onChange={setAddExpensesCurrency} />
                                                        </div>
                                                        {
                                                            errorsReturned?.currency_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input name="amount" value={addExpensesAmount} onChange={(e) => setAddExpensesAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                        {
                                                            errorsReturned?.amount ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={'Date'}
                                                                value={addExpensesDate ? addExpensesDate : ''}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={addExpensesDate}
                                                                dateFormat="d MMMM yyyy"
                                                                onChange={(date) => setAddExpensesDate(date)}
                                                                minDate={addYears(new Date(), -21)}
                                                                maxDate={new Date()}
                                                                locale={localStorage.getItem("LmsLanguage")}
                                                            />
                                                        </div>
                                                        {
                                                            errorsReturned?.date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2 position-relative">
                                                        <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                        <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                        <AttachIcon className="download-icon download-svg-popup" />
                                                        {
                                                            errorsReturned?.file ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addExpensesNote} onChange={(e) => setAddExpensesNote(e.target.value)}></textarea>
                                                    </div>
                                                    <div className="col-12 pt-2 d-flex my-auto">
                                                        <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className={'my-auto billable-div ' + (addExpensesBillable ? 'active' : '')}>
                                                        </div>
                                                        <div onClick={() => addExpensesBillable === 0 ? setAddExpensesBillable(1) : setAddExpensesBillable(0)} className="ms-2 billable-popup">{generalData?.users_cost_settings['billable']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="submit" className="popup-button my-auto">{currenEditExpense ? generalData?.users_cost_settings['edit-expenses-button'] : generalData?.users_cost_settings['add-expenses-button']}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {
                //transactions popup
                addTransaction ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={(e) => closeAddTransaction(e)} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">{currenEditTransaction ? generalData?.users_cost_settings['edit-transaction-title'] : generalData?.users_cost_settings['add-transaction-title']}</h1>
                                        </div>
                                        <form onSubmit={submitTransactions}>
                                            <div className="col-12 pt-2 pb-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesPopup}
                                                                options={allTransactionTypes()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.users_cost_settings['transaction-type']}
                                                                isSearchable={false}
                                                                value={transactionType}
                                                                filterOption={(e) => filterTransactions(e)}
                                                                onChange={setTransactionType} />
                                                        </div>
                                                        {
                                                            errorsReturned?.transaction_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.transaction_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div style={{ zIndex: '999999 !important' }} className="position-relative text-start justify-content-start w-100">
                                                            <input name="amount" value={transactionAmount} onChange={(e) => setTransactionAmount(e.target.value)} className="add-user-inputs" placeholder={generalData?.users_cost_settings['amount']} />
                                                            <Select
                                                                classNamePrefix="curr-select"
                                                                className="curr-select w-100"
                                                                styles={customStylesPopupCurr}
                                                                options={allCurrencies()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                placeholder={generalData?.users_cost_settings['curr-transaction']}
                                                                isSearchable={false}
                                                                value={transactionCurrency ? transactionCurrency : null}
                                                                onChange={setTransactionCurrency} />
                                                        </div>
                                                        {
                                                            errorsReturned?.currency_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.currency_id}</h5>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errorsReturned?.amount ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.amount}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <DatePicker
                                                                className="popup-date add-user-inputs"
                                                                placeholderText={generalData?.users_cost_settings['date']}
                                                                value={addTransactionDate ? addTransactionDate : ''}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={addTransactionDate}
                                                                dateFormat="d MMMM yyyy"
                                                                onChange={(date) => setAddTransactionDate(date)}
                                                                minDate={addYears(new Date(), -21)}
                                                                maxDate={new Date()}
                                                                locale={localStorage.getItem("LmsLanguage")}
                                                            />

                                                        </div>
                                                        {
                                                            errorsReturned?.date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100">
                                                            <Select
                                                                classNamePrefix="popup-select"
                                                                className="popup-select w-100"
                                                                styles={customStylesUsersPopup}
                                                                options={allUsers()}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                isSearchable={false}
                                                                placeholder={generalData?.users_cost_settings['transaction-to']}
                                                                value={transactionTo ? transactionTo : null}
                                                                onChange={setTransactionTo} />
                                                        </div>
                                                        {
                                                            errorsReturned?.receiver_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.receiver_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="note" className="add-user-inputs" placeholder={generalData?.users_cost_settings['note']} value={addTransactionNote} onChange={(e) => setAddTransactionNote(e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center">
                                                <button type="submit" className="popup-button my-auto">{currenEditTransaction ? generalData?.users_cost_settings?.edit_transaction_button : generalData?.users_cost_settings['add-transaction-button']}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

            {
                //delete popup
                deletePopup ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={() => { setDeletePopup(null); setDeleteType(null); setOpenEditTransaction(null); setEditExpense(null); }} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <div className="col-9 col-sm-12 d-flex justify-content-center">
                                            <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                            <button onClick={(e) => deleteAction(e)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                            <button onClick={() => { setDeletePopup(''); setDeleteType(''); setOpenEditTransaction(null); setEditExpense(null); }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

            {
                //custom dates popup
                customDatesPopup ?
                    <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                        <div className="pop-up-api-box">
                            <CloseIcon className="cursor-pointer" onClick={() => { setCurrentFilterExpenses(allExpenseFilters()[0]); setCustomDatesPopup(false); setFilteDateFrom(new Date()); setFilteDateTo(new Date()) }} />
                            <div className="text-center ">
                                <div className="containter-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <h1 className="pop-up-title m-0 py-2 color-dark-blue">Custom Dates</h1>
                                        </div>
                                        <div className="col-12 pt-2 pb-4">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-md-6 pt-2">
                                                    <div className="postion-relative text-start justify-content-start w-100">
                                                        <DatePicker
                                                            className="popup-date add-user-inputs"
                                                            placeholderText={'Date'}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            dateFormat="dd/MM/yyyy"
                                                            onChange={onChangeDate}
                                                            startDate={filterDateFrom}
                                                            endDate={filterDateTo}
                                                            selectsRange
                                                            minDate={addYears(new Date(), -100)}
                                                            maxDate={new Date()}
                                                            locale={localStorage.getItem("LmsLanguage")}
                                                        />
                                                    </div>
                                                    {
                                                        errorsReturned?.date ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.date}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-center">
                                            <button type="button" onClick={() => { setCustomDatesPopup(false); setChangeExpenses(changeExpenses + 1) }} className="popup-button my-auto">Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }

            <Api options={usersApiOptions} />
            <Api options={generalDataApiOptions} />
            <Api options={expensesCall} />
        </>
    )
}