import { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import Api from "./Api";
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import DatePicker from 'react-datepicker';
import { addMonths, addYears, formatDate, getOptions } from "../Helpers";
import RelationsSelect from "./RelationsSelect";
import { getIndex } from "../Helpers";

import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { AddIconCircle, CloseIcon } from "./Icons";
registerLocale('ar', ar)

export default function LitigationPopupComponent(props) {
    const {
        authUserGlobal: [authUser],
        generalDataGlobal,
        setLitigations,
    } = useContext(GlobalState);

    const {
        search,
        setAddMatterPopup,
        page,
        sort,
        direction,
        onSuccess,
        returnRecord = 0,
    } = props

    const { editLitigation, setTotalMatters, setTotalLitigations, setTotalHearings } = props;

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [generalData] = generalDataGlobal;


    const [title, setTitle] = useState(editLitigation ? editLitigation?.title : '');
    const [selectedEntryDate, setSelectedEntryDate] = useState(editLitigation?.entry_date ? new Date(editLitigation?.entry_date) : null);
    const [selectedLitigationType, setSelectedLitigationType] = useState();
    const [litigationSubject, setLitigationSubject] = useState(editLitigation?.subject || '');
    const [litigationWorkArea, setLitigationWorkArea] = useState(editLitigation?.work_area || '')
    const [excludeValues, setExcludeValues] = useState([])

    const [activeSelect, setActiveSelect] = useState(null)

    const [selectedClient, setSelectedClient] = useState([]);

    const [, setSelectedOpponent] = useState([]);

    const [selectedAssignees, setSelectedAssignees] = useState([]);

    const [, setOpponentDescription] = useState(editLitigation?.opponent_description || '')

    const [establishDate, setEstablishDate] = useState(editLitigation?.establish_date ? new Date(editLitigation?.establish_date) : null);
    const [establishNumber, setEstablishNumber] = useState(editLitigation?.establish_number || '');

    const [appealDate, setAppealDate] = useState(editLitigation?.appeal_date ? new Date(editLitigation?.appeal_date) : null);
    const [appealNumber, setAppealNumber] = useState(editLitigation?.appeal_number || '')

    const [litigationDescription, setLitigationDescription] = useState(editLitigation?.description || '');
    const [litigationSummary, setLitigationSummary] = useState(editLitigation?.summary || '');

    const [formData, setFormData] = useState({});

    const [roundDate, setRoundDate] = useState();
    const [roundNumber, setRoundNumber] = useState();

    const [errorsReturned, setErrorsReturned] = useState({});

    //get individual realtions
    const individualRelations = () => {
        const relOptions = authUser?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type })
        )
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = authUser?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = authUser?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    const initClientOpponent = (keys) => {
        let emptyObject = {};
        keys?.map(key => (

            emptyObject[key] = [
                { client: '', description: '' }
            ]
        ))
        setFormData({ ...formData, ...emptyObject })
    }

    useEffect(() => {
        let keys = ['clients', 'opponents']
        initClientOpponent(keys)
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    //set states when current client changes
    useEffect(() => {
        if (editLitigation && editLitigation?.id) {
            setTitle(editLitigation?.title);
            setSelectedLitigationType(getOptions(generalData?.litigationTypes)[getIndex(editLitigation?.type_id, getOptions(generalData?.litigationTypes), 'value')])
            if (editLitigation?.client?.client_type_id === 1) {
                let text = 'client-' + editLitigation?.client?.id;

                setTimeout(() => {
                    setSelectedClient([...selectedClient, companyRelations()[getIndex(text, companyRelations(), 'value')]]);
                }, 300)
            }
            if (editLitigation?.client?.client_type_id === 2) {
                let text = 'client-' + editLitigation?.client?.id;
                setTimeout(() => {
                    setSelectedClient([...selectedClient, individualRelations()[getIndex(text, individualRelations(), 'value')]]);
                }, 300);
            }
            if (editLitigation?.client?.client_type_id === 3) {
                let text = 'client-' + editLitigation?.client?.id;
                setTimeout(() => {
                    setSelectedClient([...selectedClient, ngoRelations()[getIndex(text, ngoRelations(), 'value')]]);
                }, 300);
            }
            let emptyObject = [];
            emptyObject['clients'] = editLitigation?.clients?.length > 0
                ?
                editLitigation.clients.map((client, index) => (
                    {
                        id: client.client.id,
                        selectedClient:
                            Number(client?.client?.client_type_id) === 1 ?
                                companyRelations()[getIndex('client-' + client?.client?.id, companyRelations(), 'value')]
                                :
                                Number(client?.client?.client_type_id) === 2 ?
                                    individualRelations()[getIndex('client-' + client?.client?.id, individualRelations(), 'value')]
                                    :
                                    Number(client?.client?.client_type_id) === 3 ?
                                        ngoRelations()[getIndex('client-' + client?.client?.id, ngoRelations(), 'value')]
                                        :
                                        ''
                        ,
                        description: client.description
                    }
                ))
                :
                [{ client: '', description: '' }];

            emptyObject['opponents'] = editLitigation?.opponents?.length > 0
                ?
                editLitigation.opponents.map((opponent) => (
                    {
                        id: opponent?.client?.id,
                        selectedOpponent:
                            Number(opponent?.client?.client_type_id) === 1 ?
                                companyRelations()[getIndex('client-' + opponent?.client?.id, companyRelations(), 'value')]
                                :
                                Number(opponent?.client?.client_type_id) === 2 ?
                                    individualRelations()[getIndex('client-' + opponent?.client?.id, individualRelations(), 'value')]
                                    :
                                    Number(opponent?.client?.client_type_id) === 3 ?
                                        ngoRelations()[getIndex('client-' + opponent?.client?.id, ngoRelations(), 'value')]
                                        :
                                        ''
                        ,
                        description: opponent.description
                    }
                )) : [{ client: '', description: '' }];

            setFormData(emptyObject)

            if (editLitigation?.assignees?.length > 0) {
                setSelectedAssignees(
                    editLitigation?.assignees?.map(
                        singleUser => ({ value: singleUser.id, label: singleUser.name })
                    )
                );
            }
        }

    }, [editLitigation]);// eslint-disable-line react-hooks/exhaustive-deps


    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //close add client and reset states
    const resetForm = (e) => {
        setTitle('');
        setSelectedLitigationType('');
        setSelectedAssignees('');
        setLitigationSubject('');
        setLitigationWorkArea('');
        setSelectedClient([]);
        setSelectedOpponent([]);
        setOpponentDescription('');
        setEstablishDate('');
        setEstablishNumber('');
        setSelectedEntryDate('')
        setAppealDate('');
        setAppealNumber('');
        setLitigationDescription('');
        setLitigationSummary('');
    }


    //submit litigation to backend
    const submitLitigation = (e) => {
        e.preventDefault();

        let fd = new FormData();
        fd.append('litigation_id', editLitigation ? editLitigation?.id : '');
        fd.append('title', title ? title : '');
        if (search) {
            fd.append('search', search);
        }
        fd.append('entry_date', selectedEntryDate ? formatDate(selectedEntryDate) : '');
        fd.append('litigation_type', selectedLitigationType ? selectedLitigationType?.value : '');
        fd.append('litigation_subject', litigationSubject ? litigationSubject : '');
        fd.append('litigaiton_workarea', litigationWorkArea ? litigationWorkArea : '');
        fd.append('establish_date', establishDate ? formatDate(establishDate) : '');
        fd.append('establish_number', establishNumber ? establishNumber : '');
        fd.append('appeal_date', appealDate ? formatDate(appealDate) : '');
        fd.append('appeal_number', appealNumber ? appealNumber : '');
        fd.append('round_date', roundDate ? formatDate(roundDate) : '');
        fd.append('round_number', roundNumber ? roundNumber : '');
        fd.append('clients', formData?.clients.length > 0 ? JSON.stringify(formData?.clients) : '');
        fd.append('opponents', formData?.opponents.length > 0  ? JSON.stringify(formData?.opponents) : '');
        if (selectedAssignees?.length > 0) {
            fd.append('assignees', (selectedAssignees?.length > 0 ? selectedAssignees?.map((single, index) => (
                single.value
            )).toString() : ''));
        }
        fd.append('description', litigationDescription ? litigationDescription : '');
        fd.append('summary', litigationSummary ? litigationSummary : '');
        fd.append('sort', sort || '');
        fd.append('direction', direction || '');
        fd.append('page', page || '')

        if(props?.returnDashboard && props?.returnDashboard === 1){
            fd.append('return_dashboard',1);
        }

        fd.append('type', 'litigations')
        fd.append('returnRecord', returnRecord);

        if(props?.tasksType){
            fd.append('tasksType',props?.tasksType);
        }
        setGeneralDataApiOptions({
            url: 'add-edit-litigation',
            method: 'post',
            data: fd,
            callback: res => {
                resetForm();
                setAddMatterPopup(false);

                if (onSuccess) {
                    onSuccess(res)
                } else {

                    setLitigations(res.data.litigations);
                    setTotalMatters(res.data?.total['hearings']?.length + res.data?.total['litigations']?.length)
                    // setCurrentPage(data.litigations.current_page);
                    setTotalLitigations(res.data?.total['litigations']?.length);
                    setTotalHearings(res.data?.total['hearings'].length);
                }


            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }

    //add new empty field to formdata by key
    const addToFormData = (key) => {
        let emptyObject = {};
        emptyObject = { client: '', description: '' }
        let newFormData = { ...formData };
        newFormData[key].push(emptyObject);
        setFormData(newFormData);
    }
    const removeFromFormData = (dataField, index, id = null) => {
        let newFd = { ...formData };
        newFd[dataField].splice(index, 1);
        // newFd[dataField].splice(index, 1);
        setFormData(newFd);
    }


    const handleClientDescriptionChange = ({ target }, index) => {
        let newFormData = { ...formData }
        newFormData['clients'][index].description = target.value;
        setFormData(newFormData);
    }

    const handleOpponentDescriptionChange = ({ target }, index) => {
        let newFormData = { ...formData };
        newFormData['opponents'][index].description = target?.value;
        setFormData(newFormData);
    }


    const customStyles = {
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A"
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: 'Poppins',
                fontWeight: '400',
                color: '#00366A',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                "&:hover": {
                    backgroundColor: "#f2f9fc"
                },
            };
        },
        singleValue: base => ({
            ...base,
            backgroundColor: "red"
        }),
        control: provided => ({
            ...provided,
            minHeight: 40,
            width: '100%',
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: '#393939' },
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "1px",
        }),
        input: (base, state) => ({
            ...base,
            color: '#00366A',
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "-8px",
            '& input': {
                font: 'inherit',
            },
        })
    };
    //get users for relations
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: singleOption?.name }))
        return curren;
    }

    const handleSelectClientChange = (e, index) => {
        const newFormData = { ...formData }
        newFormData.clients[index] =
        {
            selectedClient:
                Number(e?.client_type) === 1 ?
                    companyRelations()[getIndex(e?.value, companyRelations(), 'value')]
                    :
                    Number(e?.client_type) === 2 ?
                        individualRelations()[getIndex(e?.value, individualRelations(), 'value')]
                        :
                        Number(e?.client_type) === 3 ?
                            ngoRelations()[getIndex(e?.value, ngoRelations(), 'value')]
                            :
                            ''
            ,
        }
        setFormData(newFormData)
    }
    const handleSelectedOpponentChange = (e, index) => {
        const newFormData = { ...formData }
        newFormData.opponents[index] =
        {

            selectedOpponent:
                Number(e?.client_type) === 1 ?
                    companyRelations()[getIndex(e?.value, companyRelations(), 'value')]
                    :
                    Number(e?.client_type) === 2 ?
                        individualRelations()[getIndex(e?.value, individualRelations(), 'value')]
                        :
                        Number(e?.client_type) === 3 ?
                            ngoRelations()[getIndex(e?.value, ngoRelations(), 'value')]
                            :
                            ''
            ,
        }
        setFormData(newFormData)
    }

    useEffect(() => {
        setExcludeValues([]);
    }, [formData])

    useEffect(() => {
        if (Number(excludeValues?.length) === 0) {
            if (formData?.clients) {
                formData?.clients?.map((client) => {
                    if (!excludeValues.includes(client?.selectedClient)) {
                        if (Number(client?.id) !== 0) {
                            setExcludeValues(prev => ([...prev, client?.selectedClient?.label]))
                        }
                    }
                    return null;
                })

            }
            if (formData?.opponents) {
                formData?.opponents?.map((opponent) => {
                    if (!excludeValues.includes(opponent?.selectedOpponent)) {
                        if (Number(opponent?.id) !== 0) {
                            setExcludeValues(prev => ([...prev, opponent?.selectedOpponent?.label]))
                        }
                    }
                    return null;
                })
            }
        }
    }, [excludeValues]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in" >
                <div className="pop-up-api-box" style={{ maxHeight: 540, overflow: 'auto' }}>
                    <CloseIcon className="cursor-pointer" onClick={(e) => { setAddMatterPopup({ open: false, type: '' }); resetForm() }} />
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{editLitigation?.id ? generalData?.matterSectionsSettings['edit-record']?.replace('%record%', props?.popupTitle) : generalData?.matterSectionsSettings['add-record']?.replace('%record%', props?.popupTitle)}</h1>
                                </div>
                                <form onSubmit={submitLitigation}>
                                    <div className="col-12 pt-2 pb-4">
                                        <div className="row">
                                            <div className="col-12 col-md-6 pt-2">
                                                <input
                                                    name="title"
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    className="add-user-inputs"
                                                    placeholder={generalData?.litigationFixedTitles['litigation-title']}
                                                    required
                                                />
                                                {
                                                    errorsReturned?.title ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.title}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {/* <div className="col-12 col-md-6 pt-2">
                                                <div className="position-relative text-start justify-content-start w-100">
                                                    <DatePicker
                                                        className="popup-date add-user-inputs"
                                                        placeholderText={generalData?.matterSectionsSettings['entry-date']}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        value={new Date()}
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={addYears(new Date(), -21)}
                                                        maxDate={addMonths(new Date(), 1)}
                                                        isClearable={true}
                                                        selected={selectedEntryDate}
                                                        onChange={setSelectedEntryDate}
                                                        locale={localStorage.getItem("LmsLanguage")}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.client_category_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.client_category_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div> */}
                                            <>
                                                <div className="col-12 col-md-6 pt-2">
                                                    <div className="position-relative text-start justify-content-start w-100">
                                                        <Select
                                                            classNamePrefix="select-multiple-users"
                                                            className="select-multiple-users w-100"
                                                            styles={customStylesPopup}
                                                            options={getOptions(generalData?.litigationTypes)}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.litigationFixedTitles['litigation-type']}
                                                            isSearchable={false}
                                                            value={selectedLitigationType}
                                                            onClick={() => { setActiveSelect(null) }}

                                                            onChange={setSelectedLitigationType} />
                                                    </div>
                                                    {
                                                        errorsReturned?.legal_type_id ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>

                                            </>

                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <input
                                                        name="subject"
                                                        value={litigationSubject}
                                                        onChange={(e) => setLitigationSubject(e.target.value)}
                                                        className="add-user-inputs"
                                                        placeholder={generalData?.litigationFixedTitles['litigation-subject']}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <input
                                                        name="title"
                                                        value={litigationWorkArea}
                                                        onChange={(e) => setLitigationWorkArea(e.target.value)}
                                                        className="add-user-inputs"
                                                        placeholder={generalData?.litigationFixedTitles['work-area']}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 text-start mt-3">
                                                <h6 className="m-0">{generalData?.litigationFixedTitles['clients-names']}</h6>
                                            </div>
                                            {
                                                formData?.clients?.length > 0 &&
                                                formData?.clients?.map((client, index) => (
                                                    <div key={index} className="row">
                                                        <div key={index} className="col-12 col-md-6 mt-2 position-relative" style={{ zIndex: 100 - index }}>
                                                            <div className='position-absolute top-0 start-n1 h-100 d-flex align-items-center'>
                                                                {
                                                                    formData.clients?.length > 1 && Number(index) !== formData.clients?.length - 1 ?
                                                                        <button className="btn border-circle-sm border-circle p-0" type='button' onClick={() => removeFromFormData('clients', index, client?.id)}>-</button>
                                                                        :
                                                                        <button className="btn border-circle-sm border-circle p-0" type='button' onClick={() => addToFormData('clients')}><AddIconCircle className={`start-0 nostyling`} /></button>
                                                                }
                                                            </div>
                                                            <div className="postion-relative text-start justify-content-start w-100" >
                                                                <RelationsSelect
                                                                    selectedRelation={client?.selectedClient ? client?.selectedClient : ''}
                                                                    setSelectedRelation={(e) => handleSelectClientChange(e, index)}
                                                                    profile={authUser}
                                                                    defaultSelected={'individual'}
                                                                    key={index}
                                                                    activeSelect={activeSelect}
                                                                    setActiveSelect={setActiveSelect}
                                                                    excludeMatters
                                                                    count={index}
                                                                    excludeMultipleValues={excludeValues}
                                                                    // onChange={(e) => setTitle(e.target.value)}
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.litigationFixedTitles['client-name']}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <input
                                                                    name="title"
                                                                    value={client?.description}
                                                                    onChange={(e) => handleClientDescriptionChange(e, index)}
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.litigationFixedTitles['client-description']}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                                )

                                            }
                                            <div className="col-12 text-start mt-3">
                                                <h6 className="m-0">{generalData?.litigationFixedTitles['opponents-names']}</h6>
                                            </div>
                                            {
                                                formData?.opponents?.length > 0 &&
                                                formData?.opponents?.map((opponent, index) => (
                                                    <div key={index} className="row">
                                                        <div key={index} className="col-12 col-md-6 mt-2 position-relative" style={{ zIndex: 99 - index - formData?.clients?.length }}>
                                                            <div className='position-absolute top-0 start-n1 h-100 d-flex align-items-center'>
                                                                {
                                                                    formData.opponents.length > 1 && Number(index) !== formData.opponents.length - 1 ?
                                                                        <button className="btn border-circle-sm border-circle p-0" type='button' onClick={() => removeFromFormData('opponents', index, opponent?.id)}>-</button>
                                                                        :
                                                                        <button className="btn border-circle-sm border-circle p-0" type='button' onClick={() => addToFormData('opponents')}><AddIconCircle className={'start-0 nostyling'} /></button>
                                                                }
                                                            </div>
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <RelationsSelect
                                                                    selectedRelation={opponent?.selectedOpponent ? opponent?.selectedOpponent : ''}
                                                                    setSelectedRelation={(e) => handleSelectedOpponentChange(e, index)}
                                                                    profile={authUser}
                                                                    defaultSelected={'individual'}
                                                                    activeSelect={activeSelect}
                                                                    setActiveSelect={setActiveSelect}
                                                                    excludeMultipleValues={excludeValues}
                                                                    excludeMatters
                                                                    count={formData.clients + index}
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.litigationFixedTitles['opponent-name']}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <div className="postion-relative text-start justify-content-start w-100">
                                                                <input
                                                                    name="title"
                                                                    value={opponent?.description}
                                                                    onChange={(e) => handleOpponentDescriptionChange(e, index)}
                                                                    className="add-user-inputs"
                                                                    placeholder={generalData?.litigationFixedTitles['opponent-description']}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.legal_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                ))

                                            }


                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <DatePicker
                                                        className="popup-date add-user-inputs"
                                                        placeholderText={generalData?.litigationFixedTitles['establish-date']}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={addYears(new Date(), -10)}
                                                        maxDate={addYears(new Date(), 10)}
                                                        isClearable={true}
                                                        selected={establishDate}
                                                        value={establishDate}
                                                        onChange={setEstablishDate}
                                                        locale={localStorage.getItem("LmsLanguage")}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <input
                                                        name="title"
                                                        value={establishNumber}
                                                        onChange={(e) => setEstablishNumber(e.target.value)}
                                                        className="add-user-inputs"
                                                        placeholder={generalData?.litigationFixedTitles['establish-number']}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            {/* <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <DatePicker
                                                        className="popup-date add-user-inputs"
                                                        placeholderText={generalData?.matterSectionsSettings['establish-date']}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={addYears(new Date(), -21)}
                                                        maxDate={addMonths(new Date(), 1)}
                                                        isClearable={true}
                                                        value={appealDate}
                                                        selected={appealDate}
                                                        onChange={setAppealDate}
                                                        locale={localStorage.getItem("LmsLanguage")}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <input
                                                        name="title"
                                                        value={appealNumber}
                                                        onChange={(e) => setAppealNumber(e.target.value)}
                                                        className="add-user-inputs"
                                                        placeholder={generalData?.matterSectionsSettings['establish-number']}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div> */}
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <DatePicker
                                                        className="popup-date add-user-inputs"
                                                        placeholderText={generalData?.litigationFixedTitles['round-date']}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={addYears(new Date(), -10)}
                                                        maxDate={addMonths(new Date(), 10)}
                                                        isClearable={true}
                                                        value={roundDate}
                                                        selected={roundDate}
                                                        onChange={setRoundDate}
                                                        locale={localStorage.getItem("LmsLanguage")}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <input
                                                        name="title"
                                                        value={roundNumber}
                                                        onChange={(e) => setRoundNumber(e.target.value)}
                                                        className="add-user-inputs"
                                                        placeholder={generalData?.litigationFixedTitles['round-number']}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <DatePicker
                                                        className="popup-date add-user-inputs"
                                                        placeholderText={generalData?.litigationFixedTitles['appeal-date']}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={addYears(new Date(), -10)}
                                                        maxDate={addMonths(new Date(), 10)}
                                                        isClearable={true}
                                                        value={appealDate}
                                                        selected={appealDate}
                                                        onChange={setAppealDate}
                                                        locale={localStorage.getItem("LmsLanguage")}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 col-md-6 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <input
                                                        name="title"
                                                        value={appealNumber}
                                                        onChange={(e) => setAppealNumber(e.target.value)}
                                                        className="add-user-inputs"
                                                        placeholder={generalData?.litigationFixedTitles['appeal-number']}
                                                    />
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 pt-2">
                                                <div className="postion-relative text-start justify-content-start w-100">
                                                    <Select
                                                        classNamePrefix="select-multiple-users"
                                                        className="select-multiple-users w-100"
                                                        isClearable={false}
                                                        styles={customStyles}
                                                        isMulti={true}
                                                        placeholder={generalData?.matterSectionsSettings['assignees']}
                                                        options={allUsers()}
                                                        onClick={() => { setActiveSelect(null) }}

                                                        value={selectedAssignees}
                                                        noOptionsMessage={() => generalData?.litigationFixedTitles['no-options']}
                                                        onChange={setSelectedAssignees}
                                                    />
                                                    {
                                                        errorsReturned?.remind_users ?
                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.remind_users}</h5>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                {
                                                    errorsReturned?.legal_type_id ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start  text-start pop-errors-text">{errorsReturned?.legal_type_id}</h5>
                                                        :
                                                        null
                                                }
                                            </div>



                                            <div className="col-12 pt-2">
                                                <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.litigationFixedTitles['description']} value={litigationDescription} onChange={(e) => setLitigationDescription(e.target.value)}></textarea>
                                                {
                                                    errorsReturned?.comment ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                        :
                                                        null
                                                }
                                                {
                                                    errorsReturned?.board_exists ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 pt-2">
                                                <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.litigationFixedTitles['assignees-summary']} value={litigationSummary} onChange={(e) => { setLitigationSummary(e.target.value) }}></textarea>
                                                {
                                                    errorsReturned?.comment ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                        :
                                                        null
                                                }
                                                {
                                                    errorsReturned?.board_exists ?
                                                        <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                        :
                                                        null
                                                }
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{editLitigation?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}