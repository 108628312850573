import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReminderPopup from './ReminderPopup'
import React, { useContext, useEffect, useState } from 'react';
import GlobalState from '../contexts/GlobalState';
import Api from './Api';
import ReminderCard from './ReminderCard';
import { formatDate } from '../Helpers';
import { CalendarNextLabel, CalendarPreviousLabel } from './Icons';

export default function LitigationsReminder(props) {
    const {
        addReminderGlobal,
        generalDataGlobal,
        activeMatter, setActiveMatter
    } = useContext(GlobalState);
    const [generalData,] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [selectedDate, setSelectedDate] = useState();
    const [initialDate,] = useState(new Date());
    const [addReminder, setAddReminder] = addReminderGlobal;
    const [currentReminderEdit, setCurrentReminderEdit] = useState(null);
    const [currentReminderSnooze, setCurrentReminderSnooze] = useState(null);


    const marks = props.marks;

    //check if date should be hilighted
    const checkDateMarked = (value) => {
        if (hilightedDate(formatDate(value), marks) >= 0) {
            return "position-relative highlight";
        } else {
            return "2";
        }
    }

    //hilight date
    function hilightedDate(value, arr) {
        for (var i = 0; i < arr?.length; i++) {
            if (arr[i] === value) {
                return i;
            }
        }
        return -1;
    }

    //get client data when date changes
    useEffect(() => {
        if (selectedDate && initialDate && (selectedDate !== initialDate)) {
            setGeneralDataApiOptions({
                url: 'get-matter-data',
                method: 'post',
                data: {
                    user_id: props.litigationId,
                    id: props.litigationId,
                    type: 'litigations',
                    reminder_date_selected: selectedDate ? formatDate(selectedDate) : '',
                },
                callback: res => {
                    setActiveMatter(res.data);
                }
            })
        }
    }, [selectedDate]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="container-fluid documents-div pt-3">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col d-flex flex-row">
                                <div className="d-flex align-items-center">
                                    <h1 className="m-0">{generalData?.website_titles['reminder']}</h1>
                                    <img style={{ height: "30px" }} className="cursor-pointer shadow ms-2" src={'/assets/images/plus.svg'} onClick={(e) => setAddReminder(true)} alt="add" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-12 col-md-5 col-xl-4 position-relative">
                        <Calendar
                            locale={localStorage.getItem('LmsLanguage')}
                            className="reminders-calendar pb-4"
                            formatWeekday={(locale, day) => formatDate(new Date())}
                            tileClassName={(e) => checkDateMarked(e?.date)}
                            onChange={(e) => { setSelectedDate(e) }}
                            value={selectedDate}
                            prevLabel={<CalendarPreviousLabel/>}
                            nextLabel={<CalendarNextLabel/>}
                        />
                        <p className="calendar-reminders-legend color-red">{activeMatter?.reminders?.length} Reminders</p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-8 pt-3 pt-md-0 pb-3">
                        {
                            !selectedDate &&
                            <h5 className='fw-700  mt-4 mb-2'>{generalData?.website_titles['today']}</h5>
                        }
                        {
                            activeMatter?.reminders?.length > 0 ?
                                activeMatter?.reminders?.map((singleRem, indRem) => (
                                    <ReminderCard
                                        setCurrentReminderSnooze={setCurrentReminderSnooze}
                                        currentReminderSnooze={currentReminderSnooze}
                                        selectedDate={selectedDate}
                                        setCurrentReminderEdit={setCurrentReminderEdit}
                                        currentReminderEdit={currentReminderEdit}
                                        key={indRem}
                                        indRem={indRem}
                                        singleRem={singleRem}
                                        litigationId={props.litigationId}
                                    />
                                ))
                                :
                                <p>{generalData?.website_titles['no-reminders-for-this-day']}</p>
                        }
                        {
                            !selectedDate &&
                            <>

                                <h5 className='fw-700 mt-4 mb-2'>{generalData?.website_titles['upcoming']}</h5>
                                {
                                    activeMatter?.upcomingReminders?.length > 0 ?
                                        activeMatter?.upcomingReminders?.map((singleRem, indRem) => (
                                            <ReminderCard
                                                setCurrentReminderSnooze={setCurrentReminderSnooze}
                                                currentReminderSnooze={currentReminderSnooze}
                                                selectedDate={selectedDate}
                                                setCurrentReminderEdit={setCurrentReminderEdit}
                                                currentReminderEdit={currentReminderEdit}
                                                key={indRem}
                                                indRem={indRem}
                                                singleRem={singleRem}
                                                litigationId={props.litigationId}
                                            />
                                        ))
                                        :
                                        <p>{generalData?.website_titles['no-reminders-for-this-day']}</p>
                                }
                            </>


                        }
                    </div>
                </div>
            </div>
            {
                //reminder popup
                addReminder ?
                    <ReminderPopup selectedDate={selectedDate} marks={activeMatter?.marks} litigationId={props.litigationId} />
                    :
                    null
            }
            <Api options={generalDataApiOptions} />
        </>
    )
}