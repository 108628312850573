import { useContext, useEffect, useState } from "react";
import Select from 'react-select';
import Api from "./Api";
import GlobalState from '../contexts/GlobalState';
import "react-datepicker/dist/react-datepicker.css";
import 'react-phone-number-input/style.css';
import DatePicker from 'react-datepicker';
import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { getIndex, addYears, formatDateTime, getOptions } from "../Helpers";
import RelationsSelect from "./RelationsSelect";
import { AttachIcon, CloseIcon } from "./Icons";
import LockUnlockComponent from "./LockUnlockComponent";
registerLocale('ar', ar);

export default function MeetingPopupComponent(props) {
    const {
        editMeeting, setEditMeeting,
        onSuccess, handleClose, setAddPopup
    } = props;

    const {
        authUserGlobal: [authUser],
        generalDataGlobal,
        generalDataGlobal: [{ meetingTypes }]
    } = useContext(GlobalState);

    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});


    //custom select styles
    const customStyles = {
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A"
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontFamily: 'Poppins',
                fontWeight: '400',
                color: '#00366A',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                "&:hover": {
                    backgroundColor: "#f2f9fc"
                },
            };
        },
        singleValue: base => ({
            ...base,
            backgroundColor: "red"
        }),
        control: provided => ({
            ...provided,
            minHeight: 40,
            width: '100%',
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: '#393939' },
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "1px",
        }),
        input: (base, state) => ({
            ...base,
            color: '#00366A',
            fontSize: "14px",
            height: "100%",
            fontFamily: 'Poppins',
            fontWeight: '400',
            marginTop: "-8px",
            '& input': {
                font: 'inherit',
            },
        })
    };
    // custom styles for the select
    const customStylesPopup = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#07407C',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: '#07407C',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                maxWidth: "100%",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            height: "40px",
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "100%",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };


    // form states

    const [selectedMeetingType, setSelectedMeetingType] = useState();
    const [name, setName] = useState(editMeeting?.name || '');
    const [selectedMeetingDate, setSelectedMeetingDate] = useState('');
    const [location, setLocation] = useState(editMeeting?.location || '');
    const [note, setNote] = useState(editMeeting?.note || '');
    const [selectedRelation, setSelectedRelation] = useState();
    const [clientType, setClientType] = useState(editMeeting?.client?.client_type_id);
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();
    const [fileName, setFileName] = useState(editMeeting?.uploaded_files ? editMeeting?.uploaded_files?.file_name : '');
    const [publicState, setPublicState] = useState(editMeeting?.public);
    const [selectedAssignees, setSelectedAssignees] = useState([])
    const [errorsReturned, setErrorsReturned] = useState({});


    //handle document upload
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0]);
            setDocumentLink(file);
        }
    }

    //get individual realtions
    const individualRelations = () => {
        const relOptions = authUser?.individual_only?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get company relations
    const companyRelations = () => {
        const relOptions = authUser?.company_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get ngro relations
    const ngoRelations = () => {
        const relOptions = authUser?.ngo_relations?.map(
            singleRel => ({ type: singleRel.type, value: singleRel.slug + '-' + singleRel.id, label: singleRel?.title, slug: singleRel.slug, image: singleRel.image, client_type: singleRel.client_type }))
        return relOptions;
    }

    //get users for assignees or remind users
    const allUsers = () => {
        const curren = generalData?.users?.map(
            singleOption => ({ value: singleOption.id, label: singleOption?.name, image:singleOption?.full_path_picture }))
        return curren;
    }

    useEffect(() => {
        if (editMeeting && editMeeting?.id) {

            setSelectedMeetingType(getOptions(meetingTypes)[getIndex(editMeeting?.meeting_type_id, getOptions(meetingTypes), 'value')])

            setSelectedRelation(
                Number(editMeeting?.client?.client_type_id) === 1 ?
                    companyRelations()[getIndex('client-' + editMeeting?.client?.id, companyRelations(), 'value')]
                    :
                    Number(editMeeting?.client?.client_type_id) === 2 ?
                        individualRelations()[getIndex('client-' + editMeeting?.client?.id, individualRelations(), 'value')]
                        :
                        Number(editMeeting?.client?.client_type_id) === 3 ?
                            ngoRelations()[getIndex('client-' + editMeeting?.client?.id, ngoRelations(), 'value')]
                            :
                            ''
            )
            setSelectedMeetingDate(editMeeting?.date ? new Date(editMeeting?.date.replace(/-/g, "/")) : '');
            setDocumentLink(editMeeting?.file || '')
            setFileName(editMeeting?.uploaded_files?.file_name || '')

            if (editMeeting?.assignees?.length > 0) {
                setSelectedAssignees(
                    editMeeting?.assignees?.map(
                        singleUser => ({ value: singleUser.id, label: singleUser.name, image:singleUser?.full_path_picture })
                    )
                );
            }
        }
    }, [editMeeting]); // eslint-disable-line react-hooks/exhaustive-deps

    //reset all fields in the form
    const resetForm = (e) => {
        setSelectedMeetingType({});
        setName('');
        setSelectedMeetingDate('');
        setLocation('');
        setNote('');
        setSelectedRelation({});
        setClientType({});
        setDocument('');
        setDocumentLink('');
        setFileName('');
        setPublicState(0);
        setSelectedAssignees({});
        setEditMeeting(null)
    }

    //submit meeting to backend
    const submiteMeeting = (e) => {
        e.preventDefault();
        let fd = new FormData();
        fd.append('meeting_type_id', selectedMeetingType?.value || '')
        fd.append('meeting_id', editMeeting?.id || '');
        fd.append('name', name || '')
        fd.append('location', location);
        fd.append('date', selectedMeetingDate ? formatDateTime(selectedMeetingDate) : '');
        fd.append('public', publicState);
        fd.append('client_id', selectedRelation?.value?.split('-')[1] || '');

        if (selectedAssignees?.length > 0) {
            fd.append('assignees', (selectedAssignees?.length > 0 ? selectedAssignees?.map((single, index) => (
                single.value
            )).toString() : ''));
        }
        fd.append('note', note || '');


        if(props?.tasksType){
            fd.append('tasksType',props?.tasksType);
        }
        fd.append('force', 1);
        fd.append('parent_folder_id', '');
        fd.append('files_type_id', 5);
        fd.append('main_directory', 3);
        fd.append('file_id', editMeeting?.uploaded_files_id || '');
        fd.append('folder_name', 'Meetings');
        fd.append('from_home', 1);
        fd.append('file_name', fileName ? fileName : '');

        if (document) {
            fd.append('file', document[0]);
        }
        if(props?.returnDashboard){
            fd.append('return_dashboard',props?.returnDashboard);
        }

        if(props?.tasksType){
            fd.append('tasksType',props?.tasksType);
        }

        
        if(props?.returnEvents === 1){
            fd.append('return_events',1);
            fd.append('meetingCheckbox', props?.meetingCheckbox ? 1 : 0);
            fd.append('hearingCheckbox', props?.hearingCheckbox ? 1 : 0);
            fd.append('tasksCheckbox', props?.tasksCheckbox ? 1 : 0);
            fd.append('remindersCheckbox', props?.remindersCheckbox ? 1 : 0);
        }
        
        setGeneralDataApiOptions({
            url: 'add-edit-meeting',
            method: 'post',
            data: fd,
            callback: res => {
                if (onSuccess) {
                    onSuccess(res);
                } else {

                }
                resetForm();
                setAddPopup(false)
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        });
    }

    const handleCloseClick = () => {
        resetForm();
        if (handleClose) {
            handleClose();
        } else {
            setAddPopup({ open: false, type: '' });
        }
    }

    const Control = (props) => {
        return (
            <div className="bg-gray d-flex px-2 mb-2" {...props.innerProps}><img alt="icon"  className="me-2" style={{ width: 30, height: 30, borderRadius: '50%' }} src={props?.data?.image || generalData?.fixed_settings?.full_path_no_profile_picture} />{props?.children}</div>
        )
    }

    const ValueContainer = (props) => {
        return (
            <div style={{ backgroundColor: '#F1F4FB' }} className="d-flex p-1 ms-1 mb-1"><img alt="icon" className="me-2" style={{ width: 30, height: 30, borderRadius: '50%' }} src={props?.data?.image || generalData?.fixed_settings?.full_path_no_profile_picture} />{props?.children}</div>
        )
    }

    return (
        <>
            <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in" >
                <div className="pop-up-api-box" style={{ maxHeight: 700 }}>
                    <div className="d-flex">
                        <CloseIcon className="cursor-pointer" onClick={handleCloseClick} />
                    </div>
                    <div className="text-center ">
                        <div className="containter-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="pop-up-title m-0 py-2 color-dark-blue">{editMeeting?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']} {props?.popupTitle}</h1>
                                </div>
                                <form onSubmit={submiteMeeting}>

                                    <div className="col-12 pt-2 pb-4">
                                        <>
                                            <div className="row">
                                                {/* meeting type  */}
                                                <>
                                                    <div className="col-12 col-md-6 pt-2">

                                                        <Select
                                                            classNamePrefix="popup-select"
                                                            className="popup-select w-100"
                                                            styles={customStylesPopup}
                                                            options={getOptions(meetingTypes)}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            placeholder={generalData?.meetingFixedTitles['meeting-type']}
                                                            isSearchable={false}
                                                            value={selectedMeetingType ? selectedMeetingType : null}
                                                            onChange={setSelectedMeetingType}
                                                        />

                                                        {
                                                            errorsReturned?.meeting_type_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.meeting_type_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>

                                                {/* meeting name  */}
                                                <>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input
                                                            name="name"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            className="add-user-inputs"
                                                            placeholder={generalData?.website_titles['name']}
                                                        />
                                                        {
                                                            errorsReturned?.name ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.name}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>

                                                {/* meeting date  */}
                                                <>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <DatePicker
                                                            className="popup-date add-user-inputs"
                                                            placeholderText={generalData?.website_titles['date']}
                                                            value={selectedMeetingDate ? selectedMeetingDate : ''}
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            showTimeSelect
                                                            use12Hours={true}
                                                            dropdownMode="select"
                                                            selected={selectedMeetingDate}
                                                            dateFormat="d MMMM yyyy hh:mm a"
                                                            onChange={(date) => setSelectedMeetingDate(date)}
                                                            maxDate={addYears(new Date(), 21)}
                                                            minDate={new Date()}
                                                            locale={localStorage.getItem("LmsLanguage")}
                                                        />
                                                        {
                                                            errorsReturned?.date ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.date}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>

                                                {/* meeting location  */}
                                                <>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <input
                                                            name="location"
                                                            value={location}
                                                            onChange={(e) => setLocation(e.target.value)}
                                                            className="add-user-inputs"
                                                            placeholder={generalData?.meetingFixedTitles['location']}
                                                        />
                                                        {
                                                            errorsReturned?.location ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.location}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>

                                                {/* meeting file  */}
                                                <>
                                                    <div className="col-12 col-md-6 pt-2 position-relative" style={{ zIndex: 9 }}>
                                                        <input
                                                            hidden
                                                            id='upload-operation-file'
                                                            type="file"
                                                            onChange={(e) => { documentHandler(e.target.files) }}
                                                        />
                                                        <label
                                                            className="pop-upload-file position-relative"
                                                            htmlFor="upload-operation-file"
                                                        >
                                                            {documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}
                                                        </label>

                                                        <AttachIcon />
                                                        {
                                                            errorsReturned?.file ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className="col-12 col-md-6 pt-2 position-relative" style={{ zIndex: 9 }}>
                                                        <input
                                                            className='add-user-inputs'
                                                            id='file_name'
                                                            type="text"
                                                            placeholder={generalData?.website_titles['file-name']}
                                                            onChange={(e) => { setFileName(e.target.value) }}
                                                            value={fileName}
                                                        />
                                                        {
                                                            errorsReturned?.file_name ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_name}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>

                                                {/* assigned to  */}
                                                <>
                                                    <div className="col-12 col-md-12 pt-2">
                                                        <div className="w-100 h-100 position-relative">
                                                            <Select
                                                                classNamePrefix="select-multiple-users"
                                                                className="select-multiple-users w-100"
                                                                isClearable={false}
                                                                styles={customStyles}
                                                                isMulti={true}
                                                                placeholder={generalData?.mattersCommonTitles['assignees']}
                                                                options={allUsers()}
                                                                value={selectedAssignees}
                                                                onChange={setSelectedAssignees}
                                                                components={{ Option: Control, MultiValueContainer: ValueContainer }}

                                                            />
                                                            <LockUnlockComponent
                                                                onChange={(isPublic) => { setPublicState(isPublic) }}
                                                                defaultState={publicState}
                                                            />
                                                            {
                                                                errorsReturned?.assignees ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.assignees}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                    </div>
                                                </>

                                                {/* client select */}
                                                <>
                                                    <div className="col-12 col-md-6 pt-2">
                                                        <div className="postion-relative text-start justify-content-start w-100" >
                                                            <RelationsSelect
                                                                selectedRelation={selectedRelation}
                                                                setSelectedRelation={setSelectedRelation}
                                                                profile={authUser}
                                                                excludeMatters
                                                                // placeholder={selectedShareOperation && selectedShareOperation.slug != 'initial-subscription' && generalData?.shareholdersFixedTitles['to']}
                                                                defaultSelected={clientType ? (clientType === 1 ? 'company' : (clientType === 2 ? 'individual' : 'ngo')) : 'individual'}
                                                            // isDisabled={!!props.operation}
                                                            />

                                                        </div>
                                                        {
                                                            errorsReturned?.to_id ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.to_id}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>

                                                {/* note  */}
                                                <>
                                                    <div className="col-12 pt-2">
                                                        <textarea type="text-area" name="description" className="add-user-inputs" placeholder={generalData?.meetingFixedTitles['note']} value={note} onChange={(e) => { setNote(e.target.value) }}></textarea>
                                                        {
                                                            errorsReturned?.comment ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.comment}</h5>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            errorsReturned?.board_exists ?
                                                                <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.board_exists}</h5>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </>

                                            </div>
                                        </>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="popup-button my-auto">{editMeeting?.id ? generalData?.website_titles['edit'] : generalData?.website_titles['add']}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>
    )
}