import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import Api from "../components/Api";
import axios from "axios";
import { PasswordHidden, PasswordVisible } from "../components/Icons";

export default function ForgotPassword() {

    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const { generalDataGlobal, authUserGlobal } = useContext(GlobalState);
    const [passwordVisible, setPasswordVisible] = useState(0);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(0);
    const [errorsReturned, setErrorsReturned] = useState({});
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [generalData] = generalDataGlobal;
    const [, setAuthUser] = authUserGlobal;
    const history = useNavigate();

    let { email, token } = useParams();

    //submit reset password request
    const submitResetPassword = (e) => {
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'reset-password',
            method: 'post',
            data: {
                email: email,
                token: token,
                password: password,
                password_confirmation: confirmPassword,
            },
            callback: res => {
                axios.defaults.headers.common['Authorization'] = res.data.token;
                setAuthUser(res.data.user);
                localStorage.setItem('auth_token', res.data.token);
                history('/');
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                <div className="col-12 col-md-6 full-screen-min light-bg-color d-flex align-items-center justify-content-center d-none d-md-flex">
                        <img className="logo-web" alt="Logo Web" src="https://hellotree-lms-demo.hellotree.dev/asset?path=images/logo.png" />
                    </div>
                    <div className="col-12 col-md-6 full-screen-min dark-blue-bg d-flex align-items-center justify-content-center py-4 py-md-0">
                        <div className="row w-100 justify-content-center">
                            <div className="col-12 d-flex d-md-none small-screens-auth-logo py-4 pt-md-0 justify-content-center">
                                <img className="logo-mobile" class="logo-web" alt="Logo Web" src="https://hellotree-lms-demo.hellotree.dev/asset?path=images/logo.png" />
                            </div>
                            <div className="col-12 col-md-8 col-lg-5 text-center">
                                <h1 className="login-title white-text pb-4">{generalData?.authentication_pages_settings['reset-password-title']}</h1>
                                <h1 className="login-subtitle white-text pb-4">{generalData?.authentication_pages_settings['reset-password-subtitle']}</h1>
                                <form className="w-100" onSubmit={submitResetPassword}>
                                    <div className="row w-100 m-0">
                                        <div className="col-12 pb-3">
                                            <div className="col-12 pb-3 position-relative">
                                                <input type={passwordVisible ? 'text' : 'password'} name="password" className={errorsReturned?.password ? 'auth-input w-100 border-red' : 'auth-input w-100'} placeholder={generalData?.authentication_pages_settings['new-password']} onChange={e => setPassword(e.target.value)} value={password} />
                                                <div className="pass-icon" onClick={() => passwordVisible === 0 ? setPasswordVisible(1) : setPasswordVisible(0)}>
                                                    {
                                                        passwordVisible ?
                                                            <PasswordVisible />
                                                            :
                                                            <PasswordHidden />

                                                    }
                                                </div>
                                                {
                                                    errorsReturned?.password ?
                                                        <p className="m-0 errors-auth">{errorsReturned.password}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className="col-12 pb-3 position-relative">
                                                <input type={confirmPasswordVisible ? 'text' : 'password'} name="password_confirmation" className={errorsReturned?.confirm_password ? 'auth-input w-100 border-red' : 'auth-input w-100'} placeholder={generalData?.authentication_pages_settings['confirm-new-password']} onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} />
                                                <div className="pass-icon" onClick={() => confirmPasswordVisible === 0 ? setConfirmPasswordVisible(1) : setConfirmPasswordVisible(0)}>
                                                    {
                                                        confirmPasswordVisible ?
                                                            <PasswordVisible />
                                                            :
                                                            <PasswordHidden />

                                                    }
                                                </div>
                                                {
                                                    errorsReturned?.password_confirmation ?
                                                        <p className="m-0 errors-auth">{errorsReturned.password_confirmation}</p>
                                                        :
                                                        null
                                                }
                                                {
                                                    errorsReturned && !errorsReturned?.password && !errorsReturned?.password_confirmation ?
                                                        <p className="m-0 errors-auth">{errorsReturned[0]}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 pt-4 d-flex justify-content-center">
                                            <button className="auth-button">{generalData?.authentication_pages_settings['reset-password-button']}</button>
                                        </div>
                                        <div className="col-12 pt-4 d-flex justify-content-center">
                                            <Link to="/sign-in" className="forgot-pass-text">{generalData?.authentication_pages_settings['back-to-login']}</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Api options={generalDataApiOptions} />
        </>

    )
}