import { useContext, useState, useEffect } from "react";
import GlobalState from '../contexts/GlobalState';
import { Link } from "react-router-dom";
import React from 'react';
import Api from "../components/Api";
import Select from 'react-select';
import Breadcrumb from "./Breadcrumb";
import { Draggable, Droppable } from 'react-drag-and-drop';
import DatePicker from "react-datepicker";
import { getIndex, addYears, formatDate } from '../Helpers';


import { registerLocale } from "react-datepicker";
import ar from 'date-fns/locale/ar';
import { AttachIcon, CloseIcon, FileIcon, FolderIcon, ShareMailIcon, ShareMailIconHovered, ShareWhatsapIcon, ShareWhatsappIconHovered, ThreeDotsMenu } from "./Icons";
registerLocale('ar', ar)

export default function Documents(props) {
    const { generalDataGlobal, draggedElementGlobal, dropElementGlobal, movePopupGlobal } = useContext(GlobalState);
    const [generalData] = generalDataGlobal;
    const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
    const [errorsReturned, setErrorsReturned] = useState({});
    const [deleteFolderPopup, setDeleteFolderPopup] = useState(null);
    const [openEditFolderMenu, setOpenEditFolderMenu] = useState(null);
    const [editFolder, setEditFolder] = useState();
    const [returnedFiles, setReturnedFiles] = useState({});

    const [currentFolder, setCurrentFolder] = useState();
    const [userFolders, setUserFolders] = useState({});
    const [addFolder, setAddFolder] = useState(false);
    const [folderName, setFolderName] = useState();
    const [counter, setCounter] = useState(0);

    const [uploadFilePopup, setUploadFilePopup] = useState(false);
    const [fileName, setFileName] = useState('');
    const [fileType, setFileType] = useState('');
    const [fileComment, setFileComment] = useState('');
    const [document, setDocument] = useState();
    const [documentLink, setDocumentLink] = useState();

    const [fileMenuOpen, setFileMenuOpen] = useState();
    const [deleteFilePopup, setDeleteFilePopup] = useState();
    const [editingFile, setEditingFile] = useState();
    const [shareLink, setShareLink] = useState();
    const [hoveredSocial, setHoveredSocial] = useState();
    const [copiedText, setCopiedText] = useState();
    const [draggedElement, setDraggedElemet] = draggedElementGlobal;
    const [dropElement, setDropElememt] = dropElementGlobal;
    const [movePopup, setMovePopup] = movePopupGlobal;
    const [shortcutFolders, setShortcutFolders] = useState([]);

    const [fileDate, setFileDate] = useState(new Date());
    //handle document upload
    const documentHandler = (files) => {
        if (files.length > 0) {
            setDocument(files);
            let file = URL.createObjectURL(files[0]);
            setDocumentLink(file);
        }
    }

    //custom select styles
    const customStyles = {
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                display: "flex",
                fontFamily: 'Poppins',
                fontWeight: '400',
                textTransform: "capitalize",
                fontSize: '14px',
                color: '#00366A',
            }
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                display: "flex",
                color: '#00366A',
                fontFamily: 'Poppins',
                fontWeight: '400',
                backgroundColor: 'white',
                textTransform: "capitalize",
                margin: "auto",
                paddingRight: "10px",
                paddingLeft: "10px",
                paddingBottom: "0",
                paddingTop: "0",
            };
        },
        singleValue: base => ({
            ...base,
            color: "#00366A",
            display: "flex",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: "400",
            textTransform: "capitalize",
        }),
        control: provided => ({
            ...provided,
            border: "none",
            backgroundColor: 'white',
            outline: "none",
            '&:hover': { borderColor: 'transparent' },
            textTransform: 'uppercase',
            width: "100%",
            borderRadius: "7px",
            fontFamily: 'Poppins',
            fontWeight: '400',
            minHeight: "40px",
            height: "40px",
            boxShadow: "0px 3px 6px rgb(0 0 0 / 16%)",
        }),
        menu: (provided, state) => ({
            ...provided,
            borderRadius: "7px",
            textTransform: 'uppercase',
            border: "1px solid transparent",
        }),
        dropdownIndicator: base => ({
            ...base,
            color: "#00366A !important",
            // marginTop: "-18px !important"
        }),
        input: (base, state) => ({
            ...base,
            color: "#393939",
            fontSize: "16px",
            height: "40px",
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            fontWeight: '400',
            marginTop: "14px",
            '& input': {
                font: 'inherit',
            },
        })
    };

    //handle drop element
    const onDrop = (e, single) => {
        setDropElememt(single);
        if ((single === null && draggedElement !== null) || (single?.id !== draggedElement?.id)) {
            setMovePopup(true);
        }
    };

    //handle drag start
    const onDragStart = (e, single) => {
        if (single?.folder_slug && (single?.folder_slug === 'signatures' || single?.folder_slug === 'proxies' || single?.folder_slug === 'personal-documents' || single?.folder_slug === 'board-members')) {
        } else {
            setDraggedElemet(single);
        }
    };

    //handle close file popup close and reset states
    const closeFilePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setUploadFilePopup(false);
        setFileComment('');
        setFileName('');
        setFileType('');
        setErrorsReturned({});
        setEditFolder();
        setFileType('');
        setFileName('');
        setDocument('');
        setEditingFile('');
        setFileMenuOpen('');
        setDocumentLink('');
        setUploadFilePopup(false);
        setFileComment('');
        setFileDate(new Date());
    }

    //get all files types
    const allFileTypes = () => {
        const FileTypes = generalData?.files_type?.map(
            singleFile => ({ value: singleFile.id, label: singleFile.title }))
        return FileTypes;
    }

    //get directories list
    useEffect(() => {
        setGeneralDataApiOptions({
            url: 'directories-list',
            method: 'post',
            data: {
                user_id: props.currentUser,
                main_directory: props.mainFolder,
                parent_folder_id: props.currentFolder,
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
                setUserFolders(res.data?.folders);
                setCurrentFolder(res.data?.current_folder ? res.data?.current_folder[0] : null);
                setShortcutFolders(res?.data?.shortcutFolders);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                }
            }
        })
    }, [counter, props.currentFolder]);// eslint-disable-line react-hooks/exhaustive-deps


    // create folder submit
    const createFolder = (e) => {
        e.preventDefault();

        setGeneralDataApiOptions({
            url: 'new-directory',
            method: 'post',
            data: {
                parent_folder_id: props.currentFolder,
                main_directory: props.mainFolder,
                user_id: props.user_id,
                folder_name: folderName,
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
                setUserFolders(res.data?.folders);
                setCurrentFolder(res.data?.current_folder ? res.data?.current_folder[0] : null);
                setAddFolder(false);
                setFolderName();
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    }

    //delete folder process
    const deleteFolderProcess = (e, folderId) => {
        e.preventDefault();
        e.stopPropagation();
        setDeleteFolderPopup(folderId);
    }

    //file delete click 
    const fileDeleteClick = (e, single) => {
        e.preventDefault();
        e.stopPropagation();
        setDeleteFilePopup(single);
    }

    //cancel delete folder
    const cancelDeleteFolder = () => {
        setDeleteFolderPopup(null);
        setOpenEditFolderMenu(null)
    }

    //submit files
    const submitFiles = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let fd = new FormData();
        if (props.currentFolder) {
            fd.append('parent_folder_id', props.currentFolder);
        }
        fd.append('main_directory', props.mainFolder);
        fd.append('user_id', props.user_id);
        fd.append('folder_id', currentFolder);

        if (editingFile?.id) {
            fd.append('file_id', editingFile?.id);
        }

        fd.append('file_date', formatDate(fileDate));
        fd.append('file_name', fileName);
        fd.append('files_type_id', fileType ? fileType?.value : '');
        fd.append('comment', fileComment);

        if (document) {
            fd.append('file', document[0]);
        }

        setGeneralDataApiOptions({
            url: 'submit-files',
            method: 'post',
            data: fd,
            callback: res => {
                setReturnedFiles(res.data?.files);
                setUserFolders(res.data?.folders);
                setCurrentFolder(res.data?.current_folder ? res.data?.current_folder[0] : null);
                setErrorsReturned({});
                setFileType('');
                setFileName('');
                setDocument('');
                setEditingFile('');
                setFileMenuOpen('');
                setDocumentLink('');
                setUploadFilePopup(false);
                setFileComment('');
                setFileDate(new Date());

            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    };

    //edit menu
    const openFilesEditMenu = (e, single) => {
        e.stopPropagation();
        e.preventDefault();
        if (fileMenuOpen?.id === single?.id) {
            setFileMenuOpen();
        } else {
            setFileMenuOpen(single);
        }
    }

    //edit folder
    const submitEditFolder = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setGeneralDataApiOptions({
            url: 'rename-directory',
            method: 'post',
            data: {
                parent_folder_id: props.currentFolder,
                main_directory: props.mainFolder,
                user_id: props.user_id,
                folder_name: folderName,
                folder_id: editFolder?.id,
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
                setUserFolders(res.data?.folders);
                setCurrentFolder(res.data?.current_folder ? res.data?.current_folder[0] : null);
                setOpenEditFolderMenu(null);
                setDeleteFolderPopup(null);
                setErrorsReturned({});
                setEditFolder();
                setFolderName();
            },
            catch: err => {
                if (err) {
                    setOpenEditFolderMenu(null);
                    setErrorsReturned(err);
                }
            }
        })
    }

    //delete folder submit
    const deleteFolder = (folderId) => {
        setGeneralDataApiOptions({
            url: 'delete-directory',
            method: 'post',
            data: {
                folder_id: folderId,
                main_directory: props.mainFolder,
                parent_folder_id: props.currentFolder ? props.currentFolder : null,
                user_id: props.currentUser,
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
                setUserFolders(res.data?.folders);
                setCurrentFolder(res.data?.current_folder ? res.data?.current_folder[0] : null);
                setOpenEditFolderMenu(null);
                setDeleteFolderPopup(null);
            },
            catch: err => {
                if (err) {
                    setOpenEditFolderMenu(null);
                }
            }
        })
    }

    //set states with current file data
    const editFile = (e, fileInfo) => {
        e.preventDefault();
        e.stopPropagation();
        setFileComment(fileInfo?.comment ? fileInfo?.comment : '');
        setEditingFile(fileInfo);
        setUploadFilePopup(true);
        setDocumentLink(fileInfo?.full_path_file);
        setFileName(fileInfo?.file_name);
        setFileDate(fileInfo?.file_date ? new Date(fileInfo?.file_date) : '');
        setFileType(fileInfo?.files_type_id ? allFileTypes()[getIndex(fileInfo?.files_type_id, allFileTypes(), 'value')] : '');
    };

    //delete file post to backend
    const deleteFile = (fileId) => {
        setGeneralDataApiOptions({
            url: 'delete-file',
            method: 'post',
            data: {
                file_id: fileId,
                main_directory: props.mainFolder,
                parent_folder_id: props.currentFolder ? props.currentFolder : null,
                user_id: props.currentUser,
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
                setUserFolders(res.data?.folders);
                setCurrentFolder(res.data?.current_folder ? res.data?.current_folder[0] : null);
                setFileMenuOpen(null);
                setDeleteFilePopup(null);
            },
            catch: err => {
                if (err) {
                    setFileMenuOpen(null);
                }
            }
        })
    }

    //submit move file or folder to backend
    const submitMove = () => {
        setGeneralDataApiOptions({
            url: 'move-files-folders',
            method: 'post',
            data: {
                dragged_item: draggedElement,
                dropped_element: dropElement,
                parent_folder_id: props.currentFolder ? props.currentFolder : null,
                main_directory: props.mainFolder,
                user_id: props.currentUser,
            },
            callback: res => {
                setReturnedFiles(res.data?.files);
                setUserFolders(res.data?.folders);
                setCurrentFolder(res.data?.current_folder ? res.data?.current_folder[0] : null);
                setDropElememt();
                setDraggedElemet();
                setMovePopup(false);
                setErrorsReturned({});
            },
            catch: err => {
                if (err) {
                    setErrorsReturned(err);
                }
            }
        })
    }

    //set states with current folder data
    function editFolderClick(e, single) {
        e.preventDefault();
        e.stopPropagation();
        if (single?.id) {
            setEditFolder(single);
            setFolderName(single?.folder_name);
            setErrorsReturned({});
        }
    }



    return (
        <>
            <div className="container-fluid documents-div">
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col d-flex flex-row">
                                <div className="col-auto my-auto">
                                    <div className="d-flex lower-op w-auto">
                                        <Link to={'/' + props.mainRoute + '/' + props.currentUser + '/documents/'}>
                                            <Droppable className="" types={['folder', 'file']} onDrop={(e) => onDrop(e, null)}>
                                                <h1 className={`m-0 lower-opacity ${(currentFolder?.parent?.id || currentFolder?.folder_name) ? '' : 'active'}`} >
                                                    {generalData?.documentsSectionSettings['folders-title']} &nbsp;
                                                </h1>
                                            </Droppable>
                                        </Link>
                                        {
                                            currentFolder ?
                                                <>
                                                    {
                                                        <>

                                                            {
                                                                currentFolder?.parent?.id ?
                                                                    <Breadcrumb currentFolder={props.currentFolder} mainRoute={props.mainRoute} subRoute={props.subRoute} currentUser={props.currentUser} breadcrumb={currentFolder?.parent} />
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                    }

                                                    <Link to={'/' + props.mainRoute + '/' + props.currentUser + '/documents/' + currentFolder?.id}>
                                                        <h1 className="m-0 active">
                                                            /{currentFolder?.folder_name} &nbsp;
                                                        </h1>
                                                    </Link>
                                                </>
                                                :
                                                null
                                        }
                                    </div>

                                    {/* <h1 className="m-0 d-flex">{generalData?.documentsSectionSettings['folders-title']}</h1> */}
                                </div>
                                <div className="col">
                                    <div className="row justify-content-end">
                                        <div className="col-auto pe-md-0">
                                            {
                                                currentFolder?.folder_slug === 'signatures' || currentFolder?.folder_slug === 'proxies' || currentFolder?.folder_slug === 'personal-documents' || currentFolder?.folder_slug === 'board-members' || currentFolder?.folder_slug === 'shares-operations' || currentFolder?.folder_slug === 'hearings' ?
                                                    null
                                                    :
                                                    <>
                                                        <div className="d-flex ">
                                                            <button className="px-3 popup-button w-auto" onClick={(e) => setAddFolder(true)}>{generalData?.documentsSectionSettings['create-folder-main-button']}</button>
                                                            {
                                                                (Number(props.mainFolder) === 2 || Number(props?.mainFolder) === 3) &&
                                                                <>
                                                                    {
                                                                        props?.mainRoute ?
                                                                            <>
                                                                                <a className="px-3 popup-button w-auto ms-2" href={`/${props?.mainRoute}/${props.currentUser}/documents/history`}>{generalData?.documentsSectionSettings['view-history']}</a>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <a className="px-3 popup-button w-auto ms-2" href={`/${Number(props.mainFolder) === 1 ? 'profile' : 'client'}/${props.currentUser}/documents/history`}>{generalData?.documentsSectionSettings['view-history']}</a>
                                                                            </>
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col pb-2 d-flex flex-row">

                            </div>
                        </div>
                        <div className="row py-3">
                            {
                                userFolders?.length > 0 ?
                                    userFolders?.map((singleFolder, index) => (
                                        <div className="col-auto pb-3 full-width-folder" key={index} onClick={() => setCounter(1)}>
                                            <Link to={'/' + props.mainRoute + '/' + props.currentUser + '/documents/' + singleFolder?.id}>
                                                {
                                                    singleFolder?.folder_slug === 'signatures' || singleFolder?.folder_slug === 'proxies' || singleFolder?.folder_slug === 'personal-documents' || singleFolder?.folder_slug === 'shares-operations' || singleFolder?.folder_slug === 'board-members' || singleFolder?.folder_slug === 'hearings' ?
                                                        <div className="h-100">
                                                            <div className="h-100">
                                                                <div className="h-100 folder-div d-flex flex-column position-relative">
                                                                    <div className="d-flex align-items-center">
                                                                        <FolderIcon />
                                                                        <p className="number-files-title ps-1 pt-0 m-0 word-break fs-12 color-red">
                                                                            {
                                                                                singleFolder?.folder_slug === 'signatures' ?
                                                                                    generalData?.website_titles['edit-in-signature-authority'] :
                                                                                    singleFolder?.folder_slug === 'shares-operations' ?
                                                                                        generalData?.website_titles['edit-in-shareholders-section'] :
                                                                                        singleFolder?.folder_slug === 'board-members' ?
                                                                                            generalData?.website_titles['edit-in-board-members-section'] :
                                                                                            singleFolder?.folder_slug === 'hearings' ?
                                                                                                generalData?.website_titles['edit-in-hearing'] :
                                                                                                generalData?.website_titles['edit-in-general-info']
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                    <p className="folder-title pt-2 m-0 word-break">{singleFolder?.folder_name}</p>
                                                                    <p className="number-files-title m-0">{singleFolder?.files?.length} {singleFolder?.files?.length > 1 ? generalData?.documentsSectionSettings['files-counter'] : generalData?.documentsSectionSettings['file-counter']}</p>
                                                                    <p className="number-files-title m-0">{singleFolder?.sub_folders?.length} {generalData?.documentsSectionSettings['folders-title']}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <Droppable className="h-100" types={['folder', 'file']}
                                                            onDrop={(e) => onDrop(e, singleFolder)}>
                                                            <Draggable className="h-100" onDragStart={(e) => onDragStart(e, singleFolder)} type="folder" data={singleFolder}>
                                                                <div className="h-100 folder-div d-flex flex-column position-relative">
                                                                    <FolderIcon />
                                                                    {
                                                                        singleFolder?.folder_slug === 'signatures' || singleFolder?.folder_slug === 'proxies' || singleFolder?.folder_slug === 'personal-documents' || currentFolder?.folder_slug === 'shares-operations' || currentFolder?.folder_slug === 'board-members' || currentFolder?.folder_slug === 'hearings' ?
                                                                            null
                                                                            :
                                                                            <>
                                                                                <ThreeDotsMenu onClick={(e) => { e.stopPropagation(); e.preventDefault(); openEditFolderMenu === null ? setOpenEditFolderMenu(singleFolder) : setOpenEditFolderMenu(null) }} className="folders-buttons" />
                                                                                <div className={"settings-clickmenu text-start " + (singleFolder?.id === openEditFolderMenu?.id ? ' d-block' : '')}>
                                                                                    <p style={{ zIndex: "99" }} onClick={(e) => { e.preventDefault(); editFolderClick(e, singleFolder) }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['edit-placeholder']}</p>
                                                                                    <p style={{ zIndex: "200" }} onClick={(e) => { e.preventDefault(); deleteFolderProcess(e, singleFolder?.id) }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['delete-placeholder']}</p>
                                                                                </div>
                                                                            </>
                                                                    }
                                                                    <p className="folder-title pt-2 m-0 word-break">{singleFolder?.folder_name}</p>
                                                                    <p className="number-files-title m-0">{singleFolder?.files?.length} {singleFolder?.files?.length > 1 ? generalData?.documentsSectionSettings['files-counter'] : generalData?.documentsSectionSettings['file-counter']}</p>
                                                                    <p className="number-files-title m-0">{singleFolder?.sub_folders?.length} {generalData?.documentsSectionSettings['folders-title']}</p>
                                                                </div>
                                                            </Draggable>
                                                        </Droppable>
                                                }
                                            </Link>
                                        </div>
                                    ))
                                    :
                                    null
                                    // (currentFolder?.folder_slug !== 'signatures' || currentFolder?.folder_slug !== 'proxies' || currentFolder?.folder_slug !== 'personal-documents' || currentFolder?.folder_slug !== 'board-members' || currentFolder?.folder_slug !== 'shares-operations' || currentFolder?.folder_slug !== 'hearings' || currentFolder?.folder_slug !== 'tasks') &&
                                    // <p>{generalData?.documentsSectionSettings['no-folders']}</p>
                            }
                            {
                                shortcutFolders?.length > 0 ?
                                    shortcutFolders?.map((singleFolder, index) => (
                                        <div className="col-auto pb-3 full-width-folder" key={index} onClick={() => setCounter(1)}>
                                            <Link to={'/' + props.mainRoute + '/' + props.currentUser + '/documents/' + singleFolder?.id}>
                                                {
                                                    <div className="h-100">
                                                        <div className="h-100">
                                                            <div className="h-100 folder-div d-flex flex-column position-relative">
                                                                <div className="d-flex align-items-center">
                                                                    <FolderIcon />
                                                                    <p className="number-files-title ps-1 pt-0 m-0 word-break fs-12 color-red">
                                                                        {
                                                                            singleFolder?.folder_slug === 'signatures' ?
                                                                                generalData?.website_titles['edit-in-signature-authority'] :
                                                                                singleFolder?.folder_slug === 'shares-operations' ?
                                                                                    generalData?.website_titles['edit-in-shareholders-section'] :
                                                                                    singleFolder?.folder_slug === 'board-members' ?
                                                                                        generalData?.website_titles['edit-in-board-members-section'] :
                                                                                        singleFolder?.folder_slug === 'hearings' ?
                                                                                            generalData?.website_titles['edit-in-hearing'] :
                                                                                            singleFolder?.main_directories_id === 4 ?
                                                                                                generalData?.website_titles['edit-in-tasks']
                                                                                                :
                                                                                                generalData?.website_titles['edit-in-general-info']
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <p className="folder-title pt-2 m-0 word-break">
                                                                    {
                                                                        singleFolder?.main_directories_id === 4 ?
                                                                            singleFolder?.task?.name
                                                                            :
                                                                            singleFolder?.folder_slug === 'hearings' ?
                                                                                singleFolder?.litigation?.title
                                                                                :
                                                                                singleFolder?.folder_name
                                                                    }
                                                                </p>
                                                                <p className="number-files-title m-0">{singleFolder?.files?.length} {singleFolder?.files?.length > 1 ? generalData?.documentsSectionSettings['files-counter'] : generalData?.documentsSectionSettings['file-counter']}</p>
                                                                <p className="number-files-title m-0">{singleFolder?.sub_folders?.length} {generalData?.documentsSectionSettings['folders-title']}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Link>
                                        </div>
                                    ))
                                    :
                                    (currentFolder?.folder_slug !== 'signatures' || currentFolder?.folder_slug !== 'proxies' || currentFolder?.folder_slug !== 'personal-documents' || currentFolder?.folder_slug !== 'board-members' || currentFolder?.folder_slug !== 'shares-operations') &&
                                    (shortcutFolders?.length === 0 && userFolders?.length === 0) &&
                                    <p>{generalData?.documentsSectionSettings['no-folders']}</p>
                            }
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-auto my-auto">
                                <h1 className="m-0">{generalData?.documentsSectionSettings['files-titles']}</h1>
                            </div>
                            <div className="col-auto">
                                <div className="row">
                                    {
                                        currentFolder?.folder_slug === 'proxies' || currentFolder?.folder_slug === 'personal-documents' || currentFolder?.folder_slug === 'signatures' || currentFolder?.folder_slug === 'shares-operations' || currentFolder?.folder_slug === 'board-members' || currentFolder?.folder_slug === 'hearings' ?
                                            null
                                            :
                                            <button onClick={() => setUploadFilePopup(true)} className="px-3 popup-button w-auto my-auto" >{generalData?.documentsSectionSettings['create-file-main-button']}</button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row py-3">
                            {
                                returnedFiles?.length > 0 ?
                                    returnedFiles?.map((singleFile, index) => (
                                        <div className="h-100 col-auto pb-3 h-auto cursor-pointer full-width" key={index} >
                                            <a href={singleFile?.full_path_file} target="_blank" rel="noreferrer" download={singleFile.title}>
                                                {
                                                    currentFolder?.folder_slug === 'proxies' ||
                                                        currentFolder?.folder_slug === 'signatures' ||
                                                        currentFolder?.folder_slug === 'personal-documents'
                                                        || currentFolder?.folder_slug === 'shares-operations'
                                                        || currentFolder?.folder_slug === 'board-members'
                                                        || currentFolder?.folder_slug === 'hearings' ?
                                                        <div className="h-100">
                                                            <div className="files-div d-flex flex-column position-relative h-100 full-width">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <FileIcon />
                                                                    </div>
                                                                    <ThreeDotsMenu className="cursor-pointer file-buttons" onClick={(e) => openFilesEditMenu(e, singleFile)} />
                                                                    <div className={"settings-clickmenu text-start " + (singleFile?.id === fileMenuOpen?.id ? ' d-block' : '')}>
                                                                        <p onClick={(e) => { e.stopPropagation(); e.preventDefault(); setShareLink(singleFile) }} style={{ zIndex: "150" }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['share-placeholder']}</p>
                                                                    </div>
                                                                    <div className="col ps-0">
                                                                        <p className="folder-title m-0 small-line-height">{generalData?.documentsSectionSettings['file-name-placeholder']}</p>
                                                                        <p className="number-files-title pt-0 m-0 word-break">{currentFolder?.folder_slug === 'personal-documents' ? singleFile?.file_name : singleFile?.file_name + '.' + singleFile?.file?.split('.')[1]}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row py-2">
                                                                    <div className="col-6">
                                                                        <p className="folder-title m-0 ">{generalData?.documentsSectionSettings['type-placeholder']}</p>
                                                                        <p className="number-files-title pt-0 m-0 word-break">{singleFile?.files_type?.title}</p>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="folder-title m-0 ">{generalData?.documentsSectionSettings['upload-date']}</p>
                                                                        <p className="number-files-title pt-0 m-0">{singleFile?.file_date || singleFile?.upload_date || singleFile?.formatted_date}</p>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    singleFile?.comment ?
                                                                        <div className="row py-2">
                                                                            <div className="col-12">
                                                                                <p className="folder-title m-0 ">{generalData?.documentsSectionSettings['comment-placeholder']}</p>
                                                                                <p className="number-files-title pt-0 m-0 word-break">{singleFile?.comment}</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <Draggable className="h-100" onDragStart={(e) => onDragStart(e, singleFile)} type={'file'} data={singleFile}>
                                                            <div className="files-div d-flex flex-column position-relative h-100 full-width">
                                                                <div className="row">
                                                                    <div className="col-auto">
                                                                        <FileIcon />
                                                                    </div>
                                                                    <ThreeDotsMenu className="cursor-pointer file-buttons" onClick={(e) => openFilesEditMenu(e, singleFile)} />
                                                                    <div className={"settings-clickmenu text-start " + (singleFile?.id === fileMenuOpen?.id ? ' d-block' : '')}>
                                                                        <p onClick={(e) => editFile(e, singleFile)} style={{ zIndex: "99" }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['edit-placeholder']}</p>
                                                                        <p onClick={(e) => { e.stopPropagation(); e.preventDefault(); setShareLink(singleFile) }} style={{ zIndex: "150" }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['share-placeholder']}</p>
                                                                        <p onClick={(e) => fileDeleteClick(e, singleFile)} style={{ zIndex: "200" }} className="p-0 py-1 m-0 ms-2">{generalData?.documentsSectionSettings['delete-placeholder']}</p>
                                                                    </div>
                                                                    <div className="col">

                                                                        <div className="row">

                                                                            <div className="col-6 ps-0">
                                                                                <p className="folder-title m-0 small-line-height">{generalData?.documentsSectionSettings['file-name-placeholder']}</p>
                                                                                <p className="number-files-title pt-0 m-0 word-break">{singleFile?.file_name + '.' + singleFile?.file?.split('.')[1]}</p>
                                                                            </div>
                                                                            {
                                                                                singleFile?.original_name &&
                                                                                <div className="col-6 ps-0">
                                                                                    <p className="folder-title m-0 small-line-height">{generalData?.website_titles['original-file-name']}</p>
                                                                                    <p className="number-files-title pt-0 m-0 word-break">{singleFile?.original_name}</p>
                                                                                </div>
                                                                            }

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="row py-2">
                                                                    <div className="col-6">
                                                                        <p className="folder-title m-0 ">{generalData?.documentsSectionSettings['type-placeholder']}</p>
                                                                        <p className="number-files-title pt-0 m-0 word-break">{singleFile?.files_type?.title}</p>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="folder-title m-0 ">{generalData?.documentsSectionSettings['upload-date']}</p>
                                                                        <p className="number-files-title pt-0 m-0">{singleFile?.file_date || singleFile?.upload_date || singleFile?.formatted_date}</p>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    singleFile?.comment ?
                                                                        <div className="row py-2">
                                                                            <div className="col-12">
                                                                                <p className="folder-title m-0 ">{generalData?.documentsSectionSettings['comment-placeholder']}</p>
                                                                                <p className="number-files-title pt-0 m-0 word-break">{singleFile?.comment}</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </Draggable>
                                                }
                                            </a>
                                        </div>
                                    ))
                                    :
                                    <p>{generalData?.documentsSectionSettings['no-files']}</p>
                            }
                        </div>
                        {
                            //folders popup
                            addFolder ?
                                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <CloseIcon className="cursor-pointer close-popup-svg" onClick={() => { setAddFolder(false); setErrorsReturned({}); setFolderName() }} />
                                        <div className="text-center ">
                                            <div className="containter-fluid">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.documentsSectionSettings['folder-popup-new']}</h1>
                                                    </div>
                                                    <form onSubmit={createFolder}>
                                                        <div className="col-12 pt-2 pb-4">
                                                            <div className="row justify-content-center">
                                                                <div className="col-12 pt-2">
                                                                    <input required name="folder_name" className="add-user-inputs" placeholder={generalData?.documentsSectionSettings['folder-popup-placeholder']} value={folderName} onChange={(e) => setFolderName(e.target.value)} />
                                                                    {
                                                                        errorsReturned?.folder_name ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.folder_name}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        errorsReturned[0] ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <button type="submit" className="popup-button my-auto">{generalData?.documentsSectionSettings['folder-popup-create']}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            // edit folders popup
                            editFolder ?
                                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <CloseIcon className="cursor-pointer close-popup-svg" onClick={() => { setEditFolder(false); setErrorsReturned({}); setFolderName() }} />
                                        <div className="text-center ">
                                            <div className="containter-fluid">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h1 className="pop-up-title m-0 py-2 color-dark-blue">{generalData?.documentsSectionSettings['rename-folder-title']}</h1>
                                                    </div>
                                                    <form onSubmit={submitEditFolder}>
                                                        <div className="col-12 pt-2 pb-4">
                                                            <div className="row justify-content-center">
                                                                <div className="col-12 pt-2">
                                                                    <input required name="folder_name" className="add-user-inputs" placeholder="Folder Name" value={folderName} onChange={(e) => setFolderName(e.target.value)} />
                                                                    {
                                                                        errorsReturned?.folder_name ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned?.folder_name}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        errorsReturned[0] ?
                                                                            <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text">{errorsReturned}</h5>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <button type="submit" className="popup-button my-auto">{generalData?.documentsSectionSettings['rename-folder-button']}</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            //share link popup
                            shareLink ?
                                <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                                    <div className="pop-up-api-box">
                                        <CloseIcon className="cursor-pointer close-popup-svg" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShareLink(); setCopiedText(); setErrorsReturned({}); setEditingFile(); setFileMenuOpen() }} />
                                        <div className=" ">
                                            <div className="containter-fluid">
                                                <div className="row">
                                                    <div className="col-12 text-start">
                                                        <h1 className="pop-up-title m-0 py-2 color-dark-blue share-title-popup">{generalData?.documentsSectionSettings['share-link-title']}</h1>
                                                    </div>
                                                    <div className="col-12 d-flex py-1">
                                                        <div className="share-icons pe-2" onMouseLeave={() => setHoveredSocial()} onMouseEnter={() => setHoveredSocial(1)}>
                                                            <a href={'whatsapp://send?text=' + shareLink?.full_path_file + ''} target="_blank" rel="noreferrer">
                                                                {
                                                                    hoveredSocial === 1 ?
                                                                        <ShareWhatsappIconHovered />
                                                                        :
                                                                        <ShareWhatsapIcon />
                                                                }
                                                            </a>
                                                        </div>
                                                        <div className="share-icons" onMouseLeave={() => setHoveredSocial()} onMouseEnter={() => setHoveredSocial(2)}>
                                                            <a href={'mailto:?body=' + shareLink?.full_path_file} target="_blank" rel="noreferrer">
                                                                {
                                                                    hoveredSocial === 2 ?
                                                                        <ShareMailIconHovered />
                                                                        :
                                                                        <ShareMailIcon />

                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-start">
                                                        <h1 className="pop-up-title m-0 py-2 color-dark-blue share-title-popup">{generalData?.documentsSectionSettings['copy-link-title']}</h1>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col">
                                                                <input name="link" className="auth-input link-copy" value={shareLink?.full_path_file} />
                                                            </div>
                                                            <div className="col-auto my-auto">
                                                                <button onClick={(e) => { e.preventDefault(); setCopiedText(shareLink?.full_path_file); navigator.clipboard.writeText(shareLink?.full_path_file) }} type="button" className="popup-button my-auto">{copiedText ? generalData?.documentsSectionSettings['text-copied'] : generalData?.documentsSectionSettings['copy-link-button']}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                {
                    //delete folder popup
                    deleteFolderPopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer close-popup-svg" onClick={() => { setDeleteFolderPopup(null); setOpenEditFolderMenu(null) }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={() => deleteFolder(openEditFolderMenu ? openEditFolderMenu?.id : null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={() => cancelDeleteFolder()} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }

                {
                    // upload file popup
                    uploadFilePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer close-popup-svg" onClick={(e) => closeFilePopup(e)} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row">
                                            <div className="col-12">
                                                <h1 className="pop-up-title m-0 py-2 color-dark-blue">{editingFile?.id ? generalData?.documentsSectionSettings['edit-file-title'] : generalData?.documentsSectionSettings['upload-file-title']}</h1>
                                            </div>
                                            <form onSubmit={submitFiles}>
                                                <div className="col-12 pt-2 pb-4">
                                                    <div className="row justify-content-start">
                                                        <div className="col-12 col-md-6 pt-2 pe-md-0">
                                                            <div className="position-relative d-flex justify-content-start w-100">
                                                                <Select
                                                                    classNamePrefix="popup-select"
                                                                    className="popup-select w-100"
                                                                    styles={customStyles}
                                                                    options={allFileTypes()}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    placeholder={generalData?.documentsSectionSettings['type-placeholder']}
                                                                    isSearchable={false}
                                                                    value={fileType ? fileType : null}
                                                                    onChange={setFileType} />

                                                                <input
                                                                    type="text"
                                                                    className='position-x-center'
                                                                    style={{
                                                                        opacity: 0,
                                                                        width: 0,
                                                                        height: 0,
                                                                    }}
                                                                    value={fileType || ''}
                                                                    required
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.files_type_id ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.files_type_id}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2">
                                                            <input
                                                                name="file_name"
                                                                className="add-user-inputs"
                                                                placeholder={generalData?.documentsSectionSettings['file-name-placeholder']}
                                                                value={fileName}
                                                                onChange={(e) => setFileName(e.target.value)}
                                                                required
                                                            />
                                                            {
                                                                errorsReturned?.file_name ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_name}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-md-6 pt-2 pe-md-0 position-relative">
                                                            <input hidden id='upload-document-file' type="file" onChange={(e) => { documentHandler(e.target.files) }} />
                                                            <label className="pop-upload-file position-relative" htmlFor="upload-document-file">{documentLink ? generalData?.documentsSectionSettings['change-file-placeholder'] : generalData?.documentsSectionSettings['upload-file-placeholder']}</label>

                                                            <AttachIcon />
                                                            {
                                                                errorsReturned?.file ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>

                                                        <div className="col-12 col-md-6 pt-2" >
                                                            <div className="position-relative text-start justify-content-start w-100">
                                                                <DatePicker className="popup-date add-user-inputs"
                                                                    placeholderText={generalData?.website_titles['date']}
                                                                    value={fileDate ? fileDate : ''}
                                                                    showMonthDropdown
                                                                    showYearDropdown
                                                                    dropdownMode="select"
                                                                    selected={fileDate || ''}
                                                                    dateFormat="d MMMM yyyy"
                                                                    onChange={(date) => { setFileDate(date); }}
                                                                    minDate={addYears(new Date(), -100)}
                                                                    maxDate={addYears(new Date(), +100)}
                                                                    locale={localStorage.getItem("LmsLanguage")}
                                                                />
                                                            </div>
                                                            {
                                                                errorsReturned?.transaction_date ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start text-start pop-errors-text">{errorsReturned?.transaction_date}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="col-12 pt-2">
                                                            <textarea type="text-area" name="file_comment" className="add-user-inputs" placeholder={generalData?.documentsSectionSettings['comment-placeholder']} value={fileComment} onChange={(e) => setFileComment(e.target.value)} />
                                                            {
                                                                errorsReturned?.file_comment ?
                                                                    <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned?.file_comment}</h5>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 d-flex justify-content-center">
                                                    <button type="submit" className="popup-button my-auto">{editingFile?.id ? generalData?.documentsSectionSettings['change-file-button'] : generalData?.documentsSectionSettings['add-file-button']}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    //delet files popup
                    deleteFilePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer close-popup-svg" onClick={() => { setDeleteFilePopup(null); setFileMenuOpen(null) }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.settings_section_settings['delete-popup-title']}</h1>
                                            </div>
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={() => deleteFile(deleteFilePopup ? deleteFilePopup?.id : null)} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.settings_section_settings['delete-popup-first-button']}</button>
                                                <button onClick={() => { setDeleteFilePopup(null); setFileMenuOpen(null) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.settings_section_settings['delete-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    // move files or folders popup
                    movePopup ?
                        <div id="errors-popup" className=" error-popup-api d-flex align-items-center justify-content-center px-3 fade-in">
                            <div className="pop-up-api-box">
                                <CloseIcon className="cursor-pointer close-popup-svg" onClick={() => { setErrorsReturned({}); setDropElememt(null); setDraggedElemet(null); setMovePopup(false) }} />
                                <div className="text-center ">
                                    <div className="containter-fluid">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <div className="col-9 col-sm-12 d-flex justify-content-center">
                                                <h1 className="pop-up-title m-0 pt-3 color-dark-blue">{generalData?.documentsSectionSettings['move-popup-title']}</h1>
                                            </div>
                                            {
                                                errorsReturned ?
                                                    <div className="col-12 d-flex justify-content-center text-center">
                                                        <h5 className="pt-2 m-0 d-flex flex-start pop-errors-text text-start">{errorsReturned[0]}</h5>
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className="col-12 d-flex justify-content-center pt-4 pb-2">
                                                <button onClick={() => submitMove()} type="button" className="popup-button my-auto mx-2 w-auto px-3">{generalData?.documentsSectionSettings['move-popup-first-button']}</button>
                                                <button onClick={() => { setErrorsReturned({}); setDropElememt(null); setDraggedElemet(null); setMovePopup(false) }} type="button" className="auth-button border-dark-blue my-auto mx-2 background-transparent w-auto px-3">{generalData?.documentsSectionSettings['move-popup-second-button']}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </div >
            <Api options={generalDataApiOptions} />
        </>
    )
}